@use "../global" as *;
/*=================================
footer
=================================*/
.footer {
  background: $color-gray;
  &__inner {
    max-width: calc($base-width + 2%);
    margin: 0 auto;
    padding: 29px 1% 27px;
  }
  .fNavList {
    text-align: center;
    &__item {
      display: inline-block;
      margin: 0 25px;
      a {
        font-size: 1.3rem;
        font-weight: 400;
        line-height: 1.384615;
        color: $font-color-base;
        text-decoration: none;
        @include mq(pc) {
          &:hover {
            text-decoration: underline;
            color: $color-link;
          }
        }
      }
    }
  }
  .copy {
    font-size: 1.2rem;
    line-height: 1.5;
    color: #fff;
    background: $color-blue;
    text-align: center;
    display: block;
    padding: 29px 1%;
  }
}

.footAnnounce {
  padding: 30px 1%;
  background: #fff;
  &__inner {
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }
  .announce {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.375;
  }
}

@include mq(sp) {
  /*=================================
  footer
  =================================*/
  .footer {
    &__inner {
      padding: 29px 0;
    }
    .fNavList {
      &__item {
        margin: 0 1rem;
        a {
          font-size: 1.2rem;
        }
      }
    }
  }
  .footAnnounce {
    padding: 40px per(17) 30px;
    background: #FFF8DB;
    &__inner {
      display: block;
    }
    .announce {
      font-size: 1.4rem;
      line-height: 2;
      margin: 20px 0 0;
    }
  }
}