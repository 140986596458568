@use "../global" as *;
/*=================================
  article
=================================*/
/* 記事一覧 */
.articleList {
  &__item {
    background: #fff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .05);
    padding: 25px;
    position: relative;
    &::after {
      content: "";
      border-style: solid;
      border-width: 0 0 17px 17px;
      border-color: transparent transparent $color-blue transparent;
      display: inline-block;
      position: absolute;
      bottom: 14px;
      right: 14px;
      @include animation-base(all,.1s);
    }
    @include mq(pc) {
      &:hover {
        .textWrap__ttl {
          text-decoration: underline!important;
        }
      }
    }
    .itemWrap {
      display: flex;
      .thumb {
        width: 100px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        & + .textWrap {
          width: calc(100% - 126px);
          margin-left: 26px;
        }
      }
      .textWrap {
        &__ttl {
          font-size: 1.8rem;
          font-weight: 500;
          line-height: 1.38888;
          color: $color-blue;
          display: block;
          text-decoration: none;
        }
        &__text {
          font-size: 1.6rem;
          line-height: 1.75;
          margin: 16px 0 0;
        }
        &__data {
          line-height: 1;
          margin-top: 4px;
          > * {
            font-size: 1.4rem;
            font-weight: 400;
            line-height: 1.7142;
            display: inline-block;
            & + * {
              margin-left: 2rem;
            }
          }
        }
      }
    }
    & + .articleList__item {
      margin-top: 20px;
    }
  }
}

/* 関連記事 */
.relatedBox {
  padding: 60px 1% 40px;
  background: $color-gray;
  &__inner {
    max-width: $base-width;
    margin: 0 auto;
  }
  &__ttl {
    font-size: 3rem;
    font-weight: 700;
    line-height: 1.5;
    padding: 0 0 0 48px;
    margin: 0 0 50px;
    position: relative;
    &::before {
      content: "";
      width: 28px;
      height: 24px;
      display: inline-block;
      background: url(../img/common/ttl_line_4.svg) no-repeat center center;
      background-size: contain;
      position: absolute;
      top: 12px;
      left: 0;
    }
  }
  &__text {
    font-size: 1.6rem;
    font-weight: 400;
    margin-top: 7px;
  }
  .relatedList {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    &__item {
      width: calc((100% - 40px) / 2);
      margin-bottom: 20px;
      &:not(:nth-child(2n + 1)) {
        margin-left: 40px;
      }
    }
  }
}
.relatedList {
  &__item {
    background: #fff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .05);
    position: relative;
    &::after {
      content: "";
      border-style: solid;
      border-width: 0 0 12px 12px;
      border-color: transparent transparent $color-blue transparent;
      display: inline-block;
      position: absolute;
      bottom: 8px;
      right: 8px;
      @include animation-base(all,.1s);
    }
    @include mq(pc) {
      &:hover {
        .textWrap__ttl {
          text-decoration: underline!important;
        }
      }
    }
    .itemWrap {
      display: flex;
      .thumb {
        width: 100px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        & + .textWrap {
          width: calc(100% - 100px);
        }
      }
      .textWrap {
        padding: 18px 26px;
        &__ttl {
          font-size: 1.6rem;
          font-weight: 500;
          line-height: 1.5625;
          color: $color-blue;
          display: block;
          text-decoration: none;
        }
        &__text {
          font-size: 1.5rem;
          line-height: 1.5;
          margin: 10px 0 0;
        }
        &__data {
          line-height: 1;
          margin-top: 4px;
          > * {
            font-size: 1.4rem;
            font-weight: 400;
            line-height: 1.7142;
            display: inline-block;
            & + * {
              margin-left: 2rem;
            }
          }
        }
      }
    }
  }
}

/*カテゴリー別記事一覧*/
.articleByCategory {
  max-width: 1060px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  &__ttl {
    color: #fff;
    background: $color-blue;
    padding: 8px 18px;
    margin: 0 0 20px;
    display: flex;
    align-items: center;
    .ttl,.num {
      font-size: 1.8rem;
      font-weight: 500;
      line-height: 1.66666;
    }
  }
  &__item {
    width: calc((100% - 40px) / 2);
    &:nth-child(2n) {
      margin-left: 40px;
    }
    &:nth-child(n + 3) {
      margin-top: 40px;
    }
    .btn--more {
      width: 70%;
      margin: 30px auto 0;
    }
  }
  .articleList {
    &__item {
      padding: 0;
      .itemWrap {
        .thumb {
          & + .textWrap {
            width: calc(100% - 100px);
            margin-left: 0;
          }
        }
        .textWrap {
          padding: 26px 30px;
          &__ttl {
            font-size: 1.6rem;
            line-height: 1.5625;
          }
        }
      }
      &::after {
        border-width: 0 0 12px 12px;
        right: 8px;
        bottom: 8px;
      }
    }
  }
}

@include mq(sp) {
  /*=================================
  article
  =================================*/
  /* 記事一覧 */
  .articleList {
    &__item {
      padding: 8px;
      &::after {
        border-width: 0 0 10px 10px;
        bottom: 7px;
        right: 7px;
      }
      .itemWrap {
        .thumb {
          width: 80px;
          & + .textWrap {
            width: calc(100% - 95px);
            margin-left: 15px;
          }
        }
        .textWrap {
          &__ttl {
            font-size: 1.4rem;
            line-height: 1.64285;
          }
          &__text {
            display: none;
          }
          &__data {
            margin-top: 0;
            > * {
              font-size: 1.2rem;
            }
          }
        }
      }
      & + .articleList__item {
        margin-top: 17px;
      }
    }
  }

  /* 関連記事 */
  .relatedBox {
    padding: 40px per(17);
    &__ttl {
      font-size: 2.2rem;
      line-height: 1.545454;
      padding: 0 0 0 27px;
      margin: 0 0 23px;
      &::before {
        width: 20px;
        height: 17px;
        top: 8px;
      }
    }
    &__text {
      font-size: 1.4rem;
    }
    .relatedList {
      display: block;
      &__item {
        width: 100%;
        margin-bottom: 15px;
        &:not(:nth-child(2n + 1)) {
          margin-left: 0;
        }
      }
    }
  }
  .relatedList {
    &__item {
      &::after {
        border-width: 0 0 8px 8px;
        bottom: 6px;
        right: 6px;
      }
      .itemWrap {
        .thumb {
          width: 80px;
          & + .textWrap {
            width: calc(100% - 80px);
          }
        }
        .textWrap {
          padding: 11px 12px 26px;
          &__ttl {
            font-size: 1.4rem;
            line-height: 1.64285;
          }
          &__text {
            display: none;
          }
          &__data {
            margin-top: 0;
            > * {
              font-size: 1.2rem;
            }
          }
        }
      }
    }
  }

  /*カテゴリー別記事一覧*/
  .articleByCategory {
    display: block;
    &__ttl {
      padding: 9px per(18);
      margin: 0 0 15px;
      .ttl,.num {
        font-size: 1.6rem;
      }
    }
    &__item {
      width: 100%;
      &:nth-child(2n) {
        margin-left: 0;
      }
      &:nth-child(n + 2) {
        margin-top: 40px;
      }
      .btn--more {
        width: 100%;
        margin: 15px auto 0;
      }
    }
    .articleList {
      &__item {
        padding: 0;
        .itemWrap {
          .thumb {
            & + .textWrap {
              width: calc(100% - 80px);
              margin-left: 0;
            }
          }
          .textWrap {
            padding: 11px per(12);
            &__ttl {
              font-size: 1.4rem;
            }
          }
        }
        &::after {
          border-width: 0 0 8px 8px;
          right: 6px;
          bottom: 6px;
        }
      }
    }
  }
}