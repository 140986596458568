@use "../global" as *;
/* =================================
  header
================================= */
.header {
  width: 100%;
  background: #fff;
  &__logo {
    margin-bottom: -90px;
    padding: 36px 35px;
    @include mq(tab) {
      padding: vw(36) vw(35);
      margin-bottom: vw(-100);
    }
    .logo {
      display: inline-block;
      vertical-align: middle;
      margin-right: 36px;
      @include mq(tab) {
        margin-right: vw(36);
        width: vw(408);
      }
      a {
        display: block;
        @include mq(pc) {
          &:hover {
            opacity: .8;
          }
        }
      }
    }
    .text {
      font-size: 1.4rem;
      display: inline-block;
      vertical-align: middle;
      max-width: 13em;
    }
  }
  .btnArea {
    margin: 0 25px 30px auto;
    @include mq(tab) {
      margin: 0 vw(25) vw(30) auto;
    }
    &__ttl {
      display: none;
    }
    &__text {
      display: none;
    }
    &__btn {
      margin: 0 0 0 10px;
    }
  }
  .gNav {
    &__inner {
      display: flex;
      flex-direction: column-reverse;
    }
    &List {
      background: rgb(0,77,161);
      background: -moz-linear-gradient(180deg, rgba(0,77,161,1) 0%, rgba(4,69,141,1) 100%);
      background: -webkit-linear-gradient(180deg, rgba(0,77,161,1) 0%, rgba(4,69,141,1) 100%);
      background: linear-gradient(180deg, rgba(0,77,161,1) 0%, rgba(4,69,141,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="$color-blue",endColorstr="#04458d",GradientType=1);
      padding: 0 1%;
      &__inner {
        max-width: $base-width;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      &__item {
        display: inline-block;
        position: relative;
        &::after {
          content: "";
          width: 0;
          height: 4px;
          display: block;
          border-bottom: 4px double #fff;
          position: absolute;
          left: 50%;
          bottom: 13px;
          transform: translateX(-50%);
          z-index: 0;
          @include animation-base(all,.1s);
        }
        @include mq(pc) {
          &:hover {
            &::after {
              width: 100%;
            }
          }
        }
        a {
          font-size: 1.6rem;
          font-weight: 500;
          color: #fff;
          line-height: 1.428571;
          text-decoration: none;
          padding: 21px 0 20px;
          display: block;
          position: relative;
          z-index: 2;
        }
        &.current-menu-item {
          a {
            color: $color-theme;
            &::after {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .gNavList {
    &.fixedNav {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      box-shadow: 0 3px 10px rgba(0, 0, 0, .06);
      z-index: 999;
      animation: header-show .5s ease-in;
    }
  }
}
@include mq(sp) {
  /* =================================
    header
  ================================= */
  .header {
    padding: 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    box-shadow: 0 3px 10px rgba(0, 0, 0, .06);
    &__inner {
      display: block;
      height: 60px;
    }
    &__logo {
      width: calc(100% - 60px);
      min-height: 60px;
      padding: 15px 0 15px per(17);
      background: #fff;
      display: flex;
      align-items: center;
      .logo {
        width: clamp(200px, vw(276,$sp-width), 276px);
        line-height: 1;
      }
      .text {
        display: none;
      }
    }
    .btnArea {
      display: block;
      margin: 74px auto 0;
      padding: 40px per(20) 30px;
      background: #FFF8DB;
      position: relative;
      &__ttl {
        display: block;
        font-size: 1.5rem;
        box-shadow: 3px 5px 0px 0px #DEBA10;
        width: calc(100% - per(40));
        position: absolute;
        top: -43px;
        left: per(20);
        &::before {
          width: 5px;
          height: calc(100% + 5px);
          background: #DEBA10;
          display: inline-block;
          position: absolute;
          top: 0;
          right: -5px;
        }
        &::after {
          border-width: 14px 8px 0 8px;
          filter: drop-shadow(3px 4px 0px #DEBA10);
          bottom: -14px;
        }
      }
      &__text {
        font-size: 1.4rem;
        line-height: 2;
        display: block;
        margin: 20px 0 0;
      }
      &__btn {
        margin: 0;
      }
    }
    .gNav {
      overflow-y: scroll;
      max-height: 90vh;
      width: 100%;
      margin: auto;
      padding: 20px 0;
      position: fixed;
      top: 60px;
      left: 0;
      z-index: 9999;
      transform: scale(.7);
      opacity: 0;
      visibility: hidden;
      transition: opacity .5s ease-in-out, transform .3s ease-out,visibility .2s ease-out;
      &__inner {
        display: block;
      }
      &List {
        background: none;
        padding: 0 per(20);
        &__inner {
          display: block;
        }
        &__item {
          display: block;
          margin: 0;
          &::after {
            content: none;
          }
          a {
            font-size: 1.4rem;
            color: #fff;
            padding: 20px 40px 20px per(15);
            border-radius: 5px;
            background: $color-blue;
            text-align: center;
            &::before {
              content: "";
              width: 16px;
              height: 16px;
              display: inline-block;
              background: url(../img/common/icon/icon_gnav_arrow.svg) no-repeat center center;
              background-size: contain;
              margin: 0 8px -3px 0;
            }
          }
          & + .gNavList__item {
            margin-top: 20px;
          }
        }
      }
      &.show {
        transform: scale(1);

        visibility: visible;
        opacity: 1;
      }
      &.hide {
        transform: scale(.7);
        opacity: 0;
        visibility: hidden;
      }
    }
    .headerNav__cover {
      height: 0;
      width: 100vw;
      background: $color-gray;
      position: fixed;
      top: 60px;
      left: 0;
      display: none;
      z-index: 9998;
      &.show {
        display: block;
        animation: show .2s linear 0s;
      }
      &.hide {
        display: none;
        animation: hide .2s linear 0s;
      }
    }

    /*バーガーボタン設定*/
    .burger {
      width: 60px;
      height: 60px;
      background: #fff;
      margin: auto;
      border-left: 2px solid #EBEBEB;
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
      z-index: 9999;
      &::before {
        content: "";
        width: 23px;
        height: 3px;
        display: inline-block;
        background: $color-blue;
        position: absolute;
        top: calc(50% - 3.5px);
        left: 50%;
        transform: translate(-50%,-50%);
        @include animation-base;
      }
      &::after {
        content: "";
        width: 23px;
        height: 3px;
        display: inline-block;
        background: $color-red;
        position: absolute;
        top: calc(50% + 3.5px);
        left: 50%;
        transform: translate(-50%,-50%);
        @include animation-base;
      }
      /*クリック後、バツボタンになる*/
      &.is-open {
        &::before {
          top: 50%;
          transform: translate(-50%,-50%) rotate(45deg);
        }
        &::after {
          top: 50%;
          transform: translate(-50%,-50%) rotate(-45deg);
        }
      }
    }
  }
}