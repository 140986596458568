@use "animate" as *;
@use "variables" as *;
@use "breakpoint" as *;
@use "font-family" as *;
@mixin singleStyle-base {
  h1,h2,h3,h4,h5,h6 {
    clear: both;
  }
  p:not([class]) {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.75;
    & + p {
      margin-top: 2rem;
    }
  }
  h1:not([class]),h1.wp-block-heading {
    font-size: 3.2rem;
    font-weight: 700;
    line-height: 1.5;
    margin-top: 100px;
    margin-bottom: 40px;
  }
  h2:not([class]),h2.wp-block-heading {
    font-size: 2.5rem;
    font-weight: 700;
    color: #fff;
    background: $color-blue;
    line-height: 1.2;
    margin-top: 50px;
    margin-bottom: 30px;
    padding: 15px 30px;
  }
  h3:not([class]),h3.wp-block-heading {
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.4;
    margin-top: 68px;
    margin-bottom: 30px;
    padding-top: 18px;
    border-top: 3px solid $color-blue;
  }
  h4:not([class]),h4.wp-block-heading {
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1.666666;
    margin-top: 50px;
    margin-bottom: 30px;
    padding: 12px 24px;
    background: $color-gray;
    position: relative;
    &::before {
      content: "";
      width: 7px;
      height: 100%;
      display: inline-block;
      border-left: 2px solid $color-blue;
      border-right: 2px solid $color-red;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  h5:not([class]),h5.wp-block-heading {
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1.66666;
    margin-top: 50px;
    margin-bottom: 33px;
    padding-left: 30px;
    position: relative;
    &::before {
      content: "";
      width: 19px;
      height: 17px;
      display: inline-block;
      background: url(../img/common/ttl_line_4.svg) no-repeat center center;
      background-size: contain;
      position: absolute;
      top: 6px;
      left: 0;
    }
  }
  h6:not([class]),h6.wp-block-heading {
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1.77777;
    color: $color-blue;
    margin-top: 40px;
    margin-bottom: 20px;
    padding-left: 20px;
    position: relative;
    &::before {
      content: "";
      width: 10px;
      height: 10px;
      display: inline-block;
      background: $color-blue;
      position: absolute;
      top: 12px;
      left: 0;
      transform: rotate(45deg);
    }
  }
  blockquote {
    position: relative;
    padding: 50px 20px 20px;
    margin: 50px 0 30px;
    box-sizing: border-box;
    font-style: italic;
    color: #464646;
    border: 10px solid $color-gray;
    &::before {
      content: "“";
      font-size: 5rem;
      font-weight: 700;
      color: $color-blue;
      display: inline-block;
      font-style: normal;
      width: 50px;
      height: 50px;
      line-height: 1.3;
      text-align: center;
      background: $color-gray;
      border-radius: 50px;
      position: absolute;
      top: -25px;
      left: 15px;
    }
    @for $i from 1 through 6 {
      h#{$i}{
        margin: 0 0 20px!important;
        font-size: 1.6rem;
        line-height: 1.75;
        letter-spacing: .06em;
        position: relative;
      }
    }
    p {
      font-style: normal;
    }
    cite {
      display: block;
      text-align: right;
      line-height: 1;
      color: #888888;
      font-size: 0.9em;
      margin: 10px 0 0;
    }
  }
  .btnWrap {
    clear: both;
  }
  img:not([class]),&.wp-block-heading {
    display: block;
    margin: 15px auto 20px;
  }
  img {
    &.alignleft {
      float: left;
      margin: 0 30px 30px 0;
    }
    &.alignright {
      float: right;
      margin: 0 0 30px 30px;
    }
    &.aligncenter,&.alignnone {
      margin: 0 auto;
      display: block;
    }
  }
  ul:not([class]) {
    margin: 50px 30px;
    padding: 38px 27px;
    background: #FFF;
    border: 10px solid $color-gray;
    clear: both;
    @include mq(tab) {
      padding: vw(38) vw(27);
    }
    li {
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 2;
      color: $color-blue;
      padding-left: 28px;
      position: relative;
      &::before {
        content: "";
        width: 18px;
        height: 18px;
        background: url(../img/common/icon/icon_ul.svg) no-repeat center center;
        background-size: contain;
        display: inline-block;
        position: absolute;
        top: 6px;
        left: 0;
      }
      & + li {
        margin-top: 10px;
      }
    }
    @for $i from 1 through 6 {
      h#{$i}::before{
        content: none;
      }
    }
  }
  ol:not([class]) {
    margin: 50px 30px;
    padding: 38px 27px;
    background: #FFF;
    border: 10px solid $color-gray;
    counter-reset: listNum;
    clear: both;
    @include mq(tab) {
      padding: vw(38) vw(27);
    }
    li {
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 2;
      padding-left: 31px;
      position: relative;
      &::before {
        counter-increment: listNum;
        content: counter(listNum);
        font-size: 1.2rem;
        font-weight: 400;
        color: #fff;
        background: $color-blue;
        width: 24px;
        line-height: 24px;
        border-radius: 50px;
        text-align: center;
        display: inline-block;
        position: absolute;
        top: 4px;
        left: 0;
      }
      & + li {
        margin-top: 10px;
      }
    }
    @for $i from 1 through 6 {
      h#{$i}::before{
        content: none;
      }
    }
  }
  @include mq(sp) {
    p:not([class]) {
      font-size: 1.5rem;
      line-height: 1.71428;
    }
    h1:not([class]),h1.wp-block-heading {
      font-size: 2.4rem;
      list-style: 1.44444;
      margin-top: 54px;
      margin-bottom: 20px;
    }
    h2:not([class]),h2.wp-block-heading {
      font-size: 1.8rem;
      line-height: 1.55555;
      margin-top: 54px;
      margin-bottom: 20px;
      margin-left: calc(per(-17) - 1px);
      padding: 15px per(17);
      width: calc(100% + per(34) + 2px);
    }
    h3:not([class]),h3.wp-block-heading {
      font-size: 1.6rem;
      line-height: 1.5;
      margin-top: 40px;
      margin-bottom: 20px;
      padding-top: 15px;
    }
    h4:not([class]),h4.wp-block-heading {
      font-size: 1.4rem;
      line-height: 1.625;
      margin-top: 40px;
      margin-bottom: 40px;
      padding: 11px per(16);
    }
    h5:not([class]),h5.wp-block-heading {
      font-size: 1.5rem;
      margin-top: 30px;
      margin-bottom: 20px;
      padding-left: 22px;
      &::before {
        width: 15px;
        height: 14px;
        top: 5px;
      }
    }
    h6:not([class]),h6.wp-block-heading {
      font-size: 1.5rem;
      line-height: 1.73333;
      padding-left: 15px;
      &::before {
        width: 8px;
        height: 8px;
        top: 10px;
      }
    }
    blockquote {
      padding: 40px per(15) 20px;
      margin: 40px 0 30px;
      border: 7px solid $color-gray;
      &::before {
        font-size: 4rem;
        width: 45px;
        height: 45px;
        line-height: 1.5;
      }
      @for $i from 1 through 6 {
        h#{$i}{
          font-size: 1.5rem;
        }
      }
    }
    img {
      &.alignleft {
        margin: 0 7px 7px 0;
      }
      &.alignright {
        margin: 0 0 7px 7px;
      }
    }
    ul:not([class]) {
      margin: 20px auto 40px;
      padding: 17px per(20);
      border: 7px solid $color-gray;  
      li {
        padding-left: 28px;
        &::before {
          width: 20px;
          height: 20px;
          top: 5px;
        }
      }
    }
    ol:not([class]) {
      margin: 20px auto 40px;
      padding: 17px per(20);
      border: 7px solid $color-gray;  
      li {
        &::before {
          font-size: 1rem;
          width: 22px;
          line-height: 22px;
        }
      }
    }
  }
  iframe {
    width: 100%;
  }
}
@mixin singleStyle-parts {
  /*---------------------
    記事タイトル
  ---------------------*/
  .articleTtl {
    font-size: 3.2rem;
    list-style: 1.5;
    margin: 0 0 8px;
  }
  /*---------------------
    メインビジュアル
  ---------------------*/
  .articleMv {
    margin: 15px auto;
    text-align: center;
    overflow: hidden;
    img {
      margin: 0 auto;
    }
  }
  /*---------------------
    更新日
  ---------------------*/
  .date {
    text-align: right;
    > * {
      font-size: 1.2rem!important;
      font-weight: 400;
      color: #333333;
      display: inline-block;
      line-height: 1!important;
      margin: 0;
      & + * {
        margin: 0 0 0 1rem!important;
      }
    }
  }
  /*---------------------
    目次
  ---------------------*/
  .tocBox {
    margin: 40px auto 100px;
    padding: 23px;
    background: $color-gray;
    &__inner {
      background: #fff;
      padding: 0 20px 20px;
      box-shadow: 0 3px 10px rgba(0, 0, 0, .05);
    }
    &__ttl {
      font-size: 1.8rem;
      line-height: 1;
      margin: 0 0 15px;
      text-align: center;
      &::before {
        content: "";
        width: 20px;
        height: 16px;
        display: inline-block;
        background: url(../img/common/icon/icon_book.svg) no-repeat center center;
        background-size: contain;
        margin: 0 9px -2px 0;
      }
    }
    .tocList {
      counter-reset: tocNum;
      &__item {
        &:not(:last-child) {
          border-bottom: 1px solid #D2DAF0;
        }
        a {
          font-size: 1.6rem;
          font-weight: 400;
          color: $color-blue;
          display: block;
          padding: 12px 24px;
          text-decoration: none;
          position: relative;
          &::before {
            counter-increment: tocNum;
            content: counter(tocNum)".";
            font-size: 1.6rem;
            font-weight: 400;
            color: $color-blue;
            display: inline-block;
            position: absolute;
            top: 12px;
            left: 0;
          }
          &::after {
            content: "";
            border-style: solid;
            border-width: 0 0 12px 12px;
            border-color: transparent transparent $color-blue transparent;
            display: inline-block;
            position: absolute;
            bottom: 12px;
            right: 0;
            @include animation-base(all,.1s);
          }
          @include mq(pc) {
            &:hover {
              text-decoration: underline;
              color: $color-theme;
              &::after {
                bottom: 6px;
              }
            }
          }
        }
        .tocList {
          counter-reset: tocChildNum;
          padding-left: 26px;
          &__item {
            a {
              padding-left: 46px;
              display: block;
              &::before {
                counter-increment: tocChildNum;
                content: counter(tocNum) " - " counter(tocChildNum)".";
              }
            }
          }
        }
      }
    }
    .tocMore {
      font-size: 1.5rem;
      font-weight: 500;
      background: $color-yellow;
      text-align: center;
      margin-top: 20px;
      padding: 8px 20px;
      display: inline-block;
      text-align: center;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;
      @include mq(pc) {
        &:hover {
          color: #fff;
          background: $color-blue;
        }
      }
    }
    &:not(.open) {
      .tocBox__inner {
        > .tocList > .tocList__item:nth-child(n + 4) {
          display: none;
        }
      }
      .tocMore {
        &::after {
          transform: rotate(0deg);
        }
        @include mq(pc) {
          &:hover {
            &::after {
              transform: translateY(2px) rotate(90deg);
            }
          }
        }
      }
    }
  }
  /*---------------------
    関連記事
  ---------------------*/
  .related {
    margin: 60px auto 50px;
    position: relative;
    &__ttl {
      position: absolute;
      top: -24px;
      right: 4px;
      z-index: 1;
    }
    .relatedList {
      &__item {
        padding: 16px 24px 15px;
        box-shadow: 0 3px 8px rgba(0, 0, 0, .1);
        &::after {
          border-width: 0 0 14px 14px;
          bottom: 14px;
          right: 12px;
        }
        .itemWrap {
          .thumb {
            width: 80px;
            img {
              margin: 0;
            }
            & + .textWrap {
              width: calc(100% - 102px);
              margin-left: 22px;
            }
          }
          .textWrap {
            padding: 15px 0;
            &__ttl {
              font-size: 1.8rem;
              line-height: 1.38888;
            }
          }
        }
        & + .relatedList__item {
          margin-top: 40px;
        }
      }
    }
  }
  /*---------------------
    既存テーマスタイル
  ---------------------*/
  /* balloon状の見出し */
  .point {
    font-size: 1.5rem;
    font-weight: 700;
    color:#fff;
    display: inline-block;
    padding: 4px 16px 7px;
    background: $color-red;
    box-shadow: 3px 4px 0px 0px $color-red-dark;
    position: relative;
    transform: translateY(20px);
    &::before {
      content: "";
      width: 4px;
      height: calc(100% + 4px);
      background: $color-red-dark;
      display: inline-block;
      position: absolute;
      top: 0;
      right: -4px;
    }
    &::after {
      content: "";
      border-style: solid;
      border-width: 11px 5px 0 5px;
      border-color: $color-red transparent transparent transparent;
      display: inline-block;
      filter: drop-shadow(3px 4px 0px $color-red-dark);
      position: absolute;
      bottom: -11px;
      left: 50%;
      transform: translateX(-50%);
    }
    .icon {
      display: inline-block;
      vertical-align: middle;
      margin: 0 15px 0 0;
    }
    & + ul,ol {
      margin-top: 0;
      border-color: #FFF1B4;
    }
    & + ul li {
      &::before {
        background: url(../img/common/icon/icon_ul_yellow.svg) no-repeat center center;
        background-size: contain;
      }
    }
  }
  /* トピックス状 */
  .note {
    background: $color-gray;
    margin: 50px 40px;
    padding: 50px 40px 40px;
    position: relative;
    &::before {
      content: "";
      width: 100%;
      height: 7px;
      display: block;
      border-top: 2px solid $color-blue;
      border-bottom: 2px solid $color-red;
      position: absolute;
      top: 0;
      left: 0;
    }
    @for $i from 1 through 6 {
      h#{$i}{
        font-size: 1.8rem;
        font-weight: 700;
        line-height: 1.66666;
        color: $color-blue;
        background: none;
        margin: 0 0 20px!important;
        padding: 0 0 0 43px;
        border: none;
        position: relative;
        &::before {
          content: "";
          width: 31px;
          height: 44px;
          display: inline-block;
          background: url(../img/common/ttl_topic.svg) no-repeat center center;
          background-size: contain;
          position: absolute;
          top: -8px;
          left: 0;
          border: none;
          margin: 0;
          padding: 0;
          transform: none;
        }
        &::after {
          content: none;
        }
      }
    }
    p {
      background: #fff;
      margin: 0!important;
      padding: 20px;
      & + p {
        padding-top: 0;
      }
    }
  }
  /* 商標誘導ボタン */
  .btn {
    background: $color-gray;
    padding: 30px;
    margin: 50px auto;
    a {
      font-size: 2.8rem;
      font-weight: 500;
      color: #fff;
      background: rgb(215,1,16);
      background: -moz-linear-gradient(180deg, rgba(215,1,16,1) 0%, rgba(185,5,18,1) 100%);
      background: -webkit-linear-gradient(180deg, rgba(215,1,16,1) 0%, rgba(185,5,18,1) 100%);
      background: linear-gradient(180deg, rgba(215,1,16,1) 0%, rgba(185,5,18,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d70110",endColorstr="#b90512",GradientType=1);
      border-radius: 5px;
      box-shadow: 4px 4px 0px 4px $color-red-dark;
      padding: 30px 5px;
      text-align: center;
      text-decoration: none;
      display: block;
      &::before {
        content: "";
        width: 26px;
        height: 26px;
        display: inline-block;
        background: url(../img/common/icon/icon_arrow_right.svg) no-repeat center center;
        background-size: contain;
        margin: 0 10px -2px 0;
      }
      @include mq(pc) {
        @include animation-base(all,0s);
        &:hover {
          box-shadow: none;
          transform: translate(8px,8px);
          opacity: .9;
        }
      }
    }
  }
  /* 商標誘導カード */
  .intr {
    margin: 34px 0 70px;
    position: relative;
    &__ttl {
      position: absolute;
      top: -33px;
      right: 4px;
    }
    &Box {
      border: 10px solid #E6E6E6;
      padding: 20px;
      &__link {
        font-size: 2.2rem;
        color: $color-blue;
        background: $color-gray;
        padding: 13px 5px;
        margin: 0 0 20px;
        display: block;
        text-align: center;
        text-decoration: none;
        @include mq(pc) {
          &:hover {
            text-decoration: underline;
          }
        }
      }
      &__cont {
        display: flex;
        align-items: flex-start;
        .thumb {
          width: clamp(150px, vw(260), 260px);
          margin-right: 30px;
          & + .spec {
            width: calc(100% - clamp(150px, vw(260), 260px) - 30px);
          }
        }
        .spec {
          &__text {
            font-size: 1.5rem;
          }
          &__table {
            margin: 16px 0;
            th {
              font-weight: 500;
            }
            td {
              font-weight: 700;
              background: #fff;
              text-align: center;
            }
          }
          &__note {
            font-size: 1rem;
            margin: 5px 0 0;
            text-align: right;
          }
          &__btn {
            font-size: 1.6rem;
            font-weight: 500;
            line-height: 1.5;
            color: #fff;
            text-decoration: none;
            display: block;
            background: rgb(215,1,16);
            background: -moz-linear-gradient(180deg, rgba(215,1,16,1) 0%, rgba(185,5,18,1) 100%);
            background: -webkit-linear-gradient(180deg, rgba(215,1,16,1) 0%, rgba(185,5,18,1) 100%);
            background: linear-gradient(180deg, rgba(215,1,16,1) 0%, rgba(185,5,18,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d70110",endColorstr="#b90512",GradientType=1);
            border-radius: 4px;
            box-shadow: 2px 2px 0px 2px $color-red-dark;
            padding: 13px 5px 11px;
            margin: 12px 0 0;
            text-align: center;
            position: relative;
            &::before {
              content: "";
              width: 15px;
              height: 15px;
              display: inline-block;
              background: url(../img/common/icon/icon_arrow_right.svg) no-repeat center center;
              background-size: contain;
              margin: 0 10px -2px 0;
            }
            @include mq(pc) {
              @include animation-base(all,0s);
              &:hover {
                box-shadow: none;
                transform: translate(2px,2px);
                opacity: .9;
              }
            }
          }
        }
      }
    }
  }
  @include mq(sp) {
    /*---------------------
      記事タイトル
    ---------------------*/
    .articleTtl {
      font-size: 2.4rem;
      list-style: 1.33333;
    }
    /*---------------------
      メインビジュアル
    ---------------------*/
    .articleMv {
      padding: 0 per(8);
    }
    /*---------------------
      更新日
    ---------------------*/
    .date {
      text-align: left;
    }
    /*---------------------
      目次
    ---------------------*/
    .tocBox {
      margin: 50px auto;
      padding: 17px per(17);
      width: calc(100% + per(34) + 2px);
      margin-left: calc(per(-17) - 1px);
      &__inner {
        padding: 10px per(15) 26px;
        border: 1px solid #D2DAF0;
        > .tocList > .tocList__item:last-of-type {
          border-bottom: 1px solid #D2DAF0;
        }
      }
      &__ttl {
        margin: 0 0 10px;
        &::before {
          width: 18px;
          height: 14px;
          margin: 0 7px -2px 0;
        }
      }
      .tocList {
        &__item {
          a {
            font-size: 1.4rem;
            padding: 11px 1rem 11px 2rem;
            &::before {
              font-size: 1.4rem;
              top: 11px;
            }
            &::after {
              border-width: 0 0 9px 9px;
              bottom: 9px;
            }
          }
          .tocList {
            padding-left: 2rem;
            &__item {
              a {
                padding-left: 4rem;
              }
            }
          }
        }
      }
      .tocMore {
        font-size: 1.3rem;
        margin-top: 15px;
        padding: 5px 15px;
      }
    }
    /*---------------------
      関連記事
    ---------------------*/
    .related {
      margin: 30px auto;
      &__ttl {
        position: relative;
        top: -8px;
        left: 50%;
        right: initial;
        transform: translateX(-50%);
      }
      .relatedList {
        &__item {
          padding: 10px per(26) 10px per(10);
          &::after {
            border-width: 0 0 10px 10px;
            bottom: 10px;
            right: 11px;
          }
          .itemWrap {
            .thumb {
              & + .textWrap {
                width: calc(100% - 90px);
                margin-left: 10px;
              }
            }
            .textWrap {
              padding: 0;
              &__ttl {
                font-size: 1.4rem;
                line-height: 1.4285;
              }
            }
          }
          & + .relatedList__item {
            margin-top: 17px;
          }
        }
      }
    }
  
    /*---------------------
      既存テーマスタイル
    ---------------------*/
    /* balloon状の見出し */
    .point {
      font-size: 1.4rem;
      padding: 10px 20px 11px;
      transform: translateY(0);
      .icon {
        margin: 0 12px 0 0;
      }
    }
    /* トピックス状 */
    .note {
      margin: 30px 0 40px;
      padding: 25px per(23) 25px;
      @for $i from 1 through 6 {
        h#{$i}{
          font-size: 1.5rem;
          margin: 0 0 9px!important;
          padding: 0 0 0 31px;
          &::before {
            width: 24px;
            height: 33px;
          }
        }
      }
      p {
        padding: 18px per(18);
      }
    }
    /* 商標誘導ボタン */
    .btn {
      padding: 8px;
      margin: 37px auto;
      a {
        font-size: 1.8rem;
        border-radius: 4px;
        box-shadow: 2px 2px 0px 2px $color-red-dark;
        padding: 20px per(17);
        &::before {
          width: 16px;
          height: 16px;
          margin: 0 5px -2px 0;
        }
      }
    }
    /* 商標誘導カード */
    .intr {
      margin: 32px 0 40px;
      &__ttl {
        font-size: 1.4rem;
        line-height: 3.2;
        position: relative;
        top: -12px;
        left: 50%;
        right: initial;
        transform: translateX(-50%);
      }
      &Box {
        border: 7px solid #E6E6E6;
        padding: 10px 10px 20px;
        &__link {
          font-size: 1.6rem;
          padding: 10px;
        }
        &__cont {
          display: block;
          .thumb {
            width: calc(100% - per(10));
            margin: 0 auto 20px;
            & + .spec {
              width: 100%;
            }
          }
          .spec {
            &__text {
              font-size: 1.6rem;
              text-align: center;
            }
            &__table {
              margin: 10px 0 15px;
              display: table;
              white-space: normal;
              th {
                font-size: 1.2rem;
                padding: 8px;
              }
              td {
                font-size: 1.4rem;
                padding: 8px;
              }
            }
            &__note {
              font-size: 1.2rem;
              margin: 15px 0 0;
              text-align: left;
            }
            &__btn {
              padding: 17px 5px 17px;
              margin: 20px 0 0;
            }
          }
        }
      }
    }
  }
}