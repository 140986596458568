@use "../../global" as *;

/*=================================
  title
=================================*/
.ttl--base {
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.5;
  padding: 0 0 0 48px;
  margin: 0 0 40px;
  position: relative;
  &::before {
    content: "";
    width: 28px;
    height: 24px;
    display: inline-block;
    background: url(../img/common/ttl_line_4.svg) no-repeat center center;
    background-size: contain;
    position: absolute;
    top: 12px;
    left: 0;
  }
  &.center {
    text-align: center;
  }
  &.white {
    color: #fff;
  }
  .sm {
    font-size: 2rem;
  }
}
.ttl--page {
  padding: 27px 1% 36px;
  &__inner {
    max-width: $base-width;
    margin: 0 auto;
  }
  .ttl {
    font-size: 3.6rem;
    font-weight: 700;
    line-height: 1.5;
    padding: 0 0 0 43px;
    position: relative;
    &::before {
      content: "";
      width: 34px;
      height: 30px;
      display: inline-block;
      background: url(../img/common/ttl_line_4.svg) no-repeat center center;
      background-size: contain;
      position: absolute;
      top: 15px;
      left: 0;
    }
  }
  .text {
    font-size: 1.6rem;
    font-weight: 400;
    margin-top: 7px;
  }
}
.balloon--yellow {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 3;
  color: $font-color-base;
  display: inline-block;
  padding: 0 16px;
  background: #FFE678;
  box-shadow: 3px 4px 0px 0px #DEBA10;
  position: relative;
  &::before {
    content: "";
    width: 4px;
    height: calc(100% + 4px);
    background: #DEBA10;
    display: inline-block;
    position: absolute;
    top: 0;
    right: -4px;
  }
  &::after {
    content: "";
    border-style: solid;
    border-width: 11px 5px 0 5px;
    border-color: #FFE678 transparent transparent transparent;
    display: inline-block;
    filter: drop-shadow(3px 4px 0px #DEBA10);
    position: absolute;
    bottom: -11px;
    left: 50%;
    transform: translateX(-50%);
  }
  .icon {
    width: 31px;
    display: inline-block;
    vertical-align: middle;
    margin: 0 13px 0 0;
  }
}

.balloon--red {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 3;
  color:#fff;
  display: inline-block;
  padding: 0 16px;
  background: $color-red;
  box-shadow: 3px 4px 0px 0px $color-red-dark;
  position: relative;
  &::before {
    content: "";
    width: 4px;
    height: calc(100% + 4px);
    background: $color-red-dark;
    display: inline-block;
    position: absolute;
    top: 0;
    right: -4px;
  }
  &::after {
    content: "";
    border-style: solid;
    border-width: 11px 5px 0 5px;
    border-color: $color-red transparent transparent transparent;
    display: inline-block;
    filter: drop-shadow(3px 4px 0px $color-red-dark);
    position: absolute;
    bottom: -11px;
    left: 50%;
    transform: translateX(-50%);
  }
  .icon {
    width: 31px;
    display: inline-block;
    vertical-align: middle;
    margin: 0 13px 0 0;
  }
}

.balloon--blue {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 3;
  color:#fff;
  display: inline-block;
  padding: 0 16px;
  background: $color-blue;
  box-shadow: 3px 4px 0px 0px $color-blue-dark;
  position: relative;
  &::before {
    content: "";
    width: 4px;
    height: calc(100% + 4px);
    background: $color-blue-dark;
    display: inline-block;
    position: absolute;
    top: 0;
    right: -4px;
  }
  &::after {
    content: "";
    border-style: solid;
    border-width: 11px 5px 0 5px;
    border-color: $color-blue transparent transparent transparent;
    display: inline-block;
    filter: drop-shadow(3px 4px 0px $color-blue-dark);
    position: absolute;
    bottom: -11px;
    left: 50%;
    transform: translateX(-50%);
  }
  .icon {
    width: 31px;
    display: inline-block;
    vertical-align: middle;
    margin: 0 13px 0 0;
  }
}

@include mq(sp) {
  /*=================================
    title
  =================================*/
  .ttl--base {
    font-size: 2.2rem;
    line-height: 1.545454;
    padding: 0 0 0 27px;
    margin: 0 0 30px;
    &::before {
      width: 20px;
      height: 17px;
      top: 9px;
    }
    .sm {
      font-size: 1.6rem;
      display: block;
    }
  }
  .ttl--page {
    padding: 30px per(17);
    .ttl {
      font-size: 2.2rem;
      padding: 0 0 0 27px;
      &::before {
        width: 20px;
        height: 17px;
        top: 8px;
      }
    }
    .text {
      font-size: 1.4rem;
      margin-top: 10px;
      padding-left: 27px;
    }
  }
  .balloon--yellow {
    line-height: 3.5;
    padding: 0 9px;
    &::after {
      border-width: 14px 8px 0 8px;
      bottom: -14px;
    }
    .icon {
      margin: 0 12px 0 0;
    }
  }
  .balloon--red {
    line-height: 3.5;
    padding: 0 9px;
    &::after {
      border-width: 14px 8px 0 8px;
      bottom: -14px;
    }
    .icon {
      margin: 0 12px 0 0;
    }
  }
  .balloon--blue {
    line-height: 3.5;
    padding: 0 9px;
    &::after {
      border-width: 14px 8px 0 8px;
      bottom: -14px;
    }
    .icon {
      margin: 0 12px 0 0;
    }
  }
}