@use "../mixin/variables" as *;
// マップの設定 マップの名前：breakpoint　キーは3つ：pc tab1 tab2 sp 　　各キーの値に文字列としてメディアクエリを’’（シングルクォーテーション）内にセット。
$breakpoint: (
  pc: 'screen and (min-width: #{$display-width-s})',
  tab: 'screen and (min-width: #{$display-width-s}) and (max-width: #{$design-width}px)',
  sp: 'screen and (min-width: 0) and (max-width: #{$display-width-s})'
);

// mixinの定義　mixin名：mq　引数：＄bp
@mixin mq($bp) {
    // #{}で文字列の挿入(インターポレーション)を設定　map-get関数で設定したマップから値を取得
    @media #{map-get($breakpoint, $bp)} {
        // @contentで任意のスタイルを記述できるようにする
        @content;
    }
} 

// 設定したmixinの呼び出し方：@include mixinの名前(引数)　{

// }
// 例えば上記の場合：@include mq(tab) {

// }
