@use "../../global" as *;
/*=================================
  topPage
=================================*/
.home {
  .secWrap {
    padding: 90px 1% 60px;
    background: #fff;
    &.blue {
      background: #E8F1FB;
    }
    &.gray {
      background: $color-gray;
    }
    &__inner {
      max-width: $base-width;
      margin: 0 auto;
    }
  }
  /* メインビジュアル */
  .mv {
    background: url(../img/top/bg_mv.jpg) no-repeat right top $color-gray;
    background-size: contain;
    padding: 80px 0 0 90px;
    position: relative;
    &__inner {
      max-width: 1276px;
      margin: 0 auto;
      background: #fff;
      padding: 75px 56px 56px;
      position: relative;
      &::after {
        content: "";
        width: clamp(239px, vw(478), 478px);
        height: clamp(223px, vw(446), 446px);
        display: inline-block;
        background: url(../img/top/img_mv.png) no-repeat center center;
        background-size: contain;
        position: absolute;
        right: 36px;
        bottom: -20px;
      }
    }
    &__ttl {
      font-size: 1.9rem;
      line-height: 3.7;
      letter-spacing: .04em;
      box-shadow: 6px 6px 0px 0px #DEBA10;
      position: absolute;
      top: -30px;
      left: -47px;
      &::before {
        width: 6px;
        height: calc(100% + 6px);
        right: -6px;
      }
      &::after {
        border-width: 18px 10px 0 10px;
        bottom: -18px;
        filter: drop-shadow(6px 6px 0px #DEBA10);
      }
      .icon {
        width: 49px;
        margin: 0 15px 0 0;
      }
    }
    &__catch {
      font-size: 3.6rem;
      font-weight: 700;
      letter-spacing: .05em;
      line-height: 1.77777;
      margin: 0 0 30px;
      padding-right: clamp(239px, vw(350), 350px);
      position: relative;
      z-index: 1;
      .em {
        color: $color-blue;
      }
    }
    &__cont {
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 1.75;
      padding-right: clamp(239px, vw(350), 350px);
      position: relative;
      z-index: 1;
      .em {
        font-weight: 500;
        color: $color-red;
      }
    }
  }
}


@media screen and (max-width: $display-width-s) {
  /*=================================
    topPage
  =================================*/
  .home {
    .secWrap {
      padding: 40px per(25);
    }
    /* メインビジュアル */
    .mv {
      background: url(../img/top/bg_mv.jpg) no-repeat right -37px top $color-gray;
      background-size: contain;  
      padding: 30px 0 0 per(20);
      &__inner {
        padding: 68px per(20) 27px;
        &::after {
          content: none;
        }
      }
      &__ttl {
        font-size: 1.5rem;
        box-shadow: 5px 5px 0px 0px #DEBA10;
        position: absolute;
        top: -10px;
        left: 0;
        &::before {
          width: 5px;
          height: calc(100% + 5px);
          right: -5px;
        }
        &::after {
          border-width: 16px 7px 0 7px;
          bottom: -16px;
          filter: drop-shadow(5px 5px 0px #DEBA10);
        }
        .icon {
          width: 39px;
          margin: 0 2px 0 0;
        }
      }
      &__catch {
        font-size: 2.6rem;
        line-height: 1.38461;
        margin: 0 0 30px;
        padding-right: clamp(96px, vw(150,$sp-width), 150px);
        &::after {
          content: "";
          width: clamp(96px, vw(193,$sp-width), 193px);
          height: clamp(88px, vw(175,$sp-width), 175px);
          display: inline-block;
          background: url(../img/top/img_mv.png) no-repeat center center;
          background-size: contain;
          position: absolute;
          right: 0;
          bottom: -25px;
        }
      }
      &__cont {
        font-size: 1.4rem;
        line-height: 2;
        padding-right: 0;
      }
    }
  }
}