@use "../global" as *;
/* =================================
  container
================================= */
/* singlePage */
.singlePage {
  @include singleStyle-base;
  @include singleStyle-parts;
  padding: 30px 1% 100px;
  &__in {
    max-width: $base-width;
    margin: 0 auto;
    @for $i from 1 through 6 {
      & > h#{$i}:first-child {
        margin-top: -0!important;
      }
    }
    .tocBox {
      & + * {
        margin-top: 0!important;
      }
    }
  }
}

/* 記事詳細ページ */
.articleDetail {
  @include singleStyle-base;
  @include singleStyle-parts;
  padding: 33px 0 0;
  position: relative;
  &::before {
    content: "";
    width: 100%;
    height: 10px;
    display: block;
    border-top: 3px solid $color-blue;
    border-bottom: 3px solid $color-red;
    position: absolute;
    top: 0;
    left: 0;
  }
  &__in {
    @for $i from 1 through 6 {
      & > h#{$i}:first-child {
        margin-top: -0!important;
      }
    }
    .tocBox {
      & + * {
        margin-top: 0!important;
      }
    }
  }
}

/* 記事一覧ページ */
.articleListWrap {
  background: $color-gray;
  padding: 90px 1% 100px;
  &__in {
    max-width: $base-width;
    margin: 0 auto;
  }
}

@include mq(sp) {
  /* singlePage */
  .singlePage {
    padding: 0 per(17);
  }
  /* 記事詳細ページ */
  .articleDetail {
    padding: 19px 0 0;
    &::before {
      height: 9px;
    }
    &__in {
      padding: 0 per(17);
    }
  }

  /* 記事一覧ページ */
  .articleListWrap {
    padding: 20px per(17);
  }
}