@use "../global" as *;
/*=================================
  cvBox
=================================*/
.cvBox {
  padding: 68px 1% 90px;
  background: #fff;
  &__inner {
    max-width: $base-width;
    margin: 0 auto;
    display: flex;
    background:  #FFF8DB;
    position: relative;
    &::before {
      content: "";
      width: 100%;
      height: 10px;
      display: block;
      border-top: 3px solid $color-blue;
      border-bottom: 3px solid $color-red;
      position: absolute;
      top: 0;
      left: 0;
    }
    &::after {
      content: "";
      width: 100%;
      height: 10px;
      display: block;
      border-top: 3px solid $color-blue;
      border-bottom: 3px solid $color-red;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  &__img {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: left top;
    }
  }
  &__cont {
    padding: 74px 60px;
    @include mq(tab) {
      padding: vw(74) vw(60);
    }
  }
  &__ttl {
    font-size: 3.2rem;
    font-weight: 700;
    line-height: 1.71875;
    @include mq(tab) {
      font-size: clamp(2.8rem, vw(32), 3.2rem);
    }
    .sm {
      font-size: 2.5rem;
      line-height: 2.2;
      display: block;
      @include mq(tab) {
        font-size: clamp(2.1rem, vw(25), 2.5rem);
      }
    }
  }
  &__btn {
    margin: 30px 0 0;
    .btn {
      width: 100%;
    }
  }
}

.cvBox--loan {
  background: #FFF7D4;
  padding: 60px 1%;
  &__inner {
    max-width: $base-width;
    margin: 0 auto;
  }
  .balloon--red {
    display: block;
    width: 100%;
    max-width: 350px;
    margin: 0 auto 18px;
    text-align: center;
  }
}
@include mq(sp) {
  /*=================================
    cvBox
  =================================*/
  .cvBox {
    padding: 40px per(25);
    &__inner {
      display: block;
      &::before {
        height: 9px;
      }
      &::after {
        height: 9px;
      }
    }
    &__cont {
      padding: 14px per(20) 30px;
    }
    &__ttl {
      font-size: 3rem;
      line-height: 1.4;
      .sm {
        font-size: 1.8rem;
        line-height: 2;
      }
    }
    &__btn {
      margin: 19px 0 0;
    }
  }
  .cvBox--loan {
    padding: 30px per(25);
    display: block!important;
    .ttl--base {
      margin: 0 0 10px;
    }
    .balloon--red {
      font-size: 1.5rem;
      line-height: 3.5;
      width: 95%;
      margin: 0 auto;
      text-align: center;
    }
    .btnWrap {
      margin: 25px 0 0;
    }
    .loanList {
      &__item {
        margin: 17px 0 0;
      }
    }
  }
}