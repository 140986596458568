@charset "UTF-8";


@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes vibrate {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(-15deg);
  }
  75% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes vibrate02 {
  0% {
    transform: rotate(0deg) scale(1.2);
  }
  5% {
    transform: rotate(15deg) scale(1.2);
  }
  10% {
    transform: rotate(-15deg) scale(1.2);
  }
  15% {
    transform: rotate(15deg) scale(1.2);
  }
  20% {
    transform: rotate(0deg) scale(1);
  }
  100% {
    transform: rotate(0deg) scale(1);
  }
}
@keyframes burger-show {
  0% {
    right: -50%;
  }
  100% {
    right: 0%;
  }
}
@keyframes header-show {
  0% {
    opacity: 0;
    top: -100px;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}
a:hover {
  outline: none;
}
a:active {
  outline: none;
}
a:focus {
  outline: none;
}

@media screen and (min-width: 767px) { /* =================================
  hover
================================= */
  a {
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
  }
  .widelink {
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
    cursor: pointer;
  }
  .widelink .widelink__cover {
    position: relative;
    overflow: hidden;
  }
  .widelink .widelink__cover::before {
    content: "もっと見る";
    font-size: 1.4rem;
    color: #fff;
    opacity: 0;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 6;
    transition-property: opacity, top;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
  }
  .widelink .widelink__cover::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 5;
    opacity: 0;
    background: rgb(61, 84, 138);
    background: -moz-linear-gradient(135deg, rgba(61, 84, 138, 0.7) 0%, rgba(100, 133, 209, 0.7) 100%);
    background: -webkit-linear-gradient(135deg, rgba(61, 84, 138, 0.7) 0%, rgba(100, 133, 209, 0.7) 100%);
    background: linear-gradient(135deg, rgba(61, 84, 138, 0.7) 0%, rgba(100, 133, 209, 0.7) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3d548a",endColorstr="#6485d1",GradientType=1);
    color: white;
    transition-property: opacity;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
  }
  .widelink .widelink__menu {
    position: relative;
    overflow: hidden;
    display: block;
  }
  .widelink .widelink__menu::before {
    content: "詳しく見る";
    font-size: 1.3rem;
    font-weight: 700;
    color: #fff;
    line-height: 1;
    text-align: center;
    opacity: 0;
    padding: 15px 9%;
    border: 1px solid #fff;
    border-radius: 50px;
    position: absolute;
    bottom: 12%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 6;
    transition-property: opacity, bottom;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
  }
  .widelink .widelink__article {
    position: relative;
    overflow: hidden;
  }
  .widelink .widelink__article::before {
    content: "";
    display: inline-block;
    width: 130px;
    height: 24px;
    background: url(../img/link_more.png) no-repeat center center;
    opacity: 0;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 6;
    transition-property: opacity, top;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
  }
  .widelink .widelink__article::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 5;
    opacity: 0;
    background-color: rgba(0, 84, 142, 0.7);
    color: white;
    transition-property: opacity;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
  }
  .widelink:hover a {
    text-decoration: none;
  }
  .widelink:hover .widelink__cover::before, .widelink:hover .widelink__article::before {
    opacity: 1;
    top: 50%;
  }
  .widelink:hover .widelink__cover::after, .widelink:hover .widelink__article::after {
    opacity: 1;
  }
  .widelink:hover .widelink__menu::before {
    opacity: 1;
    bottom: 10%;
  }
  .hoverBg .widelink__cover::before {
    content: none;
  }
  .hoverBg .widelink__cover::after {
    content: none;
  }
  .hoverBg:hover {
    background: #FFF8DB !important;
  }
  .hoverBg:hover a {
    text-decoration: underline !important;
  }
}
/*=================================
  button
=================================*/
.btnWrap {
  margin: 20px auto 30px;
  text-align: center;
}
.btnWrap.center {
  text-align: center;
}
.btnWrap.right {
  text-align: right;
}
.btnWrap > * + * {
  margin-top: 9px;
}

.btn--red {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.428571;
  color: #fff;
  text-decoration: none;
  display: block;
  background: rgb(215, 1, 16);
  background: -moz-linear-gradient(180deg, rgb(215, 1, 16) 0%, rgb(185, 5, 18) 100%);
  background: -webkit-linear-gradient(180deg, rgb(215, 1, 16) 0%, rgb(185, 5, 18) 100%);
  background: linear-gradient(180deg, rgb(215, 1, 16) 0%, rgb(185, 5, 18) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d70110",endColorstr="#b90512",GradientType=1);
  border-radius: 4px;
  box-shadow: 2px 2px 0px 2px #AA000C;
  padding: 19px 5px 17px;
  text-align: center;
  position: relative;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .btn--red {
    font-size: clamp(15px, 1.317715959vw, 18px);
    padding: 1.2445095168vw 2.0497803807vw;
  }
}

.btn--ranking {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.428571;
  color: #fff;
  text-decoration: none;
  display: block;
  background: rgb(215, 1, 16);
  background: -moz-linear-gradient(180deg, rgb(215, 1, 16) 0%, rgb(185, 5, 18) 100%);
  background: -webkit-linear-gradient(180deg, rgb(215, 1, 16) 0%, rgb(185, 5, 18) 100%);
  background: linear-gradient(180deg, rgb(215, 1, 16) 0%, rgb(185, 5, 18) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d70110",endColorstr="#b90512",GradientType=1);
  border-radius: 4px;
  box-shadow: 2px 2px 0px 2px #AA000C;
  padding: 19px 5px 17px;
  text-align: center;
  position: relative;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .btn--ranking {
    font-size: clamp(15px, 1.317715959vw, 18px);
    padding: 1.2445095168vw 2.0497803807vw;
  }
}
.btn--ranking::before {
  content: "";
  width: 15px;
  height: 15px;
  display: inline-block;
  background: url(../img/common/icon/icon_crown_white.svg) no-repeat center center;
  background-size: contain;
  margin: 0 7px -2px 0;
}

.btn--blue {
  font-size: 1.4rem;
  font-weight: 500;
  color: #fff;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  background: #304A85;
  padding: 15px;
}

.btn--more {
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.42857;
  color: #000;
  border: 1px solid #004DA1;
  text-decoration: none;
  text-align: center;
  display: block;
  background: #fff;
  margin: 0 auto;
  padding: 20px 15px;
  border-radius: 3px;
  position: relative;
}
.btn--more::before {
  content: "";
  width: 16px;
  height: 16px;
  display: inline-block;
  background: url(../img/common/icon/icon_arrow_right_blue.svg) no-repeat center center;
  background-size: contain;
  margin: 0 8px -3px 0;
}

@media screen and (min-width: 767px) {
  /*=================================
    button
  =================================*/
  .btnWrap {
    display: flex;
    justify-content: center;
  }
  .btnWrap > * + * {
    margin: 0 20px;
  }
  .btn--red {
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 1.444444;
    border-radius: 5px;
    padding: 17px 28px;
    display: inline-block;
    transition-property: all;
    transition-duration: 0s;
    transition-timing-function: ease-out;
  }
  .btn--red:hover {
    box-shadow: none;
    transform: translate(4px, 4px);
  }
  .btn--ranking {
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 1.444444;
    border-radius: 5px;
    padding: 17px 28px;
    display: inline-block;
    transition-property: all;
    transition-duration: 0s;
    transition-timing-function: ease-out;
  }
  .btn--ranking::before {
    width: 20px;
    height: 20px;
    margin: 0 13px -2px 0;
  }
  .btn--ranking:hover {
    box-shadow: none;
    transform: translate(4px, 4px);
  }
  .btn--blue {
    font-size: 2.2rem;
    padding: 23px 37px;
  }
  .btn--blue:hover {
    opacity: 0.9;
  }
  .btn--more:hover {
    background: #004DA1;
    color: #fff;
  }
  .btn--more:hover::before {
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: ease-out;
    background: url(../img/common/icon/icon_arrow_right_white.svg) no-repeat center center;
    background-size: contain;
  }
}
/*=================================
  title
=================================*/
.ttl--base {
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.5;
  padding: 0 0 0 48px;
  margin: 0 0 40px;
  position: relative;
}
.ttl--base::before {
  content: "";
  width: 28px;
  height: 24px;
  display: inline-block;
  background: url(../img/common/ttl_line_4.svg) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 12px;
  left: 0;
}
.ttl--base.center {
  text-align: center;
}
.ttl--base.white {
  color: #fff;
}
.ttl--base .sm {
  font-size: 2rem;
}

.ttl--page {
  padding: 27px 1% 36px;
}
.ttl--page__inner {
  max-width: 1140px;
  margin: 0 auto;
}
.ttl--page .ttl {
  font-size: 3.6rem;
  font-weight: 700;
  line-height: 1.5;
  padding: 0 0 0 43px;
  position: relative;
}
.ttl--page .ttl::before {
  content: "";
  width: 34px;
  height: 30px;
  display: inline-block;
  background: url(../img/common/ttl_line_4.svg) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 15px;
  left: 0;
}
.ttl--page .text {
  font-size: 1.6rem;
  font-weight: 400;
  margin-top: 7px;
}

.balloon--yellow {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 3;
  color: #000;
  display: inline-block;
  padding: 0 16px;
  background: #FFE678;
  box-shadow: 3px 4px 0px 0px #DEBA10;
  position: relative;
}
.balloon--yellow::before {
  content: "";
  width: 4px;
  height: calc(100% + 4px);
  background: #DEBA10;
  display: inline-block;
  position: absolute;
  top: 0;
  right: -4px;
}
.balloon--yellow::after {
  content: "";
  border-style: solid;
  border-width: 11px 5px 0 5px;
  border-color: #FFE678 transparent transparent transparent;
  display: inline-block;
  filter: drop-shadow(3px 4px 0px #DEBA10);
  position: absolute;
  bottom: -11px;
  left: 50%;
  transform: translateX(-50%);
}
.balloon--yellow .icon {
  width: 31px;
  display: inline-block;
  vertical-align: middle;
  margin: 0 13px 0 0;
}

.balloon--red {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 3;
  color: #fff;
  display: inline-block;
  padding: 0 16px;
  background: #D70110;
  box-shadow: 3px 4px 0px 0px #AA000C;
  position: relative;
}
.balloon--red::before {
  content: "";
  width: 4px;
  height: calc(100% + 4px);
  background: #AA000C;
  display: inline-block;
  position: absolute;
  top: 0;
  right: -4px;
}
.balloon--red::after {
  content: "";
  border-style: solid;
  border-width: 11px 5px 0 5px;
  border-color: #D70110 transparent transparent transparent;
  display: inline-block;
  filter: drop-shadow(3px 4px 0px #AA000C);
  position: absolute;
  bottom: -11px;
  left: 50%;
  transform: translateX(-50%);
}
.balloon--red .icon {
  width: 31px;
  display: inline-block;
  vertical-align: middle;
  margin: 0 13px 0 0;
}

.balloon--blue {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 3;
  color: #fff;
  display: inline-block;
  padding: 0 16px;
  background: #004DA1;
  box-shadow: 3px 4px 0px 0px #00336B;
  position: relative;
}
.balloon--blue::before {
  content: "";
  width: 4px;
  height: calc(100% + 4px);
  background: #00336B;
  display: inline-block;
  position: absolute;
  top: 0;
  right: -4px;
}
.balloon--blue::after {
  content: "";
  border-style: solid;
  border-width: 11px 5px 0 5px;
  border-color: #004DA1 transparent transparent transparent;
  display: inline-block;
  filter: drop-shadow(3px 4px 0px #00336B);
  position: absolute;
  bottom: -11px;
  left: 50%;
  transform: translateX(-50%);
}
.balloon--blue .icon {
  width: 31px;
  display: inline-block;
  vertical-align: middle;
  margin: 0 13px 0 0;
}

@media screen and (min-width: 0) and (max-width: 767px) {
  /*=================================
    title
  =================================*/
  .ttl--base {
    font-size: 2.2rem;
    line-height: 1.545454;
    padding: 0 0 0 27px;
    margin: 0 0 30px;
  }
  .ttl--base::before {
    width: 20px;
    height: 17px;
    top: 9px;
  }
  .ttl--base .sm {
    font-size: 1.6rem;
    display: block;
  }
  .ttl--page {
    padding: 30px 4.358974359%;
  }
  .ttl--page .ttl {
    font-size: 2.2rem;
    padding: 0 0 0 27px;
  }
  .ttl--page .ttl::before {
    width: 20px;
    height: 17px;
    top: 8px;
  }
  .ttl--page .text {
    font-size: 1.4rem;
    margin-top: 10px;
    padding-left: 27px;
  }
  .balloon--yellow {
    line-height: 3.5;
    padding: 0 9px;
  }
  .balloon--yellow::after {
    border-width: 14px 8px 0 8px;
    bottom: -14px;
  }
  .balloon--yellow .icon {
    margin: 0 12px 0 0;
  }
  .balloon--red {
    line-height: 3.5;
    padding: 0 9px;
  }
  .balloon--red::after {
    border-width: 14px 8px 0 8px;
    bottom: -14px;
  }
  .balloon--red .icon {
    margin: 0 12px 0 0;
  }
  .balloon--blue {
    line-height: 3.5;
    padding: 0 9px;
  }
  .balloon--blue::after {
    border-width: 14px 8px 0 8px;
    bottom: -14px;
  }
  .balloon--blue .icon {
    margin: 0 12px 0 0;
  }
}
/*=================================
  table
=================================*/
table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 30px 0 50px;
  width: 100%;
  border: 1px solid #E3E3E3;
}
table caption {
  font-size: 2rem;
  font-weight: 700;
  margin: 0 0 30px;
  text-align: center;
  caption-side: top;
  position: relative;
}
table caption::before {
  content: "";
  width: 19px;
  height: 17px;
  display: inline-block;
  background: url(../img/common/ttl_line_4.svg) no-repeat center center;
  background-size: contain;
  margin: 0 9px -2px 0;
}
table thead th, table thead td {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.75;
  color: #fff;
  text-align: center;
  background: #004DA1;
}
table tbody tr td:nth-of-type(1) {
  background: #F5F6F9;
}
table tbody tr td.gray {
  background: #F5F6F9;
}
table tbody tr td:nth-of-type(3) {
  background: #FFF7D4;
}
table tbody tr td.yellow {
  background: #FFF7D4;
}
table th {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.75;
  color: #fff;
  text-align: center;
  background: #004DA1;
  padding: 19px 17px;
}
@media screen and (min-width: 767px) {
  table th {
    min-width: 90px;
  }
}
table td {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.75;
  padding: 19px 12px;
}
table th, table td {
  border: 1px solid #E3E3E3;
  vertical-align: middle;
}
table.scroll {
  white-space: nowrap;
  overflow: auto;
  display: block;
  border: none;
}
table.scroll ::-webkit-scrollbar {
  height: 5px;
}
table.scroll ::-webkit-scrollbar-track {
  background: #f1f1f1;
}
table.scroll ::-webkit-scrollbar-thumb {
  background: #bcbcbc;
}

.table--comparison {
  overflow: auto;
  margin: 50px 0;
}
.table--comparison table {
  border-collapse: collapse;
  border-spacing: 0px;
}
.table--comparison table th, .table--comparison table td {
  font-size: 1.6rem;
  text-align: center;
}
.table--comparison table th {
  max-width: 200px;
  text-align: center;
}
.table--comparison table td {
  max-width: 200px;
  background: #fff !important;
}
.table--comparison table img {
  max-width: 180px;
  height: auto;
  display: block;
  margin: 0 auto !important;
}
.table--comparison caption {
  margin: 0 0 20px;
  max-width: 1140px;
}
.table--comparison .fixedCell {
  position: sticky;
  left: 0;
  z-index: 500;
  font-weight: 400;
  color: #000;
  background: #FFF7D4 !important;
  min-width: 150px;
}
.table--comparison .fixedCell::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0px;
  display: block;
  border-right: 1px solid #E3E3E3;
}
.table--comparison .fixedCell::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: -1px;
  display: block;
  border-left: 1px solid #E3E3E3;
}
.table--comparison .specTb__btn {
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 1.46153;
  color: #fff;
  text-decoration: none;
  display: block;
  background: rgb(215, 1, 16);
  background: -moz-linear-gradient(180deg, rgb(215, 1, 16) 0%, rgb(185, 5, 18) 100%);
  background: -webkit-linear-gradient(180deg, rgb(215, 1, 16) 0%, rgb(185, 5, 18) 100%);
  background: linear-gradient(180deg, rgb(215, 1, 16) 0%, rgb(185, 5, 18) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d70110",endColorstr="#b90512",GradientType=1);
  border-radius: 3px;
  box-shadow: 2px 2px 0px 2px #AA000C;
  padding: 11px 5px 10px;
  text-align: center;
  position: relative;
}
.table--comparison .specTb__btn::before {
  content: "";
  width: 13px;
  height: 13px;
  display: inline-block;
  background: url(../img/common/icon/icon_arrow_right_white.svg) no-repeat center center;
  background-size: contain;
  margin: 0 7px -2px 0;
}
@media screen and (min-width: 767px) {
  .table--comparison .specTb__btn {
    transition-property: all;
    transition-duration: 0s;
    transition-timing-function: ease-out;
  }
  .table--comparison .specTb__btn:hover {
    box-shadow: none;
    transform: translate(4px, 4px);
  }
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .table--comparison .specTb__btn {
    font-size: clamp(15px, 1.317715959vw, 18px);
    padding: 1.2445095168vw 2.0497803807vw;
  }
}

.scrollAnnounce {
  display: none;
}

@media screen and (max-width: 767px) {
  /*=================================
    table
  =================================*/
  table {
    margin: 20px 0 40px;
    /* スクロールのつまみ部分の設定 */
  }
  table:not(.noscroll) {
    display: block;
    white-space: nowrap;
    overflow-x: auto;
    border: none;
  }
  table caption {
    font-size: 1.5rem;
    margin: 15px 0;
  }
  table th, table td {
    padding: 14px 20px;
  }
  table::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  table::-webkit-scrollbar-track {
    border-radius: none;
    background: #d6d6d6;
  }
  table::-webkit-scrollbar-thumb {
    background: #004DA1;
  }
  .table--comparison {
    margin: 38px 0 60px;
  }
  .table--comparison table {
    white-space: normal;
  }
  .table--comparison table th, .table--comparison table td {
    font-size: 1.8rem;
    padding: 12px;
  }
  .table--comparison table th {
    min-width: 200px;
  }
  .table--comparison table img {
    max-width: 100%;
  }
  .table--comparison caption {
    margin: 0 0 18px;
    text-align: left;
  }
  .table--comparison .fixedCell {
    font-size: 1.6rem;
    font-weight: 700;
    min-width: 110px;
    top: -1px;
    left: -2px;
  }
  .table--comparison .fixedCell::after {
    width: calc(100% + 2px);
    left: -1px;
    top: -1px;
  }
  .table--comparison .specTb__btn {
    font-size: 1.9rem;
  }
  .table--comparison .specTb__btn::before {
    width: 20px;
    height: 20px;
    margin: 0 7px -4px 0;
  }
  .scrollAnnounce {
    display: block;
    text-align: center;
  }
  .scrollAnnounce .text {
    font-size: 1.3rem;
    font-weight: 600;
    color: #fff;
    background: #304A85;
    padding: 3px 6px;
    position: relative;
  }
  .scrollAnnounce .text::after {
    content: "";
    border-style: solid;
    border-width: 7px 6.5px 0 6.5px;
    border-color: #304A85 transparent transparent transparent;
    display: inline-block;
    position: absolute;
    bottom: -7px;
    left: 50%;
    transform: translateX(-50%);
  }
}
/*=================================
  breadcrumb
=================================*/
.breadcrumb {
  border-bottom: 1px solid #DEDEDE;
  padding: 14px 1%;
}
.breadcrumb ul li {
  font-size: 1.4rem;
  line-height: 1;
  color: #B8B8B8;
  display: inline-block;
}
.breadcrumb ul li a {
  font-size: 1.4rem;
  line-height: 1;
  color: #000;
  margin-right: 13px;
}
@media screen and (min-width: 767px) {
  .breadcrumb ul li a:hover {
    color: #304A85;
    text-decoration: none;
  }
}
.breadcrumb ul li a::after {
  content: "";
  border-style: solid;
  border-width: 5.5px 0 5.5px 6px;
  border-color: transparent transparent transparent #B8B8B8;
  display: inline-block;
  margin-left: 13px;
}

@media screen and (min-width: 0) and (max-width: 767px) {
  /*=================================
    breadcrumb
  =================================*/
  .breadcrumb {
    padding: 10px 4.358974359%;
  }
  .breadcrumb ul li {
    font-size: 1.2rem;
    line-height: 1.5;
  }
  .breadcrumb ul li a {
    font-size: 1.2rem;
    line-height: 1.5;
    margin-right: 7px;
  }
  .breadcrumb ul li a::after {
    margin-left: 7px;
    margin-bottom: -1px;
  }
}
/*=================================
  pager
=================================*/
.pager {
  margin: 50px auto 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pagerList__item {
  margin-right: 3px;
  display: inline-block;
}
.pagerList__item a, .pagerList__item span {
  font-size: 1.5rem;
  line-height: 1;
  padding: 13px 16px;
  display: block;
}
.pagerList__item a {
  color: #304A85;
  text-decoration: none;
  background: #fff;
}
@media screen and (min-width: 767px) {
  .pagerList__item a:hover {
    color: #fff;
    background: #304A85;
  }
}
.pagerList__item span {
  color: #fff;
  background: #304A85;
}
.pager .listCount {
  font-size: 1.4rem;
}

@media screen and (max-width: 767px) {
  /*=================================
    pager
  =================================*/
  .pager {
    margin: 20px auto 0;
    display: block;
    text-align: center;
  }
  .pagerList__item a, .pagerList__item span {
    font-size: 1.2rem;
    line-height: 1;
    padding: 13px 16px;
    display: block;
  }
  .pager .listCount {
    font-size: 1.2rem;
    margin: 15px 0 0;
  }
}
/*=================================
  topPage
=================================*/
.home {
  /* メインビジュアル */
}
.home .secWrap {
  padding: 90px 1% 60px;
  background: #fff;
}
.home .secWrap.blue {
  background: #E8F1FB;
}
.home .secWrap.gray {
  background: #F5F6F9;
}
.home .secWrap__inner {
  max-width: 1140px;
  margin: 0 auto;
}
.home .mv {
  background: url(../img/top/bg_mv.jpg) no-repeat right top #F5F6F9;
  background-size: contain;
  padding: 80px 0 0 90px;
  position: relative;
}
.home .mv__inner {
  max-width: 1276px;
  margin: 0 auto;
  background: #fff;
  padding: 75px 56px 56px;
  position: relative;
}
.home .mv__inner::after {
  content: "";
  width: clamp(239px, 34.9926793558vw, 478px);
  height: clamp(223px, 32.6500732064vw, 446px);
  display: inline-block;
  background: url(../img/top/img_mv.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  right: 36px;
  bottom: -20px;
}
.home .mv__ttl {
  font-size: 1.9rem;
  line-height: 3.7;
  letter-spacing: 0.04em;
  box-shadow: 6px 6px 0px 0px #DEBA10;
  position: absolute;
  top: -30px;
  left: -47px;
}
.home .mv__ttl::before {
  width: 6px;
  height: calc(100% + 6px);
  right: -6px;
}
.home .mv__ttl::after {
  border-width: 18px 10px 0 10px;
  bottom: -18px;
  filter: drop-shadow(6px 6px 0px #DEBA10);
}
.home .mv__ttl .icon {
  width: 49px;
  margin: 0 15px 0 0;
}
.home .mv__catch {
  font-size: 3.6rem;
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 1.77777;
  margin: 0 0 30px;
  padding-right: clamp(239px, 25.6222547584vw, 350px);
  position: relative;
  z-index: 1;
}
.home .mv__catch .em {
  color: #004DA1;
}
.home .mv__cont {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.75;
  padding-right: clamp(239px, 25.6222547584vw, 350px);
  position: relative;
  z-index: 1;
}
.home .mv__cont .em {
  font-weight: 500;
  color: #D70110;
}

@media screen and (max-width: 767px) {
  /*=================================
    topPage
  =================================*/
  .home {
    /* メインビジュアル */
  }
  .home .secWrap {
    padding: 40px 6.4102564103%;
  }
  .home .mv {
    background: url(../img/top/bg_mv.jpg) no-repeat right -37px top #F5F6F9;
    background-size: contain;
    padding: 30px 0 0 5.1282051282%;
  }
  .home .mv__inner {
    padding: 68px 5.1282051282% 27px;
  }
  .home .mv__inner::after {
    content: none;
  }
  .home .mv__ttl {
    font-size: 1.5rem;
    box-shadow: 5px 5px 0px 0px #DEBA10;
    position: absolute;
    top: -10px;
    left: 0;
  }
  .home .mv__ttl::before {
    width: 5px;
    height: calc(100% + 5px);
    right: -5px;
  }
  .home .mv__ttl::after {
    border-width: 16px 7px 0 7px;
    bottom: -16px;
    filter: drop-shadow(5px 5px 0px #DEBA10);
  }
  .home .mv__ttl .icon {
    width: 39px;
    margin: 0 2px 0 0;
  }
  .home .mv__catch {
    font-size: 2.6rem;
    line-height: 1.38461;
    margin: 0 0 30px;
    padding-right: clamp(96px, 38.4615384615vw, 150px);
  }
  .home .mv__catch::after {
    content: "";
    width: clamp(96px, 49.4871794872vw, 193px);
    height: clamp(88px, 44.8717948718vw, 175px);
    display: inline-block;
    background: url(../img/top/img_mv.png) no-repeat center center;
    background-size: contain;
    position: absolute;
    right: 0;
    bottom: -25px;
  }
  .home .mv__cont {
    font-size: 1.4rem;
    line-height: 2;
    padding-right: 0;
  }
}
/*=================================
  お問い合わせ
=================================*/
.formBox h1 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.formBox h2 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.formBox h3 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.formBox h4 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.formBox h5 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.formBox h6 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.formBox .infoBox {
  display: flex;
  align-items: flex-start;
}
.formBox .infoBox .thumb {
  width: 42%;
  max-width: 359px;
  margin-right: 46px;
}
.formBox .infoBox .thumb img {
  margin: 0;
}
.formBox .infoBox .contact {
  width: calc(100% - (42% + 46px));
}
.formBox .infoBox .contact .infoBoxTtl {
  font-size: 2rem;
  line-height: 1.8;
  font-weight: 400;
}
.formBox .infoBox .contact .address .info--tel {
  margin-top: 20px;
}
.formBox .infoBox .contact .address .info--tel .tel .num {
  font-size: 4.9rem;
  font-weight: bold;
  line-height: 1;
  text-decoration: none;
}
@media screen and (min-width: 767px) {
  .formBox .infoBox .contact .address .info--tel .tel .num {
    pointer-events: none;
  }
}
.formBox .infoBox .contact .address .info--tel .tel .num::before {
  content: "";
  width: 38px;
  height: 38px;
  display: inline-block;
  background: url(../img/common/icon/icon_tel_w.png) no-repeat center center;
  background-size: contain;
  margin: 0 10px -2px 0;
}
.formBox .infoBox .contact .address .info--tel .tel .num:hover {
  opacity: 0.8;
}
.formBox .infoBox .contact .address .info--tel .receptionTime {
  display: flex;
  font-size: 1.6rem;
  font-weight: 500;
  margin-top: 20px;
}
.formBox .infoBox .contact .address .info--tel .receptionTime dt {
  width: 5em;
  font-weight: bold;
}
.formBox .infoBox .contact .address .info--tel .receptionTime dd {
  width: calc(100% - 5em);
}
.formBox .step {
  display: inline-block;
  counter-reset: stepNum;
  margin-bottom: 55px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.formBox .step::before {
  content: "";
  width: calc(100% - 5.5rem);
  height: 1px;
  display: inline-block;
  background: #D2D2D2;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.formBox .step__item {
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
  padding-top: 26px;
  display: inline-block;
  position: relative;
}
.formBox .step__item::before {
  counter-increment: stepNum;
  content: "STEP0" counter(stepNum);
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.625;
  color: #304A85;
  margin-bottom: 10px;
}
.formBox .step__item::after {
  content: "";
  width: 15px;
  height: 15px;
  display: inline-block;
  border-radius: 50px;
  background: #D2D2D2;
  position: absolute;
  top: -8px;
  left: 50%;
  transform: translateX(-50%);
}
.formBox .step__item.active::after {
  background: #304A85;
}
.formBox .step__item + .step__item {
  margin-left: 59px;
}
.formBox .btnWrap {
  margin-top: 40px;
}
.formBox .btn--form {
  display: block;
  text-align: center;
}
.formBox .btn--form:not(.back) input {
  font-size: 2rem;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
  padding: 30px 68px 28px;
  border-radius: 5px;
  background: #304A85;
  box-shadow: 0px 5px 0 0px #162545;
  cursor: pointer;
}
@media screen and (min-width: 767px) {
  .formBox .btn--form:not(.back):hover input {
    box-shadow: none !important;
    transform: translateY(5px);
  }
}
.formBox .btn--form.back input {
  font-size: 1.4rem;
  color: #999;
  cursor: pointer;
}

/* =================================
  お問い合わせフォーム
================================= */
.text--security {
  font-size: 1.6rem;
  margin-bottom: 30px;
  text-align: center;
}

.contactForm table {
  width: 100%;
}
.contactForm th {
  font-size: 1.6rem;
  padding: 15px 65px 15px 20px;
  text-align: left;
  width: 240px;
  position: relative;
}
.contactForm th .sub {
  font-size: 1.4rem;
  display: block;
}
.contactForm th span:not(.sub) {
  font-size: 1.2rem;
  font-weight: normal;
  letter-spacing: 0.04em;
  line-height: 1;
  color: #fff;
  background: #D2D2D2;
  padding: 5px 12px;
  position: absolute;
  top: 20px;
  right: 10px;
}
.contactForm th .required {
  background: #D70110 !important;
}
.contactForm td {
  font-size: 1.6rem;
  padding: 17px 15px 17px 20px;
  width: calc(100% - 240px);
  text-align: left;
}
.contactForm td p {
  line-height: 1.65;
}
.contactForm td * {
  font-size: 1.6rem;
}
.contactForm td input, .contactForm td textarea, .contactForm td select {
  background: #fff;
  border: 2px solid #D2D2D2;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
}
.contactForm td textarea {
  min-height: 170px;
}
.contactForm td .error {
  font-size: 1.4rem;
  color: #D70110;
  margin: 10px 0 0;
  display: inline-block;
}
.contactForm .confirmingTxt {
  text-align: center;
  margin: 40px 0;
}
.contactForm .confirmingTxt p {
  font-size: 1.6rem;
}
.contactForm .confirmingTxt .checkText {
  font-size: 1.8rem;
  margin-top: 40px;
  display: inline-block;
}
.contactForm .confirmingTxt .checkText input {
  margin-right: 10px;
}
.contactForm .confirmingTxt input {
  -webkit-appearance: checkbox;
}
.contactForm .confirmingTxt a {
  font-weight: bold;
  text-decoration: none;
}
.contactForm .confirmingTxt a:hover {
  text-decoration: underline;
}

.consultForm__item {
  display: flex;
  align-items: center;
  background: #F5F8FF;
  padding: 38px 20px;
  border: 1px solid #D2D2D2;
}
.consultForm__item + .consultForm__item {
  margin-top: 20px;
}
.consultForm__ttl {
  font-size: 1.6rem;
  font-weight: bold;
  color: #304A85;
  width: 230px;
  padding-right: 55px;
  position: relative;
}
.consultForm__ttl .sub {
  font-size: 1.4rem;
  display: block;
}
.consultForm__ttl span:not(.sub) {
  font-size: 1.2rem;
  font-weight: normal;
  letter-spacing: 0.04em;
  line-height: 1;
  color: #fff;
  background: #D2D2D2;
  padding: 5px 12px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
.consultForm__ttl .required {
  background: #D70110 !important;
}
.consultForm__list {
  font-size: 1.6rem;
  width: calc(93% - 230px);
  padding-left: 27px;
}
.consultForm__list p {
  line-height: 1.65;
}
.consultForm__list * {
  font-size: 1.6rem;
}
.consultForm__list input:not([type=radio]):not([type=checkbox]), .consultForm__list textarea, .consultForm__list select {
  background: #fff;
  border: 2px solid #D2D2D2;
  padding: 10px;
}
.consultForm__list input:not([type=radio]):not([type=checkbox]):not([class=widthNormal]), .consultForm__list textarea, .consultForm__list select {
  width: 100%;
  box-sizing: border-box;
}
.consultForm__list .mwform-radio-field {
  display: inline-block;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 3rem;
}
.consultForm__list .mwform-radio-field label {
  display: inline-block;
}
.consultForm__list input[type=radio] + span {
  padding-left: 2.8rem;
  display: inline-block;
  position: relative;
}
.consultForm__list input[type=radio] + span::before {
  content: "";
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  margin: auto;
  border-radius: 50%;
  border: solid 2px #D2D2D2;
  background-color: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}
.consultForm__list input[type=radio]:checked + span::after {
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: auto 0;
  background-color: #304A85;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 4px;
}
.consultForm__list .mwform-checkbox-field {
  display: inline-block;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 3rem;
}
.consultForm__list .mwform-checkbox-field label {
  display: inline-block;
}
.consultForm__list input[type=checkbox] + span {
  padding-left: 2.8rem;
  display: inline-block;
  position: relative;
}
.consultForm__list input[type=checkbox] + span::before {
  content: "";
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  margin: auto;
  border-radius: 4px;
  border: solid 2px #D2D2D2;
  background-color: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}
.consultForm__list input[type=checkbox]:checked + span::after {
  content: "";
  width: 11px;
  height: 7px;
  display: inline-block;
  background: url(../img/common/icon/icon_check.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 10px;
  left: 5px;
}
.consultForm__list textarea {
  min-height: 170px;
}
.consultForm__list .error {
  font-size: 1.4rem;
  color: #D70110;
  margin: 10px 0 0;
  display: block;
}
.consultForm__list .yoyaku {
  display: flex;
  align-items: center;
}
.consultForm__list .yoyaku .rank {
  font-weight: bold;
  color: #304A85;
  width: 85px;
}
.consultForm__list .yoyaku .yoyakuBox {
  width: calc(100% - 85px);
  display: flex;
}
.consultForm__list .yoyaku .yoyakudate {
  width: calc((100% - 15px) / 2) !important;
}
.consultForm__list .yoyaku .yoyakujikan {
  width: calc((100% - 15px) / 2) !important;
  margin-left: 15px;
}
.consultForm__list .yoyaku + .yoyaku {
  margin-top: 15px;
}
.consultForm .confirmingTxt {
  text-align: center;
  margin: 37px 0;
}
.consultForm .confirmingTxt p {
  font-size: 1.4rem;
}
.consultForm .confirmingTxt input {
  -webkit-appearance: checkbox;
}
.consultForm .confirmingTxt a {
  text-decoration: underline;
}
.consultForm .confirmingTxt a:hover {
  color: #304A85;
}
.consultForm + .consultForm {
  border-top: 1px dashed #D2D2D2;
  padding-top: 20px;
  margin-top: 20px;
}

.completedForm .completedMessage {
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 0.06em;
  display: inline-block;
  margin: 24px 0 0;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.completedForm .completedMessage::after {
  content: "";
  width: 100%;
  height: 3px;
  background: #304A85;
  display: inline-block;
}
.completedForm p {
  font-size: 1.5rem;
  text-align: center;
  margin: 40px 0 0;
}
.completedForm table {
  width: initial;
  margin: 40px auto 45px;
}
.completedForm th, .completedForm td {
  width: auto;
}
.completedForm th {
  font-size: 1.6rem;
  font-weight: bold;
  vertical-align: middle;
  padding: 15px 30px;
}
.completedForm td {
  vertical-align: middle;
  padding: 15px 30px;
}
.completedForm td .tel {
  font-size: 3.4rem;
  font-weight: bold;
  color: #304A85;
  line-height: 1;
  text-decoration: none;
  margin: 0;
}
@media screen and (min-width: 767px) {
  .completedForm td .tel {
    pointer-events: none;
  }
}
.completedForm td .receptionTime {
  display: flex;
  margin: 10px 0 0;
}
.completedForm td .receptionTime dt {
  font-size: 1.3rem;
  font-weight: bold;
  color: #304A85;
  width: 5.5em;
}
.completedForm td .receptionTime dd {
  font-size: 1.3rem;
  width: calc(100% - 5.5em);
}

@media screen and (max-width: 767px) {
  /*=================================
    お問い合わせ
  =================================*/
  .formBox h1 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .formBox h2 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .formBox h3 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .formBox h4 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .formBox h5 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .formBox h6 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .formBox .infoBox {
    display: block;
    margin-top: 0;
    overflow: hidden;
  }
  .formBox .infoBox .thumb {
    width: 30%;
    margin-right: 0;
    margin-bottom: 15px;
    float: left;
  }
  .formBox .infoBox .contact {
    width: 100%;
  }
  .formBox .infoBox .contact .infoBoxTtl {
    font-size: 1.5rem;
    line-height: 1.5;
    width: calc(100% - (30% + 15px));
    margin-left: auto;
  }
  .formBox .infoBox .contact .address .info--tel {
    margin-top: 0;
  }
  .formBox .infoBox .contact .address .info--tel .tel {
    width: calc(100% - (30% + 15px));
    margin-left: auto;
    margin-top: 15px;
  }
  .formBox .infoBox .contact .address .info--tel .tel .num {
    font-size: 1.8rem;
    color: #fff;
    background: #304A85;
    display: block;
    padding: 10px 10px;
    border-radius: 3px;
    text-align: center;
  }
  .formBox .infoBox .contact .address .info--tel .tel .num::before {
    width: 18px;
    height: 18px;
    display: inline-block;
    margin: 0 7px -3px 0;
  }
  .formBox .infoBox .contact .address .info--tel .receptionTime {
    width: 100%;
    font-size: 1.4rem;
    margin-top: 15px;
  }
  .formBox .step {
    margin-bottom: 40px;
  }
  .formBox .step::before {
    width: calc(100% - 5rem);
  }
  .formBox .step__item {
    font-size: 1.6rem;
    padding-top: 20px;
  }
  .formBox .step__item::before {
    font-size: 1.4rem;
    margin-bottom: 5px;
  }
  .formBox .step__item::after {
    width: 13px;
    height: 13px;
    top: -6px;
  }
  .formBox .step__item.active::after {
    background: #304A85;
  }
  .formBox .step__item + .step__item {
    margin-left: 15.1282051282vw;
  }
  .formBox .btnWrap {
    margin-top: 35px;
  }
  .formBox .btn--form:not(.back) input {
    font-size: 1.6rem;
    padding: 15px 3.5897435897% 13px;
    border-radius: 4px;
    box-shadow: 0px 4px 0 0px #162545;
  }
  /* =================================
    お問い合わせフォーム
  ================================= */
  .text--security {
    font-size: 1.4rem;
  }
  .contactForm table {
    display: table;
    white-space: initial;
  }
  .contactForm th {
    font-size: 1.5rem;
    padding: 15px 3.5897435897%;
    width: 100%;
    display: block;
  }
  .contactForm th span:not(.sub) {
    font-size: 1.1rem;
    letter-spacing: 0.4em;
    padding: 4px 4px 4px 8px;
    margin-left: 1.5rem;
    position: static;
    transform: translateY(0);
  }
  .contactForm td {
    padding: 15px 3.5897435897%;
    width: 100%;
    display: block;
  }
  .contactForm td p {
    line-height: 1.5;
  }
  .contactForm td input, .contactForm td textarea, .contactForm td select {
    padding: 12px;
  }
  .contactForm td .error {
    font-size: 1.3rem;
  }
  .contactForm .confirmingTxt {
    margin: 30px 0;
  }
  .contactForm .confirmingTxt p {
    font-size: 1.4rem;
  }
  .contactForm .confirmingTxt .checkText {
    font-size: 1.6rem;
    margin-top: 10px;
  }
  .consultForm__item {
    display: block;
    padding: 10px 3.5897435897% 20px;
  }
  .consultForm__ttl {
    font-size: 1.5rem;
    padding: 15px 0;
    width: 100%;
    display: block;
  }
  .consultForm__ttl .sub {
    font-size: 1.4rem;
    display: inline-block;
  }
  .consultForm__ttl span:not(.sub) {
    font-size: 1.1rem;
    letter-spacing: 0.4em;
    padding: 4px 4px 4px 8px;
    margin-left: 1.5rem;
    position: static;
    transform: translateY(0);
  }
  .consultForm__list {
    width: 100%;
    padding-left: 0;
  }
  .consultForm__list p {
    line-height: 1.5;
  }
  .consultForm__list .mwform-radio-field {
    margin-right: 0.5rem;
  }
  .consultForm__list .mwform-checkbox-field {
    margin-right: 0.5rem;
  }
  .consultForm__list input[type=checkbox]:checked + span::after {
    width: 15px;
    height: 10px;
    top: 6px;
    left: 3px;
  }
  .consultForm__list .error {
    font-size: 1.3rem;
  }
  .consultForm__list .yoyaku {
    flex-wrap: wrap;
  }
  .consultForm__list .yoyaku .rank {
    width: 100%;
    margin-bottom: 10px;
  }
  .consultForm__list .yoyaku .yoyakuBox {
    width: 100%;
    display: block;
  }
  .consultForm__list .yoyaku .yoyakudate {
    width: 100% !important;
  }
  .consultForm__list .yoyaku .yoyakujikan {
    width: 100% !important;
    margin: 10px 0 0;
  }
  .consultForm__list .yoyaku + .yoyaku {
    margin-top: 20px;
  }
  .consultForm .confirmingTxt {
    text-align: center;
    margin: 37px 0;
  }
  .consultForm .confirmingTxt p {
    font-size: 1.4rem;
  }
  .completedForm p {
    margin: 30px 0 0;
  }
  .completedForm table {
    display: table;
    white-space: initial;
    margin: 40px auto 45px;
  }
  .completedForm th, .completedForm td {
    width: auto;
  }
  .completedForm th {
    font-size: 1.4rem;
    padding: 15px 3.5897435897%;
  }
  .completedForm td {
    padding: 15px 3.5897435897%;
  }
  .completedForm td .tel {
    font-size: 3rem;
  }
  .completedForm td .receptionTime {
    text-align: left;
  }
}
/*=================================
  trademark
=================================*/
.trademark {
  /* キービジュアル */
  /* メインコンテンツ */
}
.trademark .ttlWrap {
  background: #FFF7D4;
  padding: 50px 0 0 2.2%;
  position: relative;
}
.trademark .ttlWrap__inner {
  max-width: 1276px;
  margin: 0 auto;
  background: #fff;
  padding: 67px 4% 87px;
  position: relative;
}
.trademark .ttlWrap__inner::before {
  content: "";
  width: 10px;
  height: 100%;
  display: block;
  border-left: 3px solid #004DA1;
  border-right: 3px solid #D70110;
  position: absolute;
  top: 0;
  left: 0;
}
.trademark .ttlWrap__ttl {
  font-size: 1.9rem;
  line-height: 3.7;
  letter-spacing: 0.04em;
  box-shadow: 6px 6px 0px 0px #AA000C;
  position: absolute;
  top: -30px;
  left: -2%;
}
.trademark .ttlWrap__ttl::before {
  width: 6px;
  height: calc(100% + 6px);
  right: -6px;
}
.trademark .ttlWrap__ttl::after {
  border-width: 18px 10px 0 10px;
  bottom: -18px;
  filter: drop-shadow(4px 6px 0px #AA000C);
}
.trademark .ttlWrap__ttl .icon {
  width: 56px;
  margin: 0 10px 0 0;
}
.trademark .ttlWrap__catch {
  font-size: 3.6rem;
  font-weight: 700;
  line-height: 1.333333;
  margin: 0 0 17px;
  max-width: 1070px;
}
.trademark .ttlWrap__band {
  font-size: 2.4rem;
  font-weight: 700;
  color: #D70110;
  text-align: center;
  display: block;
  background: #FFE6E8;
  max-width: 1070px;
}
.trademark .ttlWrap__band::before {
  content: "";
  width: 24px;
  height: 21px;
  display: inline-block;
  background: url(../img/common/icon/icon_bullhorn.svg) no-repeat center center;
  background-size: contain;
  margin: 0 14px -2px 0;
}
.trademark .ttlWrap + .trademarkCont {
  position: relative;
  top: -60px;
}
.trademarkCont {
  background: #fff;
}
.trademarkCont__inner {
  max-width: 1276px;
  margin: 0 auto;
  background: #fff;
  padding: 0 4% 30px;
}
.trademarkCont .headWrap {
  max-width: 1070px;
  display: flex;
  align-items: flex-start;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .trademarkCont .headWrap .thumb {
    width: 21.9619326501vw;
  }
}
.trademarkCont .headWrap .thumb + .pointList {
  width: calc(100% - 330px);
  margin-left: 30px;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .trademarkCont .headWrap .thumb + .pointList {
    width: calc(100% - 24.1581259151vw);
    margin-left: 2.196193265vw;
  }
}
.trademarkCont .headWrap .pointList {
  padding: 38px 18px;
  background: #FFF;
  border: 10px solid #FFF1B4;
  clear: both;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .trademarkCont .headWrap .pointList {
    padding: 2.7818448023vw 1.9765739385vw;
  }
}
.trademarkCont .headWrap .pointList__item {
  font-size: 2rem;
  font-weight: 700;
  line-height: 2;
  padding: 3px 12px 3px 40px;
  border-bottom: 1px dashed #CCCCCC;
  position: relative;
}
.trademarkCont .headWrap .pointList__item::before {
  content: "";
  width: 24px;
  height: 24px;
  background: url(../img/common/icon/icon_ul_yellow.svg) no-repeat center center;
  background-size: contain;
  display: inline-block;
  position: absolute;
  top: 19px;
  left: 3px;
}
.trademarkCont .status {
  margin: 25px 0 40px;
}
.trademarkCont .status .statusList {
  max-width: 1084px;
  margin: 0 0 13px -7px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.trademarkCont .status .statusList__item {
  background: #004DA1;
  border: 3px solid #004DA1;
  border-radius: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  margin: 0 7px 7px;
  width: 94px;
  height: 94px;
  position: relative;
}
.trademarkCont .status .statusList__item .wrap {
  font-size: 3.3rem;
  font-weight: 500;
  line-height: 1.121212;
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.trademarkCont .status .statusList__item .sm {
  font-size: 1.8rem;
  display: block;
}
.trademarkCont .status .statusList__item .md {
  font-size: 2.3rem;
  display: block;
}
.trademarkCont .status .statusList__item.no {
  border-color: #CCCCCC;
  background: #fff;
}
.trademarkCont .status .statusList__item.no::after {
  content: "NO";
  font-size: 1.4rem;
  line-height: 1;
  color: #fff;
  padding: 2px 9px;
  background: #D70110;
  display: inline-block;
  position: absolute;
  right: -3px;
  bottom: -3px;
}
.trademarkCont .status .statusList__item.no .wrap {
  color: #CCCCCC;
}
.trademarkCont .status .note {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2;
  max-width: 1070px;
}
.trademarkCont .btn {
  background: #F5F6F9;
  padding: 15px 18px 20px;
  margin: 40px 0 0;
  max-width: 1070px;
}
.trademarkCont .btn a {
  font-size: 2.8rem;
  font-weight: 500;
  color: #fff;
  background: rgb(215, 1, 16);
  background: -moz-linear-gradient(180deg, rgb(215, 1, 16) 0%, rgb(185, 5, 18) 100%);
  background: -webkit-linear-gradient(180deg, rgb(215, 1, 16) 0%, rgb(185, 5, 18) 100%);
  background: linear-gradient(180deg, rgb(215, 1, 16) 0%, rgb(185, 5, 18) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d70110",endColorstr="#b90512",GradientType=1);
  border-radius: 5px;
  box-shadow: 4px 4px 0px 4px #AA000C;
  padding: 30px 5px;
  text-align: center;
  text-decoration: none;
  display: block;
}
.trademarkCont .btn a::before {
  content: "";
  width: 26px;
  height: 26px;
  display: inline-block;
  background: url(../img/common/icon/icon_arrow_right.svg) no-repeat center center;
  background-size: contain;
  margin: 0 10px -2px 0;
}
@media screen and (min-width: 767px) {
  .trademarkCont .btn a {
    transition-property: all;
    transition-duration: 0s;
    transition-timing-function: ease-out;
  }
  .trademarkCont .btn a:hover {
    box-shadow: none;
    transform: translate(8px, 8px);
    opacity: 0.9;
  }
}
.trademarkCont .other {
  padding: 33px 0 0;
  margin: 40px 0 0;
  max-width: 1070px;
  position: relative;
}
.trademarkCont .other::before {
  content: "";
  width: 108%;
  height: 3px;
  display: block;
  background: #004DA1;
  position: absolute;
  top: 0;
  left: -4%;
}
.trademarkCont .conditions {
  margin: 0 0 30px;
}
.trademarkCont .conditions__ttl {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.5555;
  margin: 0 0 10px;
}
.trademarkCont .conditions__ttl::before {
  content: "";
  width: 18px;
  height: 21px;
  display: inline-block;
  background: url(../img/common/icon/icon_user.svg) no-repeat center center;
  background-size: contain;
  margin: 0 8px -2px 0;
}
.trademarkCont .conditions__text {
  font-size: 1.6rem;
  line-height: 1.75;
}
.trademarkCont .conditions__text .note {
  font-size: 1.2rem;
  display: block;
}
.trademarkCont .spec {
  margin: 0 0 30px;
}
.trademarkCont .spec__ttl {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.5555;
  margin: 0 0 10px;
}
.trademarkCont .spec__ttl::before {
  content: "";
  width: 22px;
  height: 20px;
  display: inline-block;
  background: url(../img/common/icon/icon_table.svg) no-repeat center center;
  background-size: contain;
  margin: 0 8px -2px 0;
}
.trademarkCont .spec__table {
  margin: 0;
  border-left: none;
  border-right: none;
}
.trademarkCont .spec__table th, .trademarkCont .spec__table td {
  font-size: 1.4rem;
  color: #000;
  text-align: center;
  border-left: none;
  border-right: none;
  border-color: #CCCCCC;
}
.trademarkCont .spec__table th {
  font-weight: 500;
  background: #F5F6F9;
}
.trademarkCont .spec__table td {
  font-weight: 400;
  background: #fff;
}

@media screen and (max-width: 767px) {
  /*=================================
    trademark
  =================================*/
  .trademark {
    /* キービジュアル */
    /* メインコンテンツ */
  }
  .trademark .ttlWrap {
    padding: 30px 0 0 4.358974359%;
  }
  .trademark .ttlWrap__inner {
    padding: 0 4.358974359% 0 5.8974358974%;
  }
  .trademark .ttlWrap__inner::before {
    width: 7px;
    border-left: 2px solid #004DA1;
    border-right: 2px solid #D70110;
  }
  .trademark .ttlWrap__ttl {
    font-size: 1.6rem;
    line-height: 1.375;
    box-shadow: 6px 6px 0px 0px #AA000C;
    position: relative;
    padding: 6px 5px 5px 50px;
    top: -15px;
    left: 0;
  }
  .trademark .ttlWrap__ttl::before {
    width: 6px;
    height: calc(100% + 6px);
    right: -6px;
  }
  .trademark .ttlWrap__ttl::after {
    border-width: 18px 10px 0 10px;
    bottom: -18px;
    filter: drop-shadow(3px 5px 0px #AA000C);
  }
  .trademark .ttlWrap__ttl .icon {
    width: 41px;
    margin: 0;
    position: absolute;
    top: 5px;
    left: 6px;
  }
  .trademark .ttlWrap__catch {
    font-size: 2.2rem;
    line-height: 1.363636;
    margin: 0 0 15px;
  }
  .trademark .ttlWrap__band {
    font-size: 1.6rem;
    padding: 2px 0;
  }
  .trademark .ttlWrap__band::before {
    width: 15px;
    height: 14px;
    margin: 0 10px -2px 0;
  }
  .trademark .ttlWrap + .trademarkCont {
    top: 0;
  }
  .trademarkCont {
    padding: 20px 6.4102564103% 0;
  }
  .trademarkCont__inner {
    padding: 0 0 40px;
  }
  .trademarkCont .headWrap {
    display: block;
  }
  .trademarkCont .headWrap .thumb {
    margin: 0 auto;
    display: block;
  }
  .trademarkCont .headWrap .thumb + .pointList {
    width: 100%;
    margin: 10px 0 0;
  }
  .trademarkCont .headWrap .pointList {
    padding: 0;
    border: none;
  }
  .trademarkCont .headWrap .pointList__item {
    font-size: 1.4rem;
    line-height: 1.42857;
    padding: 10px 15px 10px 27px;
  }
  .trademarkCont .headWrap .pointList__item::before {
    width: 16px;
    height: 16px;
    top: 19px;
    left: 3px;
  }
  .trademarkCont .status {
    margin: 20px 0 30px;
  }
  .trademarkCont .status .statusList {
    margin: 0 0 20px -5px;
    justify-content: center;
  }
  .trademarkCont .status .statusList__item {
    margin: 0 4px 8px;
    width: 61px;
    height: 61px;
  }
  .trademarkCont .status .statusList__item .wrap {
    font-size: 1.9rem;
    line-height: 1.10526;
  }
  .trademarkCont .status .statusList__item .sm {
    font-size: 1rem;
  }
  .trademarkCont .status .statusList__item .md {
    font-size: 1.3rem;
  }
  .trademarkCont .status .statusList__item.no::after {
    font-size: 0.8rem;
  }
  .trademarkCont .status .note {
    line-height: 1.71428;
  }
  .trademarkCont .btn {
    background: transparent;
    padding: 0;
    margin: 30px 0;
  }
  .trademarkCont .btn a {
    font-size: 1.6rem;
    border-radius: 4px;
    box-shadow: 2px 2px 0px 2px #AA000C;
    padding: 20px 5px;
  }
  .trademarkCont .btn a::before {
    width: 18px;
    height: 18px;
    margin: 0 5px -3px 0;
  }
  .trademarkCont .other {
    padding: 15px 0 0;
    margin: 30px 0 0;
  }
  .trademarkCont .other::before {
    width: 100%;
    height: 2px;
    left: 0;
  }
  .trademarkCont .conditions__ttl {
    font-size: 1.6rem;
    line-height: 1.4375;
    margin: 0 0 15px;
  }
  .trademarkCont .conditions__ttl::before {
    width: 16px;
    height: 18px;
    margin: 0 7px -2px 0;
  }
  .trademarkCont .conditions__text {
    font-size: 1.4rem;
    line-height: 1.71428;
  }
  .trademarkCont .spec__ttl {
    font-size: 1.6rem;
    line-height: 1.4375;
    margin: 0 0 15px;
  }
  .trademarkCont .spec__ttl::before {
    width: 19px;
    height: 16px;
    margin: 0 6px -2px 0;
  }
  .trademarkCont .spec__table {
    display: block;
    white-space: normal;
    border-right: 1px solid #CCCCCC;
    border-bottom: 1px solid #CCCCCC;
  }
  .trademarkCont .spec__table:nth-child(2n) th {
    border-left: 1px solid #CCCCCC;
  }
  .trademarkCont .spec__table tr {
    display: flex;
    flex-wrap: wrap;
  }
  .trademarkCont .spec__table th, .trademarkCont .spec__table td {
    font-size: 1.3rem;
    padding: 20px 10px;
    border: none;
    border-top: 1px solid #CCCCCC;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .trademarkCont .spec__table th {
    font-weight: 500;
    background: #F5F6F9;
    width: 6rem;
  }
  .trademarkCont .spec__table td {
    width: calc((100% - 12rem) / 2);
  }
}
/*=================================
  ranking
=================================*/
.ranking {
  /* キービジュアル */
  /* メインコンテンツ */
}
.ranking .ttlWrap {
  background: #FFF7D4;
  padding: 50px 4% 0 0;
  position: relative;
}
.ranking .ttlWrap::before {
  content: "";
  width: 100%;
  height: 10px;
  display: block;
  border-top: 3px solid #004DA1;
  border-bottom: 3px solid #D70110;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.ranking .ttlWrap__inner {
  max-width: 1276px;
  margin: 0 auto;
  background: #fff;
  padding: 36px 4% 50px 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: row-reverse;
  position: relative;
}
.ranking .ttlWrap__inner::after {
  content: "";
  width: clamp(211px, 22.7672035139vw, 311px);
  height: clamp(162px, 19.1800878477vw, 262px);
  display: inline-block;
  background: url(../img/under/ranking_mv_deco.svg) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: -30px;
  right: -4%;
}
.ranking .ttlWrap .textWrap {
  position: relative;
  z-index: 1;
}
.ranking .ttlWrap__img {
  height: 100%;
  object-fit: contain;
  margin-right: 34px;
  margin-top: -86px;
}
.ranking .ttlWrap__ttl {
  font-size: clamp(1.5rem, 1.3909224012vw, 1.9rem);
  font-weight: 700;
  line-height: 1;
  border-top: 5px solid #004DA1;
  border-bottom: 5px solid #D70110;
  display: inline-block;
  padding: 8px;
  margin: 0 0 20px;
  position: relative;
}
.ranking .ttlWrap__ttl::before {
  content: "";
  width: 25px;
  height: 16px;
  display: inline-block;
  background: url(../img/common/icon/icon_crown_blue.svg) no-repeat center center #fff;
  background-size: contain;
  position: absolute;
  top: -16px;
  left: 2rem;
}
.ranking .ttlWrap__catch {
  font-size: clamp(2.2rem, 3.074670571vw, 4.2rem);
  font-weight: 700;
  line-height: 1.142857;
  margin: 0 0 20px;
}
.ranking .ttlWrap__text {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.25;
}
.rankingCont {
  /*---------------------
    記事タイトル
  ---------------------*/
  /*---------------------
    メインビジュアル
  ---------------------*/
  /*---------------------
    更新日
  ---------------------*/
  /*---------------------
    目次
  ---------------------*/
  /*---------------------
    関連記事
  ---------------------*/
  /*---------------------
    既存テーマスタイル
  ---------------------*/
  /* balloon状の見出し */
  /* トピックス状 */
  /* 商標誘導ボタン */
  /* 商標誘導カード */
  margin: 0 auto;
  max-width: calc(1276px + 4%);
  padding: 0 4%;
  background: #fff;
}
.rankingCont h1, .rankingCont h2, .rankingCont h3, .rankingCont h4, .rankingCont h5, .rankingCont h6 {
  clear: both;
}
.rankingCont p:not([class]) {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.75;
}
.rankingCont p:not([class]) + p {
  margin-top: 2rem;
}
.rankingCont h1:not([class]), .rankingCont h1.wp-block-heading {
  font-size: 3.2rem;
  font-weight: 700;
  line-height: 1.5;
  margin-top: 100px;
  margin-bottom: 40px;
}
.rankingCont h2:not([class]), .rankingCont h2.wp-block-heading {
  font-size: 2.5rem;
  font-weight: 700;
  color: #fff;
  background: #004DA1;
  line-height: 1.2;
  margin-top: 50px;
  margin-bottom: 30px;
  padding: 15px 30px;
}
.rankingCont h3:not([class]), .rankingCont h3.wp-block-heading {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.4;
  margin-top: 68px;
  margin-bottom: 30px;
  padding-top: 18px;
  border-top: 3px solid #004DA1;
}
.rankingCont h4:not([class]), .rankingCont h4.wp-block-heading {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.666666;
  margin-top: 50px;
  margin-bottom: 30px;
  padding: 12px 24px;
  background: #F5F6F9;
  position: relative;
}
.rankingCont h4:not([class])::before, .rankingCont h4.wp-block-heading::before {
  content: "";
  width: 7px;
  height: 100%;
  display: inline-block;
  border-left: 2px solid #004DA1;
  border-right: 2px solid #D70110;
  position: absolute;
  top: 0;
  left: 0;
}
.rankingCont h5:not([class]), .rankingCont h5.wp-block-heading {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.66666;
  margin-top: 50px;
  margin-bottom: 33px;
  padding-left: 30px;
  position: relative;
}
.rankingCont h5:not([class])::before, .rankingCont h5.wp-block-heading::before {
  content: "";
  width: 19px;
  height: 17px;
  display: inline-block;
  background: url(../img/common/ttl_line_4.svg) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 6px;
  left: 0;
}
.rankingCont h6:not([class]), .rankingCont h6.wp-block-heading {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.77777;
  color: #004DA1;
  margin-top: 40px;
  margin-bottom: 20px;
  padding-left: 20px;
  position: relative;
}
.rankingCont h6:not([class])::before, .rankingCont h6.wp-block-heading::before {
  content: "";
  width: 10px;
  height: 10px;
  display: inline-block;
  background: #004DA1;
  position: absolute;
  top: 12px;
  left: 0;
  transform: rotate(45deg);
}
.rankingCont blockquote {
  position: relative;
  padding: 50px 20px 20px;
  margin: 50px 0 30px;
  box-sizing: border-box;
  font-style: italic;
  color: #464646;
  border: 10px solid #F5F6F9;
}
.rankingCont blockquote::before {
  content: "“";
  font-size: 5rem;
  font-weight: 700;
  color: #004DA1;
  display: inline-block;
  font-style: normal;
  width: 50px;
  height: 50px;
  line-height: 1.3;
  text-align: center;
  background: #F5F6F9;
  border-radius: 50px;
  position: absolute;
  top: -25px;
  left: 15px;
}
.rankingCont blockquote h1 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.rankingCont blockquote h2 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.rankingCont blockquote h3 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.rankingCont blockquote h4 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.rankingCont blockquote h5 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.rankingCont blockquote h6 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.rankingCont blockquote p {
  font-style: normal;
}
.rankingCont blockquote cite {
  display: block;
  text-align: right;
  line-height: 1;
  color: #888888;
  font-size: 0.9em;
  margin: 10px 0 0;
}
.rankingCont .btnWrap {
  clear: both;
}
.rankingCont img:not([class]), .rankingCont.wp-block-heading {
  display: block;
  margin: 15px auto 20px;
}
.rankingCont img.alignleft {
  float: left;
  margin: 0 30px 30px 0;
}
.rankingCont img.alignright {
  float: right;
  margin: 0 0 30px 30px;
}
.rankingCont img.aligncenter, .rankingCont img.alignnone {
  margin: 0 auto;
  display: block;
}
.rankingCont ul:not([class]) {
  margin: 50px 30px;
  padding: 38px 27px;
  background: #FFF;
  border: 10px solid #F5F6F9;
  clear: both;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .rankingCont ul:not([class]) {
    padding: 2.7818448023vw 1.9765739385vw;
  }
}
.rankingCont ul:not([class]) li {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2;
  color: #004DA1;
  padding-left: 28px;
  position: relative;
}
.rankingCont ul:not([class]) li::before {
  content: "";
  width: 18px;
  height: 18px;
  background: url(../img/common/icon/icon_ul.svg) no-repeat center center;
  background-size: contain;
  display: inline-block;
  position: absolute;
  top: 6px;
  left: 0;
}
.rankingCont ul:not([class]) li + li {
  margin-top: 10px;
}
.rankingCont ul:not([class]) h1::before {
  content: none;
}
.rankingCont ul:not([class]) h2::before {
  content: none;
}
.rankingCont ul:not([class]) h3::before {
  content: none;
}
.rankingCont ul:not([class]) h4::before {
  content: none;
}
.rankingCont ul:not([class]) h5::before {
  content: none;
}
.rankingCont ul:not([class]) h6::before {
  content: none;
}
.rankingCont ol:not([class]) {
  margin: 50px 30px;
  padding: 38px 27px;
  background: #FFF;
  border: 10px solid #F5F6F9;
  counter-reset: listNum;
  clear: both;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .rankingCont ol:not([class]) {
    padding: 2.7818448023vw 1.9765739385vw;
  }
}
.rankingCont ol:not([class]) li {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2;
  padding-left: 31px;
  position: relative;
}
.rankingCont ol:not([class]) li::before {
  counter-increment: listNum;
  content: counter(listNum);
  font-size: 1.2rem;
  font-weight: 400;
  color: #fff;
  background: #004DA1;
  width: 24px;
  line-height: 24px;
  border-radius: 50px;
  text-align: center;
  display: inline-block;
  position: absolute;
  top: 4px;
  left: 0;
}
.rankingCont ol:not([class]) li + li {
  margin-top: 10px;
}
.rankingCont ol:not([class]) h1::before {
  content: none;
}
.rankingCont ol:not([class]) h2::before {
  content: none;
}
.rankingCont ol:not([class]) h3::before {
  content: none;
}
.rankingCont ol:not([class]) h4::before {
  content: none;
}
.rankingCont ol:not([class]) h5::before {
  content: none;
}
.rankingCont ol:not([class]) h6::before {
  content: none;
}
@media screen and (min-width: 0) and (max-width: 767px) {
  .rankingCont p:not([class]) {
    font-size: 1.5rem;
    line-height: 1.71428;
  }
  .rankingCont h1:not([class]), .rankingCont h1.wp-block-heading {
    font-size: 2.4rem;
    list-style: 1.44444;
    margin-top: 54px;
    margin-bottom: 20px;
  }
  .rankingCont h2:not([class]), .rankingCont h2.wp-block-heading {
    font-size: 1.8rem;
    line-height: 1.55555;
    margin-top: 54px;
    margin-bottom: 20px;
    margin-left: calc(-4.358974359% - 1px);
    padding: 15px 4.358974359%;
    width: calc(108.7179487179% + 2px);
  }
  .rankingCont h3:not([class]), .rankingCont h3.wp-block-heading {
    font-size: 1.6rem;
    line-height: 1.5;
    margin-top: 40px;
    margin-bottom: 20px;
    padding-top: 15px;
  }
  .rankingCont h4:not([class]), .rankingCont h4.wp-block-heading {
    font-size: 1.4rem;
    line-height: 1.625;
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 11px 4.1025641026%;
  }
  .rankingCont h5:not([class]), .rankingCont h5.wp-block-heading {
    font-size: 1.5rem;
    margin-top: 30px;
    margin-bottom: 20px;
    padding-left: 22px;
  }
  .rankingCont h5:not([class])::before, .rankingCont h5.wp-block-heading::before {
    width: 15px;
    height: 14px;
    top: 5px;
  }
  .rankingCont h6:not([class]), .rankingCont h6.wp-block-heading {
    font-size: 1.5rem;
    line-height: 1.73333;
    padding-left: 15px;
  }
  .rankingCont h6:not([class])::before, .rankingCont h6.wp-block-heading::before {
    width: 8px;
    height: 8px;
    top: 10px;
  }
  .rankingCont blockquote {
    padding: 40px 3.8461538462% 20px;
    margin: 40px 0 30px;
    border: 7px solid #F5F6F9;
  }
  .rankingCont blockquote::before {
    font-size: 4rem;
    width: 45px;
    height: 45px;
    line-height: 1.5;
  }
  .rankingCont blockquote h1 {
    font-size: 1.5rem;
  }
  .rankingCont blockquote h2 {
    font-size: 1.5rem;
  }
  .rankingCont blockquote h3 {
    font-size: 1.5rem;
  }
  .rankingCont blockquote h4 {
    font-size: 1.5rem;
  }
  .rankingCont blockquote h5 {
    font-size: 1.5rem;
  }
  .rankingCont blockquote h6 {
    font-size: 1.5rem;
  }
  .rankingCont img.alignleft {
    margin: 0 7px 7px 0;
  }
  .rankingCont img.alignright {
    margin: 0 0 7px 7px;
  }
  .rankingCont ul:not([class]) {
    margin: 20px auto 40px;
    padding: 17px 5.1282051282%;
    border: 7px solid #F5F6F9;
  }
  .rankingCont ul:not([class]) li {
    padding-left: 28px;
  }
  .rankingCont ul:not([class]) li::before {
    width: 20px;
    height: 20px;
    top: 5px;
  }
  .rankingCont ol:not([class]) {
    margin: 20px auto 40px;
    padding: 17px 5.1282051282%;
    border: 7px solid #F5F6F9;
  }
  .rankingCont ol:not([class]) li::before {
    font-size: 1rem;
    width: 22px;
    line-height: 22px;
  }
}
.rankingCont iframe {
  width: 100%;
}
.rankingCont .articleTtl {
  font-size: 3.2rem;
  list-style: 1.5;
  margin: 0 0 8px;
}
.rankingCont .articleMv {
  margin: 15px auto;
  text-align: center;
  overflow: hidden;
}
.rankingCont .articleMv img {
  margin: 0 auto;
}
.rankingCont .date {
  text-align: right;
}
.rankingCont .date > * {
  font-size: 1.2rem !important;
  font-weight: 400;
  color: #333333;
  display: inline-block;
  line-height: 1 !important;
  margin: 0;
}
.rankingCont .date > * + * {
  margin: 0 0 0 1rem !important;
}
.rankingCont .tocBox {
  margin: 40px auto 100px;
  padding: 23px;
  background: #F5F6F9;
}
.rankingCont .tocBox__inner {
  background: #fff;
  padding: 0 20px 20px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
}
.rankingCont .tocBox__ttl {
  font-size: 1.8rem;
  line-height: 1;
  margin: 0 0 15px;
  text-align: center;
}
.rankingCont .tocBox__ttl::before {
  content: "";
  width: 20px;
  height: 16px;
  display: inline-block;
  background: url(../img/common/icon/icon_book.svg) no-repeat center center;
  background-size: contain;
  margin: 0 9px -2px 0;
}
.rankingCont .tocBox .tocList {
  counter-reset: tocNum;
}
.rankingCont .tocBox .tocList__item:not(:last-child) {
  border-bottom: 1px solid #D2DAF0;
}
.rankingCont .tocBox .tocList__item a {
  font-size: 1.6rem;
  font-weight: 400;
  color: #004DA1;
  display: block;
  padding: 12px 24px;
  text-decoration: none;
  position: relative;
}
.rankingCont .tocBox .tocList__item a::before {
  counter-increment: tocNum;
  content: counter(tocNum) ".";
  font-size: 1.6rem;
  font-weight: 400;
  color: #004DA1;
  display: inline-block;
  position: absolute;
  top: 12px;
  left: 0;
}
.rankingCont .tocBox .tocList__item a::after {
  content: "";
  border-style: solid;
  border-width: 0 0 12px 12px;
  border-color: transparent transparent #004DA1 transparent;
  display: inline-block;
  position: absolute;
  bottom: 12px;
  right: 0;
  transition-property: all;
  transition-duration: 0.1s;
  transition-timing-function: ease-out;
}
@media screen and (min-width: 767px) {
  .rankingCont .tocBox .tocList__item a:hover {
    text-decoration: underline;
    color: #304A85;
  }
  .rankingCont .tocBox .tocList__item a:hover::after {
    bottom: 6px;
  }
}
.rankingCont .tocBox .tocList__item .tocList {
  counter-reset: tocChildNum;
  padding-left: 26px;
}
.rankingCont .tocBox .tocList__item .tocList__item a {
  padding-left: 46px;
  display: block;
}
.rankingCont .tocBox .tocList__item .tocList__item a::before {
  counter-increment: tocChildNum;
  content: counter(tocNum) " - " counter(tocChildNum) ".";
}
.rankingCont .tocBox .tocMore {
  font-size: 1.5rem;
  font-weight: 500;
  background: #FECB3F;
  text-align: center;
  margin-top: 20px;
  padding: 8px 20px;
  display: inline-block;
  text-align: center;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}
@media screen and (min-width: 767px) {
  .rankingCont .tocBox .tocMore:hover {
    color: #fff;
    background: #004DA1;
  }
}
.rankingCont .tocBox:not(.open) .tocBox__inner > .tocList > .tocList__item:nth-child(n+4) {
  display: none;
}
.rankingCont .tocBox:not(.open) .tocMore::after {
  transform: rotate(0deg);
}
@media screen and (min-width: 767px) {
  .rankingCont .tocBox:not(.open) .tocMore:hover::after {
    transform: translateY(2px) rotate(90deg);
  }
}
.rankingCont .related {
  margin: 60px auto 50px;
  position: relative;
}
.rankingCont .related__ttl {
  position: absolute;
  top: -24px;
  right: 4px;
  z-index: 1;
}
.rankingCont .related .relatedList__item {
  padding: 16px 24px 15px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
}
.rankingCont .related .relatedList__item::after {
  border-width: 0 0 14px 14px;
  bottom: 14px;
  right: 12px;
}
.rankingCont .related .relatedList__item .itemWrap .thumb {
  width: 80px;
}
.rankingCont .related .relatedList__item .itemWrap .thumb img {
  margin: 0;
}
.rankingCont .related .relatedList__item .itemWrap .thumb + .textWrap {
  width: calc(100% - 102px);
  margin-left: 22px;
}
.rankingCont .related .relatedList__item .itemWrap .textWrap {
  padding: 15px 0;
}
.rankingCont .related .relatedList__item .itemWrap .textWrap__ttl {
  font-size: 1.8rem;
  line-height: 1.38888;
}
.rankingCont .related .relatedList__item + .relatedList__item {
  margin-top: 40px;
}
.rankingCont .point {
  font-size: 1.5rem;
  font-weight: 700;
  color: #fff;
  display: inline-block;
  padding: 4px 16px 7px;
  background: #D70110;
  box-shadow: 3px 4px 0px 0px #AA000C;
  position: relative;
  transform: translateY(20px);
}
.rankingCont .point::before {
  content: "";
  width: 4px;
  height: calc(100% + 4px);
  background: #AA000C;
  display: inline-block;
  position: absolute;
  top: 0;
  right: -4px;
}
.rankingCont .point::after {
  content: "";
  border-style: solid;
  border-width: 11px 5px 0 5px;
  border-color: #D70110 transparent transparent transparent;
  display: inline-block;
  filter: drop-shadow(3px 4px 0px #AA000C);
  position: absolute;
  bottom: -11px;
  left: 50%;
  transform: translateX(-50%);
}
.rankingCont .point .icon {
  display: inline-block;
  vertical-align: middle;
  margin: 0 15px 0 0;
}
.rankingCont .point + ul, .rankingCont .point ol {
  margin-top: 0;
  border-color: #FFF1B4;
}
.rankingCont .point + ul li::before {
  background: url(../img/common/icon/icon_ul_yellow.svg) no-repeat center center;
  background-size: contain;
}
.rankingCont .note {
  background: #F5F6F9;
  margin: 50px 40px;
  padding: 50px 40px 40px;
  position: relative;
}
.rankingCont .note::before {
  content: "";
  width: 100%;
  height: 7px;
  display: block;
  border-top: 2px solid #004DA1;
  border-bottom: 2px solid #D70110;
  position: absolute;
  top: 0;
  left: 0;
}
.rankingCont .note h1 {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.66666;
  color: #004DA1;
  background: none;
  margin: 0 0 20px !important;
  padding: 0 0 0 43px;
  border: none;
  position: relative;
}
.rankingCont .note h1::before {
  content: "";
  width: 31px;
  height: 44px;
  display: inline-block;
  background: url(../img/common/ttl_topic.svg) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: -8px;
  left: 0;
  border: none;
  margin: 0;
  padding: 0;
  transform: none;
}
.rankingCont .note h1::after {
  content: none;
}
.rankingCont .note h2 {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.66666;
  color: #004DA1;
  background: none;
  margin: 0 0 20px !important;
  padding: 0 0 0 43px;
  border: none;
  position: relative;
}
.rankingCont .note h2::before {
  content: "";
  width: 31px;
  height: 44px;
  display: inline-block;
  background: url(../img/common/ttl_topic.svg) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: -8px;
  left: 0;
  border: none;
  margin: 0;
  padding: 0;
  transform: none;
}
.rankingCont .note h2::after {
  content: none;
}
.rankingCont .note h3 {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.66666;
  color: #004DA1;
  background: none;
  margin: 0 0 20px !important;
  padding: 0 0 0 43px;
  border: none;
  position: relative;
}
.rankingCont .note h3::before {
  content: "";
  width: 31px;
  height: 44px;
  display: inline-block;
  background: url(../img/common/ttl_topic.svg) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: -8px;
  left: 0;
  border: none;
  margin: 0;
  padding: 0;
  transform: none;
}
.rankingCont .note h3::after {
  content: none;
}
.rankingCont .note h4 {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.66666;
  color: #004DA1;
  background: none;
  margin: 0 0 20px !important;
  padding: 0 0 0 43px;
  border: none;
  position: relative;
}
.rankingCont .note h4::before {
  content: "";
  width: 31px;
  height: 44px;
  display: inline-block;
  background: url(../img/common/ttl_topic.svg) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: -8px;
  left: 0;
  border: none;
  margin: 0;
  padding: 0;
  transform: none;
}
.rankingCont .note h4::after {
  content: none;
}
.rankingCont .note h5 {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.66666;
  color: #004DA1;
  background: none;
  margin: 0 0 20px !important;
  padding: 0 0 0 43px;
  border: none;
  position: relative;
}
.rankingCont .note h5::before {
  content: "";
  width: 31px;
  height: 44px;
  display: inline-block;
  background: url(../img/common/ttl_topic.svg) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: -8px;
  left: 0;
  border: none;
  margin: 0;
  padding: 0;
  transform: none;
}
.rankingCont .note h5::after {
  content: none;
}
.rankingCont .note h6 {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.66666;
  color: #004DA1;
  background: none;
  margin: 0 0 20px !important;
  padding: 0 0 0 43px;
  border: none;
  position: relative;
}
.rankingCont .note h6::before {
  content: "";
  width: 31px;
  height: 44px;
  display: inline-block;
  background: url(../img/common/ttl_topic.svg) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: -8px;
  left: 0;
  border: none;
  margin: 0;
  padding: 0;
  transform: none;
}
.rankingCont .note h6::after {
  content: none;
}
.rankingCont .note p {
  background: #fff;
  margin: 0 !important;
  padding: 20px;
}
.rankingCont .note p + p {
  padding-top: 0;
}
.rankingCont .btn {
  background: #F5F6F9;
  padding: 30px;
  margin: 50px auto;
}
.rankingCont .btn a {
  font-size: 2.8rem;
  font-weight: 500;
  color: #fff;
  background: rgb(215, 1, 16);
  background: -moz-linear-gradient(180deg, rgb(215, 1, 16) 0%, rgb(185, 5, 18) 100%);
  background: -webkit-linear-gradient(180deg, rgb(215, 1, 16) 0%, rgb(185, 5, 18) 100%);
  background: linear-gradient(180deg, rgb(215, 1, 16) 0%, rgb(185, 5, 18) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d70110",endColorstr="#b90512",GradientType=1);
  border-radius: 5px;
  box-shadow: 4px 4px 0px 4px #AA000C;
  padding: 30px 5px;
  text-align: center;
  text-decoration: none;
  display: block;
}
.rankingCont .btn a::before {
  content: "";
  width: 26px;
  height: 26px;
  display: inline-block;
  background: url(../img/common/icon/icon_arrow_right.svg) no-repeat center center;
  background-size: contain;
  margin: 0 10px -2px 0;
}
@media screen and (min-width: 767px) {
  .rankingCont .btn a {
    transition-property: all;
    transition-duration: 0s;
    transition-timing-function: ease-out;
  }
  .rankingCont .btn a:hover {
    box-shadow: none;
    transform: translate(8px, 8px);
    opacity: 0.9;
  }
}
.rankingCont .intr {
  margin: 34px 0 70px;
  position: relative;
}
.rankingCont .intr__ttl {
  position: absolute;
  top: -33px;
  right: 4px;
}
.rankingCont .intrBox {
  border: 10px solid #E6E6E6;
  padding: 20px;
}
.rankingCont .intrBox__link {
  font-size: 2.2rem;
  color: #004DA1;
  background: #F5F6F9;
  padding: 13px 5px;
  margin: 0 0 20px;
  display: block;
  text-align: center;
  text-decoration: none;
}
@media screen and (min-width: 767px) {
  .rankingCont .intrBox__link:hover {
    text-decoration: underline;
  }
}
.rankingCont .intrBox__cont {
  display: flex;
  align-items: flex-start;
}
.rankingCont .intrBox__cont .thumb {
  width: clamp(150px, 19.0336749634vw, 260px);
  margin-right: 30px;
}
.rankingCont .intrBox__cont .thumb + .spec {
  width: calc(100% - clamp(150px, 19.0336749634vw, 260px) - 30px);
}
.rankingCont .intrBox__cont .spec__text {
  font-size: 1.5rem;
}
.rankingCont .intrBox__cont .spec__table {
  margin: 16px 0;
}
.rankingCont .intrBox__cont .spec__table th {
  font-weight: 500;
}
.rankingCont .intrBox__cont .spec__table td {
  font-weight: 700;
  background: #fff;
  text-align: center;
}
.rankingCont .intrBox__cont .spec__note {
  font-size: 1rem;
  margin: 5px 0 0;
  text-align: right;
}
.rankingCont .intrBox__cont .spec__btn {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.5;
  color: #fff;
  text-decoration: none;
  display: block;
  background: rgb(215, 1, 16);
  background: -moz-linear-gradient(180deg, rgb(215, 1, 16) 0%, rgb(185, 5, 18) 100%);
  background: -webkit-linear-gradient(180deg, rgb(215, 1, 16) 0%, rgb(185, 5, 18) 100%);
  background: linear-gradient(180deg, rgb(215, 1, 16) 0%, rgb(185, 5, 18) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d70110",endColorstr="#b90512",GradientType=1);
  border-radius: 4px;
  box-shadow: 2px 2px 0px 2px #AA000C;
  padding: 13px 5px 11px;
  margin: 12px 0 0;
  text-align: center;
  position: relative;
}
.rankingCont .intrBox__cont .spec__btn::before {
  content: "";
  width: 15px;
  height: 15px;
  display: inline-block;
  background: url(../img/common/icon/icon_arrow_right.svg) no-repeat center center;
  background-size: contain;
  margin: 0 10px -2px 0;
}
@media screen and (min-width: 767px) {
  .rankingCont .intrBox__cont .spec__btn {
    transition-property: all;
    transition-duration: 0s;
    transition-timing-function: ease-out;
  }
  .rankingCont .intrBox__cont .spec__btn:hover {
    box-shadow: none;
    transform: translate(2px, 2px);
    opacity: 0.9;
  }
}
@media screen and (min-width: 0) and (max-width: 767px) {
  .rankingCont {
    /*---------------------
      記事タイトル
    ---------------------*/
    /*---------------------
      メインビジュアル
    ---------------------*/
    /*---------------------
      更新日
    ---------------------*/
    /*---------------------
      目次
    ---------------------*/
    /*---------------------
      関連記事
    ---------------------*/
    /*---------------------
      既存テーマスタイル
    ---------------------*/
    /* balloon状の見出し */
    /* トピックス状 */
    /* 商標誘導ボタン */
    /* 商標誘導カード */
  }
  .rankingCont .articleTtl {
    font-size: 2.4rem;
    list-style: 1.33333;
  }
  .rankingCont .articleMv {
    padding: 0 2.0512820513%;
  }
  .rankingCont .date {
    text-align: left;
  }
  .rankingCont .tocBox {
    margin: 50px auto;
    padding: 17px 4.358974359%;
    width: calc(108.7179487179% + 2px);
    margin-left: calc(-4.358974359% - 1px);
  }
  .rankingCont .tocBox__inner {
    padding: 10px 3.8461538462% 26px;
    border: 1px solid #D2DAF0;
  }
  .rankingCont .tocBox__inner > .tocList > .tocList__item:last-of-type {
    border-bottom: 1px solid #D2DAF0;
  }
  .rankingCont .tocBox__ttl {
    margin: 0 0 10px;
  }
  .rankingCont .tocBox__ttl::before {
    width: 18px;
    height: 14px;
    margin: 0 7px -2px 0;
  }
  .rankingCont .tocBox .tocList__item a {
    font-size: 1.4rem;
    padding: 11px 1rem 11px 2rem;
  }
  .rankingCont .tocBox .tocList__item a::before {
    font-size: 1.4rem;
    top: 11px;
  }
  .rankingCont .tocBox .tocList__item a::after {
    border-width: 0 0 9px 9px;
    bottom: 9px;
  }
  .rankingCont .tocBox .tocList__item .tocList {
    padding-left: 2rem;
  }
  .rankingCont .tocBox .tocList__item .tocList__item a {
    padding-left: 4rem;
  }
  .rankingCont .tocBox .tocMore {
    font-size: 1.3rem;
    margin-top: 15px;
    padding: 5px 15px;
  }
  .rankingCont .related {
    margin: 30px auto;
  }
  .rankingCont .related__ttl {
    position: relative;
    top: -8px;
    left: 50%;
    right: initial;
    transform: translateX(-50%);
  }
  .rankingCont .related .relatedList__item {
    padding: 10px 6.6666666667% 10px 2.5641025641%;
  }
  .rankingCont .related .relatedList__item::after {
    border-width: 0 0 10px 10px;
    bottom: 10px;
    right: 11px;
  }
  .rankingCont .related .relatedList__item .itemWrap .thumb + .textWrap {
    width: calc(100% - 90px);
    margin-left: 10px;
  }
  .rankingCont .related .relatedList__item .itemWrap .textWrap {
    padding: 0;
  }
  .rankingCont .related .relatedList__item .itemWrap .textWrap__ttl {
    font-size: 1.4rem;
    line-height: 1.4285;
  }
  .rankingCont .related .relatedList__item + .relatedList__item {
    margin-top: 17px;
  }
  .rankingCont .point {
    font-size: 1.4rem;
    padding: 10px 20px 11px;
    transform: translateY(0);
  }
  .rankingCont .point .icon {
    margin: 0 12px 0 0;
  }
  .rankingCont .note {
    margin: 30px 0 40px;
    padding: 25px 5.8974358974% 25px;
  }
  .rankingCont .note h1 {
    font-size: 1.5rem;
    margin: 0 0 9px !important;
    padding: 0 0 0 31px;
  }
  .rankingCont .note h1::before {
    width: 24px;
    height: 33px;
  }
  .rankingCont .note h2 {
    font-size: 1.5rem;
    margin: 0 0 9px !important;
    padding: 0 0 0 31px;
  }
  .rankingCont .note h2::before {
    width: 24px;
    height: 33px;
  }
  .rankingCont .note h3 {
    font-size: 1.5rem;
    margin: 0 0 9px !important;
    padding: 0 0 0 31px;
  }
  .rankingCont .note h3::before {
    width: 24px;
    height: 33px;
  }
  .rankingCont .note h4 {
    font-size: 1.5rem;
    margin: 0 0 9px !important;
    padding: 0 0 0 31px;
  }
  .rankingCont .note h4::before {
    width: 24px;
    height: 33px;
  }
  .rankingCont .note h5 {
    font-size: 1.5rem;
    margin: 0 0 9px !important;
    padding: 0 0 0 31px;
  }
  .rankingCont .note h5::before {
    width: 24px;
    height: 33px;
  }
  .rankingCont .note h6 {
    font-size: 1.5rem;
    margin: 0 0 9px !important;
    padding: 0 0 0 31px;
  }
  .rankingCont .note h6::before {
    width: 24px;
    height: 33px;
  }
  .rankingCont .note p {
    padding: 18px 4.6153846154%;
  }
  .rankingCont .btn {
    padding: 8px;
    margin: 37px auto;
  }
  .rankingCont .btn a {
    font-size: 1.8rem;
    border-radius: 4px;
    box-shadow: 2px 2px 0px 2px #AA000C;
    padding: 20px 4.358974359%;
  }
  .rankingCont .btn a::before {
    width: 16px;
    height: 16px;
    margin: 0 5px -2px 0;
  }
  .rankingCont .intr {
    margin: 32px 0 40px;
  }
  .rankingCont .intr__ttl {
    font-size: 1.4rem;
    line-height: 3.2;
    position: relative;
    top: -12px;
    left: 50%;
    right: initial;
    transform: translateX(-50%);
  }
  .rankingCont .intrBox {
    border: 7px solid #E6E6E6;
    padding: 10px 10px 20px;
  }
  .rankingCont .intrBox__link {
    font-size: 1.6rem;
    padding: 10px;
  }
  .rankingCont .intrBox__cont {
    display: block;
  }
  .rankingCont .intrBox__cont .thumb {
    width: 97.4358974359%;
    margin: 0 auto 20px;
  }
  .rankingCont .intrBox__cont .thumb + .spec {
    width: 100%;
  }
  .rankingCont .intrBox__cont .spec__text {
    font-size: 1.6rem;
    text-align: center;
  }
  .rankingCont .intrBox__cont .spec__table {
    margin: 10px 0 15px;
    display: table;
    white-space: normal;
  }
  .rankingCont .intrBox__cont .spec__table th {
    font-size: 1.2rem;
    padding: 8px;
  }
  .rankingCont .intrBox__cont .spec__table td {
    font-size: 1.4rem;
    padding: 8px;
  }
  .rankingCont .intrBox__cont .spec__note {
    font-size: 1.2rem;
    margin: 15px 0 0;
    text-align: left;
  }
  .rankingCont .intrBox__cont .spec__btn {
    padding: 17px 5px 17px;
    margin: 20px 0 0;
  }
}
.rankingCont__inner {
  background: #fff;
  padding: 0 0 30px;
  max-width: 1140px;
  margin: 0 auto;
}
.rankingCont .box--col2 {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  border: 10px solid #EDEEF1;
  padding: 28px 33px 36px;
  margin: 43px 0 70px;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .rankingCont .box--col2 {
    flex-wrap: wrap;
    justify-content: center;
  }
}
.rankingCont .box--col2__right {
  margin-left: 5.26%;
  width: 39.37%;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .rankingCont .box--col2__right {
    margin-left: 3%;
    width: 48.5%;
    min-width: 400px;
  }
}
.rankingCont .box--col2__left {
  width: 55.37%;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .rankingCont .box--col2__left {
    width: 48.5%;
    min-width: 400px;
  }
}
.rankingCont .box--col2__ttl {
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1.5;
  margin: 0 0 28px;
  padding: 0 0 0 36px;
  position: relative;
}
.rankingCont .box--col2__ttl .sm {
  font-size: 1.4rem;
  font-weight: 500;
  display: inline-block;
  margin: 0 0 0 1rem;
}
.rankingCont .box--col2__ttl::before {
  content: "";
  width: 26px;
  height: 23px;
  display: inline-block;
  background: url(../img/common/ttl_line_4.svg) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 8px;
  left: 0;
}
.rankingCont .box--col2__img {
  display: block;
  text-align: center;
}
.rankingCont .box--yellow {
  background: #FFF7D4;
  border-radius: 30px;
  padding: 23px 8.4615384615% 34px;
}
.rankingCont .box--yellow__ttl {
  font-size: 1.8rem;
  font-weight: 700;
  margin: 0 0 19px;
}
.rankingCont .box--yellow__ttl .icon {
  margin: 0 6px 2px 0;
}
.rankingCont .box--yellow__list li {
  padding: 0 0 0 2rem;
  position: relative;
}
.rankingCont .box--yellow__list li::before {
  content: "・";
  position: absolute;
  top: 0;
  left: 0;
}

.rankingBox {
  padding: 50px 4% 85px;
  background: #F5F6F9;
}
.rankingBox__inner {
  max-width: 1140px;
  margin: 0 auto;
}
.rankingBox__ttl {
  font-size: 4rem;
  font-weight: 700;
  line-height: 1.212121;
  text-align: center;
}
.rankingBox .rankingList {
  counter-reset: rankingNum;
}
.rankingBox .rankingList__ttl {
  font-size: 3.6rem;
  font-weight: 700;
  line-height: 1.2;
  color: #fff;
  text-decoration: none;
  background: #004DA1;
  padding: 19px 23px 15px;
}
.rankingBox .rankingList__ttl a {
  font-size: 3.6rem;
  font-weight: 700;
  line-height: 1.2;
  color: #fff;
  text-decoration: none;
}
.rankingBox .rankingList__ttl::before {
  counter-increment: rankingNum;
  content: counter(rankingNum);
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 2.5;
  color: #004DA1;
  background: url(../img/common/icon/icon_crown_ranking.svg) no-repeat center center;
  background-size: contain;
  width: 53px;
  height: 55px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  margin: 0 16px 11px 0;
}
.rankingBox .rankingList__catch {
  font-size: 3.6rem;
  font-weight: 700;
  line-height: 1.33333;
  color: #D70110;
  margin: 0 0 30px;
  text-align: center;
}
.rankingBox .rankingList__item {
  background: #fff;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.06);
  margin: 50px 0 0;
}
.rankingBox .rankingList__item:nth-child(1) .rankingList__ttl {
  background: rgb(174, 140, 56);
  background: -moz-linear-gradient(90deg, rgb(174, 140, 56) 0%, rgb(206, 172, 91) 100%);
  background: -webkit-linear-gradient(90deg, rgb(174, 140, 56) 0%, rgb(206, 172, 91) 100%);
  background: linear-gradient(90deg, rgb(174, 140, 56) 0%, rgb(206, 172, 91) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ae8c38",endColorstr="#ceac5b",GradientType=1);
}
.rankingBox .rankingList__item:nth-child(1) .rankingList__ttl::before {
  color: #B08E3A;
}
.rankingBox .rankingList__item:nth-child(2) .rankingList__ttl {
  background: rgb(180, 180, 180);
  background: -moz-linear-gradient(90deg, rgb(180, 180, 180) 0%, rgb(200, 198, 198) 100%);
  background: -webkit-linear-gradient(90deg, rgb(180, 180, 180) 0%, rgb(200, 198, 198) 100%);
  background: linear-gradient(90deg, rgb(180, 180, 180) 0%, rgb(200, 198, 198) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#b4b4b4",endColorstr="#c8c6c6",GradientType=1);
}
.rankingBox .rankingList__item:nth-child(2) .rankingList__ttl::before {
  color: #B5B5B5;
}
.rankingBox .rankingList__item:nth-child(3) .rankingList__ttl {
  background: rgb(101, 74, 42);
  background: -moz-linear-gradient(90deg, rgb(101, 74, 42) 0%, rgb(146, 125, 76) 100%);
  background: -webkit-linear-gradient(90deg, rgb(101, 74, 42) 0%, rgb(146, 125, 76) 100%);
  background: linear-gradient(90deg, rgb(101, 74, 42) 0%, rgb(146, 125, 76) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#654a2a",endColorstr="#927d4c",GradientType=1);
}
.rankingBox .rankingList__item:nth-child(3) .rankingList__ttl::before {
  color: #684E2C;
}
.rankingBox .rankingList__item .container {
  padding: 30px 6% 64px;
}
.rankingBox .rankingList__item .headWrap {
  display: flex;
  align-items: flex-start;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .rankingBox .rankingList__item .headWrap .thumb {
    width: 20.4978038067vw;
  }
}
.rankingBox .rankingList__item .headWrap .thumb + .pointList {
  width: calc(100% - 308px);
  margin-left: 28px;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .rankingBox .rankingList__item .headWrap .thumb + .pointList {
    width: calc(100% - 22.5475841874vw);
    margin-left: 2.0497803807vw;
  }
}
.rankingBox .rankingList__item .headWrap .pointList {
  padding: 25px 22px;
  background: #FFF;
  border: 10px solid #FFF1B4;
  clear: both;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .rankingBox .rankingList__item .headWrap .pointList {
    padding: 1.8301610542vw 1.6105417277vw;
  }
}
.rankingBox .rankingList__item .headWrap .pointList__item {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2;
  padding: 3px 12px 3px 40px;
  border-bottom: 1px dashed #CCCCCC;
  position: relative;
}
.rankingBox .rankingList__item .headWrap .pointList__item::before {
  content: "";
  width: 22px;
  height: 22px;
  background: url(../img/common/icon/icon_ul_yellow.svg) no-repeat center center;
  background-size: contain;
  display: inline-block;
  position: absolute;
  top: 16px;
  left: 3px;
}
.rankingBox .rankingList__item .status {
  margin: 25px 0 40px;
}
.rankingBox .rankingList__item .status .statusList {
  margin: 0 0 10px -7px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.rankingBox .rankingList__item .status .statusList__item {
  background: #004DA1;
  border: 3px solid #004DA1;
  border-radius: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  margin: 0 6px 7px 7px;
  width: 88px;
  height: 88px;
  position: relative;
}
.rankingBox .rankingList__item .status .statusList__item .wrap {
  font-size: 3rem;
  font-weight: 500;
  line-height: 1.133333;
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.rankingBox .rankingList__item .status .statusList__item .sm {
  font-size: 1.6rem;
  display: block;
}
.rankingBox .rankingList__item .status .statusList__item .md {
  font-size: 2.1rem;
  display: block;
}
.rankingBox .rankingList__item .status .statusList__item.no {
  border-color: #CCCCCC;
  background: #fff;
}
.rankingBox .rankingList__item .status .statusList__item.no::after {
  content: "NO";
  font-size: 1.2rem;
  line-height: 1;
  color: #fff;
  padding: 2px 9px;
  background: #D70110;
  display: inline-block;
  position: absolute;
  right: -3px;
  bottom: -3px;
}
.rankingBox .rankingList__item .status .statusList__item.no .wrap {
  color: #CCCCCC;
}
.rankingBox .rankingList__item .status .note {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2;
}
.rankingBox .rankingList__item .conditions {
  margin: 40px 0 0;
}
.rankingBox .rankingList__item .conditions__ttl {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.4375;
  padding: 15px 12px;
  border-top: 2px solid #004DA1;
}
.rankingBox .rankingList__item .conditions__ttl::before {
  content: "";
  width: 16px;
  height: 18px;
  display: inline-block;
  background: url(../img/common/icon/icon_user.svg) no-repeat center center;
  background-size: contain;
  margin: 0 8px -2px 0;
}
.rankingBox .rankingList__item .conditions__text {
  font-size: 1.6rem;
  line-height: 1.75;
}
.rankingBox .rankingList__item .conditions__text .note {
  font-size: 1.2rem;
  display: block;
}
.rankingBox .rankingList__item .spec {
  margin: 40px 0 0;
}
.rankingBox .rankingList__item .spec__ttl {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.4375;
  padding: 15px 12px;
  border-top: 2px solid #D70110;
}
.rankingBox .rankingList__item .spec__ttl::before {
  content: "";
  width: 20px;
  height: 18px;
  display: inline-block;
  background: url(../img/common/icon/icon_table.svg) no-repeat center center;
  background-size: contain;
  margin: 0 8px -2px 0;
}
.rankingBox .rankingList__item .spec__table {
  margin: 0;
}
.rankingBox .rankingList__item .spec__table th, .rankingBox .rankingList__item .spec__table td {
  text-align: center;
  border-color: #CCCCCC;
}
.rankingBox .rankingList__item .spec__table th {
  font-weight: 500;
}
.rankingBox .rankingList__item .spec__table td {
  background: #fff;
}
.rankingBox .rankingList__item .btn--col2 {
  display: flex;
  justify-content: center;
}
.rankingBox .rankingList__item .btn--col2 .btn {
  width: 48%;
}
.rankingBox .rankingList__item .btn--col2 .btn:nth-child(2n) {
  margin-left: 4%;
}
.rankingBox .rankingList__item .btn {
  background: #F5F6F9;
  padding: 10px;
  margin: 40px 0 0;
}
.rankingBox .rankingList__item .btn a {
  font-size: 2.2rem;
  font-weight: 500;
  color: #fff;
  background: rgb(215, 1, 16);
  background: -moz-linear-gradient(180deg, rgb(215, 1, 16) 0%, rgb(185, 5, 18) 100%);
  background: -webkit-linear-gradient(180deg, rgb(215, 1, 16) 0%, rgb(185, 5, 18) 100%);
  background: linear-gradient(180deg, rgb(215, 1, 16) 0%, rgb(185, 5, 18) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d70110",endColorstr="#b90512",GradientType=1);
  border-radius: 5px;
  box-shadow: 3px 3px 0px 3px #AA000C;
  padding: 30px 5px;
  text-align: center;
  text-decoration: none;
  display: block;
}
.rankingBox .rankingList__item .btn a::before {
  content: "";
  width: 25px;
  height: 25px;
  display: inline-block;
  background: url(../img/common/icon/icon_arrow_right.svg) no-repeat center center;
  background-size: contain;
  margin: 0 12px -4px 0;
}
@media screen and (min-width: 767px) {
  .rankingBox .rankingList__item .btn a {
    transition-property: all;
    transition-duration: 0s;
    transition-timing-function: ease-out;
  }
  .rankingBox .rankingList__item .btn a:hover {
    box-shadow: none;
    transform: translate(6px, 6px);
    opacity: 0.9;
  }
}

@media screen and (max-width: 767px) {
  /*=================================
    ranking
  =================================*/
  .ranking {
    /* キービジュアル */
    /* メインコンテンツ */
  }
  .ranking .ttlWrap {
    padding: 30px 4.358974359% 0 0;
  }
  .ranking .ttlWrap::before {
    content: none;
  }
  .ranking .ttlWrap__inner {
    padding: 20px 4.6153846154% 30px;
    display: block;
  }
  .ranking .ttlWrap__inner::after {
    content: "";
    width: clamp(87px, 44.358974359vw, 173px);
    height: clamp(73px, 37.4358974359vw, 146px);
    top: -20px;
    right: -4.358974359%;
  }
  .ranking .ttlWrap__img {
    margin: 30px 0;
  }
  .ranking .ttlWrap__ttl {
    font-size: 1.5rem;
    border-top: 4px solid #004DA1;
    border-bottom: 4px solid #D70110;
    padding: 6px 8px;
    margin: 0 0 10px;
  }
  .ranking .ttlWrap__ttl::before {
    width: 20px;
    height: 13px;
    top: -13px;
  }
  .ranking .ttlWrap__catch {
    font-size: 3.4rem;
    line-height: 1.23529;
    margin: 0 0 10px;
  }
  .ranking .ttlWrap__text {
    font-size: 1.4rem;
    line-height: 1.78571;
  }
  .rankingCont {
    padding: 0 4.358974359%;
  }
  .rankingCont__inner {
    padding: 0;
  }
  .rankingCont .box--col2 {
    display: block;
    border: none;
    padding: 0;
    margin: 40px 0;
  }
  .rankingCont .box--col2__right {
    margin: 20px 0 0;
    width: 100%;
  }
  .rankingCont .box--col2__left {
    width: 100%;
  }
  .rankingCont .box--col2__ttl {
    font-size: 1.6rem;
    line-height: 1.875;
    margin: 0 0 10px;
    padding: 0 0 0 21px;
  }
  .rankingCont .box--col2__ttl .sm {
    font-size: 1rem;
  }
  .rankingCont .box--col2__ttl::before {
    width: 15px;
    height: 14px;
  }
  .rankingCont .box--yellow {
    border-radius: 15px;
    padding: 20px 7.6923076923%;
  }
  .rankingCont .box--yellow__ttl {
    font-size: 1.5rem;
    margin: 0 0 12px;
    text-align: center;
  }
  .rankingCont .box--yellow__ttl .icon {
    width: 25px;
    margin: 0 5px 2px 0;
  }
  .rankingCont .box--yellow__list li {
    font-size: 1.3rem;
  }
  .rankingBox {
    padding: 40px 2.5641025641% 38px;
  }
  .rankingBox__ttl {
    font-size: 2rem;
    line-height: 1.6;
  }
  .rankingBox .rankingList__ttl {
    font-size: 1.8rem;
    line-height: 1.38888;
    padding: 7px 2.0512820513% 2px;
  }
  .rankingBox .rankingList__ttl a {
    font-size: 1.8rem;
    line-height: 1.38888;
  }
  .rankingBox .rankingList__ttl::before {
    font-size: 1rem;
    line-height: 2.5;
    width: 23px;
    height: 24px;
    margin: 0 5px 7px 0;
  }
  .rankingBox .rankingList__catch {
    font-size: 2rem;
    line-height: 1.4;
    margin: 0 0 15px;
  }
  .rankingBox .rankingList__item {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin: 30px 0 0;
  }
  .rankingBox .rankingList__item .container {
    padding: 15px 2.5641025641% 20px;
  }
  .rankingBox .rankingList__item .headWrap {
    display: block;
  }
  .rankingBox .rankingList__item .headWrap .thumb {
    margin: 0 auto;
    display: block;
  }
  .rankingBox .rankingList__item .headWrap .thumb + .pointList {
    width: 100%;
    margin: 10px 0 0;
  }
  .rankingBox .rankingList__item .headWrap .pointList {
    padding: 0;
    border: none;
  }
  .rankingBox .rankingList__item .headWrap .pointList__item {
    font-size: 1.4rem;
    line-height: 1.42857;
    padding: 10px 15px 10px 27px;
  }
  .rankingBox .rankingList__item .headWrap .pointList__item::before {
    width: 16px;
    height: 16px;
    top: 19px;
    left: 3px;
  }
  .rankingBox .rankingList__item .status {
    margin: 20px 0 30px;
  }
  .rankingBox .rankingList__item .status .statusList {
    margin: 0 0 20px -5px;
    justify-content: center;
  }
  .rankingBox .rankingList__item .status .statusList__item {
    margin: 0 4px 8px;
    width: 61px;
    height: 61px;
  }
  .rankingBox .rankingList__item .status .statusList__item .wrap {
    font-size: 1.9rem;
    line-height: 1.10526;
  }
  .rankingBox .rankingList__item .status .statusList__item .sm {
    font-size: 1rem;
  }
  .rankingBox .rankingList__item .status .statusList__item .md {
    font-size: 1.3rem;
  }
  .rankingBox .rankingList__item .status .statusList__item.no::after {
    font-size: 0.8rem;
  }
  .rankingBox .rankingList__item .status .note {
    line-height: 1.71428;
  }
  .rankingBox .rankingList__item .spec__ttl {
    padding: 15px 0;
  }
  .rankingBox .rankingList__item .spec__ttl::before {
    width: 19px;
    height: 16px;
    margin: 0 6px -2px 0;
  }
  .rankingBox .rankingList__item .spec__table {
    display: block;
    white-space: normal;
    border-right: 1px solid #CCCCCC;
    border-bottom: 1px solid #CCCCCC;
  }
  .rankingBox .rankingList__item .spec__table:nth-child(2n) th {
    border-left: 1px solid #CCCCCC;
  }
  .rankingBox .rankingList__item .spec__table tr {
    display: flex;
    flex-wrap: wrap;
  }
  .rankingBox .rankingList__item .spec__table th, .rankingBox .rankingList__item .spec__table td {
    font-size: 1.3rem;
    padding: 20px 10px;
    border: none;
    border-top: 1px solid #CCCCCC;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .rankingBox .rankingList__item .spec__table th {
    font-weight: 500;
    width: 6rem;
  }
  .rankingBox .rankingList__item .spec__table td {
    width: calc((100% - 12rem) / 2);
  }
  .rankingBox .rankingList__item .conditions__ttl {
    padding: 15px 0;
  }
  .rankingBox .rankingList__item .conditions__ttl::before {
    width: 16px;
    height: 18px;
    margin: 0 7px -2px 0;
  }
  .rankingBox .rankingList__item .conditions__text {
    font-size: 1.4rem;
    line-height: 1.71428;
  }
  .rankingBox .rankingList__item .btn--col2 {
    display: block;
  }
  .rankingBox .rankingList__item .btn--col2 .btn {
    width: 100%;
  }
  .rankingBox .rankingList__item .btn--col2 .btn + .btn {
    margin: 15px 0 0;
  }
  .rankingBox .rankingList__item .btn {
    background: transparent;
    padding: 0;
    margin: 40px 0 0;
  }
  .rankingBox .rankingList__item .btn a {
    font-size: 1.8rem;
    border-radius: 4px;
    box-shadow: 2px 2px 0px 2px #AA000C;
    padding: 20px 5px;
  }
  .rankingBox .rankingList__item .btn a::before {
    width: 18px;
    height: 18px;
    margin: 0 5px -3px 0;
  }
}
@media screen and (max-width: 767px) and (min-width: 767px) {
  .rankingBox .rankingList__item .btn a {
    transition-property: all;
    transition-duration: 0s;
    transition-timing-function: ease-out;
  }
  .rankingBox .rankingList__item .btn a:hover {
    box-shadow: none;
    transform: translate(6px, 6px);
    opacity: 0.9;
  }
}
/* =================================
  header
================================= */
.header {
  width: 100%;
  background: #fff;
}
.header__logo {
  margin-bottom: -90px;
  padding: 36px 35px;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .header__logo {
    padding: 2.635431918vw 2.5622254758vw;
    margin-bottom: -7.3206442167vw;
  }
}
.header__logo .logo {
  display: inline-block;
  vertical-align: middle;
  margin-right: 36px;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .header__logo .logo {
    margin-right: 2.635431918vw;
    width: 29.8682284041vw;
  }
}
.header__logo .logo a {
  display: block;
}
@media screen and (min-width: 767px) {
  .header__logo .logo a:hover {
    opacity: 0.8;
  }
}
.header__logo .text {
  font-size: 1.4rem;
  display: inline-block;
  vertical-align: middle;
  max-width: 13em;
}
.header .btnArea {
  margin: 0 25px 30px auto;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .header .btnArea {
    margin: 0 1.8301610542vw 2.196193265vw auto;
  }
}
.header .btnArea__ttl {
  display: none;
}
.header .btnArea__text {
  display: none;
}
.header .btnArea__btn {
  margin: 0 0 0 10px;
}
.header .gNav__inner {
  display: flex;
  flex-direction: column-reverse;
}
.header .gNavList {
  background: rgb(0, 77, 161);
  background: -moz-linear-gradient(180deg, rgb(0, 77, 161) 0%, rgb(4, 69, 141) 100%);
  background: -webkit-linear-gradient(180deg, rgb(0, 77, 161) 0%, rgb(4, 69, 141) 100%);
  background: linear-gradient(180deg, rgb(0, 77, 161) 0%, rgb(4, 69, 141) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="$color-blue",endColorstr="#04458d",GradientType=1);
  padding: 0 1%;
}
.header .gNavList__inner {
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.header .gNavList__item {
  display: inline-block;
  position: relative;
}
.header .gNavList__item::after {
  content: "";
  width: 0;
  height: 4px;
  display: block;
  border-bottom: 4px double #fff;
  position: absolute;
  left: 50%;
  bottom: 13px;
  transform: translateX(-50%);
  z-index: 0;
  transition-property: all;
  transition-duration: 0.1s;
  transition-timing-function: ease-out;
}
@media screen and (min-width: 767px) {
  .header .gNavList__item:hover::after {
    width: 100%;
  }
}
.header .gNavList__item a {
  font-size: 1.6rem;
  font-weight: 500;
  color: #fff;
  line-height: 1.428571;
  text-decoration: none;
  padding: 21px 0 20px;
  display: block;
  position: relative;
  z-index: 2;
}
.header .gNavList__item.current-menu-item a {
  color: #304A85;
}
.header .gNavList__item.current-menu-item a::after {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .gNavList.fixedNav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.06);
    z-index: 999;
    animation: header-show 0.5s ease-in;
  }
}
@media screen and (min-width: 0) and (max-width: 767px) {
  /* =================================
    header
  ================================= */
  .header {
    padding: 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.06);
    /*バーガーボタン設定*/
  }
  .header__inner {
    display: block;
    height: 60px;
  }
  .header__logo {
    width: calc(100% - 60px);
    min-height: 60px;
    padding: 15px 0 15px 4.358974359%;
    background: #fff;
    display: flex;
    align-items: center;
  }
  .header__logo .logo {
    width: clamp(200px, 70.7692307692vw, 276px);
    line-height: 1;
  }
  .header__logo .text {
    display: none;
  }
  .header .btnArea {
    display: block;
    margin: 74px auto 0;
    padding: 40px 5.1282051282% 30px;
    background: #FFF8DB;
    position: relative;
  }
  .header .btnArea__ttl {
    display: block;
    font-size: 1.5rem;
    box-shadow: 3px 5px 0px 0px #DEBA10;
    width: 89.7435897436%;
    position: absolute;
    top: -43px;
    left: 5.1282051282%;
  }
  .header .btnArea__ttl::before {
    width: 5px;
    height: calc(100% + 5px);
    background: #DEBA10;
    display: inline-block;
    position: absolute;
    top: 0;
    right: -5px;
  }
  .header .btnArea__ttl::after {
    border-width: 14px 8px 0 8px;
    filter: drop-shadow(3px 4px 0px #DEBA10);
    bottom: -14px;
  }
  .header .btnArea__text {
    font-size: 1.4rem;
    line-height: 2;
    display: block;
    margin: 20px 0 0;
  }
  .header .btnArea__btn {
    margin: 0;
  }
  .header .gNav {
    overflow-y: scroll;
    max-height: 90vh;
    width: 100%;
    margin: auto;
    padding: 20px 0;
    position: fixed;
    top: 60px;
    left: 0;
    z-index: 9999;
    transform: scale(0.7);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease-in-out, transform 0.3s ease-out, visibility 0.2s ease-out;
  }
  .header .gNav__inner {
    display: block;
  }
  .header .gNavList {
    background: none;
    padding: 0 5.1282051282%;
  }
  .header .gNavList__inner {
    display: block;
  }
  .header .gNavList__item {
    display: block;
    margin: 0;
  }
  .header .gNavList__item::after {
    content: none;
  }
  .header .gNavList__item a {
    font-size: 1.4rem;
    color: #fff;
    padding: 20px 40px 20px 3.8461538462%;
    border-radius: 5px;
    background: #004DA1;
    text-align: center;
  }
  .header .gNavList__item a::before {
    content: "";
    width: 16px;
    height: 16px;
    display: inline-block;
    background: url(../img/common/icon/icon_gnav_arrow.svg) no-repeat center center;
    background-size: contain;
    margin: 0 8px -3px 0;
  }
  .header .gNavList__item + .gNavList__item {
    margin-top: 20px;
  }
  .header .gNav.show {
    transform: scale(1);
    visibility: visible;
    opacity: 1;
  }
  .header .gNav.hide {
    transform: scale(0.7);
    opacity: 0;
    visibility: hidden;
  }
  .header .headerNav__cover {
    height: 0;
    width: 100vw;
    background: #F5F6F9;
    position: fixed;
    top: 60px;
    left: 0;
    display: none;
    z-index: 9998;
  }
  .header .headerNav__cover.show {
    display: block;
    animation: show 0.2s linear 0s;
  }
  .header .headerNav__cover.hide {
    display: none;
    animation: hide 0.2s linear 0s;
  }
  .header .burger {
    width: 60px;
    height: 60px;
    background: #fff;
    margin: auto;
    border-left: 2px solid #EBEBEB;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    z-index: 9999;
    /*クリック後、バツボタンになる*/
  }
  .header .burger::before {
    content: "";
    width: 23px;
    height: 3px;
    display: inline-block;
    background: #004DA1;
    position: absolute;
    top: calc(50% - 3.5px);
    left: 50%;
    transform: translate(-50%, -50%);
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
  }
  .header .burger::after {
    content: "";
    width: 23px;
    height: 3px;
    display: inline-block;
    background: #D70110;
    position: absolute;
    top: calc(50% + 3.5px);
    left: 50%;
    transform: translate(-50%, -50%);
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
  }
  .header .burger.is-open::before {
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
  }
  .header .burger.is-open::after {
    top: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}
/*=================================
footer
=================================*/
.footer {
  background: #F5F6F9;
}
.footer__inner {
  max-width: calc(1140px + 2%);
  margin: 0 auto;
  padding: 29px 1% 27px;
}
.footer .fNavList {
  text-align: center;
}
.footer .fNavList__item {
  display: inline-block;
  margin: 0 25px;
}
.footer .fNavList__item a {
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.384615;
  color: #000;
  text-decoration: none;
}
@media screen and (min-width: 767px) {
  .footer .fNavList__item a:hover {
    text-decoration: underline;
    color: #304A85;
  }
}
.footer .copy {
  font-size: 1.2rem;
  line-height: 1.5;
  color: #fff;
  background: #004DA1;
  text-align: center;
  display: block;
  padding: 29px 1%;
}

.footAnnounce {
  padding: 30px 1%;
  background: #fff;
}
.footAnnounce__inner {
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}
.footAnnounce .announce {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.375;
}

@media screen and (min-width: 0) and (max-width: 767px) {
  /*=================================
  footer
  =================================*/
  .footer__inner {
    padding: 29px 0;
  }
  .footer .fNavList__item {
    margin: 0 1rem;
  }
  .footer .fNavList__item a {
    font-size: 1.2rem;
  }
  .footAnnounce {
    padding: 40px 4.358974359% 30px;
    background: #FFF8DB;
  }
  .footAnnounce__inner {
    display: block;
  }
  .footAnnounce .announce {
    font-size: 1.4rem;
    line-height: 2;
    margin: 20px 0 0;
  }
}
/* =================================
  container
================================= */
/* singlePage */
.singlePage {
  /*---------------------
    記事タイトル
  ---------------------*/
  /*---------------------
    メインビジュアル
  ---------------------*/
  /*---------------------
    更新日
  ---------------------*/
  /*---------------------
    目次
  ---------------------*/
  /*---------------------
    関連記事
  ---------------------*/
  /*---------------------
    既存テーマスタイル
  ---------------------*/
  /* balloon状の見出し */
  /* トピックス状 */
  /* 商標誘導ボタン */
  /* 商標誘導カード */
  padding: 30px 1% 100px;
}
.singlePage h1, .singlePage h2, .singlePage h3, .singlePage h4, .singlePage h5, .singlePage h6 {
  clear: both;
}
.singlePage p:not([class]) {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.75;
}
.singlePage p:not([class]) + p {
  margin-top: 2rem;
}
.singlePage h1:not([class]), .singlePage h1.wp-block-heading {
  font-size: 3.2rem;
  font-weight: 700;
  line-height: 1.5;
  margin-top: 100px;
  margin-bottom: 40px;
}
.singlePage h2:not([class]), .singlePage h2.wp-block-heading {
  font-size: 2.5rem;
  font-weight: 700;
  color: #fff;
  background: #004DA1;
  line-height: 1.2;
  margin-top: 50px;
  margin-bottom: 30px;
  padding: 15px 30px;
}
.singlePage h3:not([class]), .singlePage h3.wp-block-heading {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.4;
  margin-top: 68px;
  margin-bottom: 30px;
  padding-top: 18px;
  border-top: 3px solid #004DA1;
}
.singlePage h4:not([class]), .singlePage h4.wp-block-heading {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.666666;
  margin-top: 50px;
  margin-bottom: 30px;
  padding: 12px 24px;
  background: #F5F6F9;
  position: relative;
}
.singlePage h4:not([class])::before, .singlePage h4.wp-block-heading::before {
  content: "";
  width: 7px;
  height: 100%;
  display: inline-block;
  border-left: 2px solid #004DA1;
  border-right: 2px solid #D70110;
  position: absolute;
  top: 0;
  left: 0;
}
.singlePage h5:not([class]), .singlePage h5.wp-block-heading {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.66666;
  margin-top: 50px;
  margin-bottom: 33px;
  padding-left: 30px;
  position: relative;
}
.singlePage h5:not([class])::before, .singlePage h5.wp-block-heading::before {
  content: "";
  width: 19px;
  height: 17px;
  display: inline-block;
  background: url(../img/common/ttl_line_4.svg) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 6px;
  left: 0;
}
.singlePage h6:not([class]), .singlePage h6.wp-block-heading {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.77777;
  color: #004DA1;
  margin-top: 40px;
  margin-bottom: 20px;
  padding-left: 20px;
  position: relative;
}
.singlePage h6:not([class])::before, .singlePage h6.wp-block-heading::before {
  content: "";
  width: 10px;
  height: 10px;
  display: inline-block;
  background: #004DA1;
  position: absolute;
  top: 12px;
  left: 0;
  transform: rotate(45deg);
}
.singlePage blockquote {
  position: relative;
  padding: 50px 20px 20px;
  margin: 50px 0 30px;
  box-sizing: border-box;
  font-style: italic;
  color: #464646;
  border: 10px solid #F5F6F9;
}
.singlePage blockquote::before {
  content: "“";
  font-size: 5rem;
  font-weight: 700;
  color: #004DA1;
  display: inline-block;
  font-style: normal;
  width: 50px;
  height: 50px;
  line-height: 1.3;
  text-align: center;
  background: #F5F6F9;
  border-radius: 50px;
  position: absolute;
  top: -25px;
  left: 15px;
}
.singlePage blockquote h1 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.singlePage blockquote h2 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.singlePage blockquote h3 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.singlePage blockquote h4 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.singlePage blockquote h5 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.singlePage blockquote h6 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.singlePage blockquote p {
  font-style: normal;
}
.singlePage blockquote cite {
  display: block;
  text-align: right;
  line-height: 1;
  color: #888888;
  font-size: 0.9em;
  margin: 10px 0 0;
}
.singlePage .btnWrap {
  clear: both;
}
.singlePage img:not([class]), .singlePage.wp-block-heading {
  display: block;
  margin: 15px auto 20px;
}
.singlePage img.alignleft {
  float: left;
  margin: 0 30px 30px 0;
}
.singlePage img.alignright {
  float: right;
  margin: 0 0 30px 30px;
}
.singlePage img.aligncenter, .singlePage img.alignnone {
  margin: 0 auto;
  display: block;
}
.singlePage ul:not([class]) {
  margin: 50px 30px;
  padding: 38px 27px;
  background: #FFF;
  border: 10px solid #F5F6F9;
  clear: both;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .singlePage ul:not([class]) {
    padding: 2.7818448023vw 1.9765739385vw;
  }
}
.singlePage ul:not([class]) li {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2;
  color: #004DA1;
  padding-left: 28px;
  position: relative;
}
.singlePage ul:not([class]) li::before {
  content: "";
  width: 18px;
  height: 18px;
  background: url(../img/common/icon/icon_ul.svg) no-repeat center center;
  background-size: contain;
  display: inline-block;
  position: absolute;
  top: 6px;
  left: 0;
}
.singlePage ul:not([class]) li + li {
  margin-top: 10px;
}
.singlePage ul:not([class]) h1::before {
  content: none;
}
.singlePage ul:not([class]) h2::before {
  content: none;
}
.singlePage ul:not([class]) h3::before {
  content: none;
}
.singlePage ul:not([class]) h4::before {
  content: none;
}
.singlePage ul:not([class]) h5::before {
  content: none;
}
.singlePage ul:not([class]) h6::before {
  content: none;
}
.singlePage ol:not([class]) {
  margin: 50px 30px;
  padding: 38px 27px;
  background: #FFF;
  border: 10px solid #F5F6F9;
  counter-reset: listNum;
  clear: both;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .singlePage ol:not([class]) {
    padding: 2.7818448023vw 1.9765739385vw;
  }
}
.singlePage ol:not([class]) li {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2;
  padding-left: 31px;
  position: relative;
}
.singlePage ol:not([class]) li::before {
  counter-increment: listNum;
  content: counter(listNum);
  font-size: 1.2rem;
  font-weight: 400;
  color: #fff;
  background: #004DA1;
  width: 24px;
  line-height: 24px;
  border-radius: 50px;
  text-align: center;
  display: inline-block;
  position: absolute;
  top: 4px;
  left: 0;
}
.singlePage ol:not([class]) li + li {
  margin-top: 10px;
}
.singlePage ol:not([class]) h1::before {
  content: none;
}
.singlePage ol:not([class]) h2::before {
  content: none;
}
.singlePage ol:not([class]) h3::before {
  content: none;
}
.singlePage ol:not([class]) h4::before {
  content: none;
}
.singlePage ol:not([class]) h5::before {
  content: none;
}
.singlePage ol:not([class]) h6::before {
  content: none;
}
@media screen and (min-width: 0) and (max-width: 767px) {
  .singlePage p:not([class]) {
    font-size: 1.5rem;
    line-height: 1.71428;
  }
  .singlePage h1:not([class]), .singlePage h1.wp-block-heading {
    font-size: 2.4rem;
    list-style: 1.44444;
    margin-top: 54px;
    margin-bottom: 20px;
  }
  .singlePage h2:not([class]), .singlePage h2.wp-block-heading {
    font-size: 1.8rem;
    line-height: 1.55555;
    margin-top: 54px;
    margin-bottom: 20px;
    margin-left: calc(-4.358974359% - 1px);
    padding: 15px 4.358974359%;
    width: calc(108.7179487179% + 2px);
  }
  .singlePage h3:not([class]), .singlePage h3.wp-block-heading {
    font-size: 1.6rem;
    line-height: 1.5;
    margin-top: 40px;
    margin-bottom: 20px;
    padding-top: 15px;
  }
  .singlePage h4:not([class]), .singlePage h4.wp-block-heading {
    font-size: 1.4rem;
    line-height: 1.625;
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 11px 4.1025641026%;
  }
  .singlePage h5:not([class]), .singlePage h5.wp-block-heading {
    font-size: 1.5rem;
    margin-top: 30px;
    margin-bottom: 20px;
    padding-left: 22px;
  }
  .singlePage h5:not([class])::before, .singlePage h5.wp-block-heading::before {
    width: 15px;
    height: 14px;
    top: 5px;
  }
  .singlePage h6:not([class]), .singlePage h6.wp-block-heading {
    font-size: 1.5rem;
    line-height: 1.73333;
    padding-left: 15px;
  }
  .singlePage h6:not([class])::before, .singlePage h6.wp-block-heading::before {
    width: 8px;
    height: 8px;
    top: 10px;
  }
  .singlePage blockquote {
    padding: 40px 3.8461538462% 20px;
    margin: 40px 0 30px;
    border: 7px solid #F5F6F9;
  }
  .singlePage blockquote::before {
    font-size: 4rem;
    width: 45px;
    height: 45px;
    line-height: 1.5;
  }
  .singlePage blockquote h1 {
    font-size: 1.5rem;
  }
  .singlePage blockquote h2 {
    font-size: 1.5rem;
  }
  .singlePage blockquote h3 {
    font-size: 1.5rem;
  }
  .singlePage blockquote h4 {
    font-size: 1.5rem;
  }
  .singlePage blockquote h5 {
    font-size: 1.5rem;
  }
  .singlePage blockquote h6 {
    font-size: 1.5rem;
  }
  .singlePage img.alignleft {
    margin: 0 7px 7px 0;
  }
  .singlePage img.alignright {
    margin: 0 0 7px 7px;
  }
  .singlePage ul:not([class]) {
    margin: 20px auto 40px;
    padding: 17px 5.1282051282%;
    border: 7px solid #F5F6F9;
  }
  .singlePage ul:not([class]) li {
    padding-left: 28px;
  }
  .singlePage ul:not([class]) li::before {
    width: 20px;
    height: 20px;
    top: 5px;
  }
  .singlePage ol:not([class]) {
    margin: 20px auto 40px;
    padding: 17px 5.1282051282%;
    border: 7px solid #F5F6F9;
  }
  .singlePage ol:not([class]) li::before {
    font-size: 1rem;
    width: 22px;
    line-height: 22px;
  }
}
.singlePage iframe {
  width: 100%;
}
.singlePage .articleTtl {
  font-size: 3.2rem;
  list-style: 1.5;
  margin: 0 0 8px;
}
.singlePage .articleMv {
  margin: 15px auto;
  text-align: center;
  overflow: hidden;
}
.singlePage .articleMv img {
  margin: 0 auto;
}
.singlePage .date {
  text-align: right;
}
.singlePage .date > * {
  font-size: 1.2rem !important;
  font-weight: 400;
  color: #333333;
  display: inline-block;
  line-height: 1 !important;
  margin: 0;
}
.singlePage .date > * + * {
  margin: 0 0 0 1rem !important;
}
.singlePage .tocBox {
  margin: 40px auto 100px;
  padding: 23px;
  background: #F5F6F9;
}
.singlePage .tocBox__inner {
  background: #fff;
  padding: 0 20px 20px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
}
.singlePage .tocBox__ttl {
  font-size: 1.8rem;
  line-height: 1;
  margin: 0 0 15px;
  text-align: center;
}
.singlePage .tocBox__ttl::before {
  content: "";
  width: 20px;
  height: 16px;
  display: inline-block;
  background: url(../img/common/icon/icon_book.svg) no-repeat center center;
  background-size: contain;
  margin: 0 9px -2px 0;
}
.singlePage .tocBox .tocList {
  counter-reset: tocNum;
}
.singlePage .tocBox .tocList__item:not(:last-child) {
  border-bottom: 1px solid #D2DAF0;
}
.singlePage .tocBox .tocList__item a {
  font-size: 1.6rem;
  font-weight: 400;
  color: #004DA1;
  display: block;
  padding: 12px 24px;
  text-decoration: none;
  position: relative;
}
.singlePage .tocBox .tocList__item a::before {
  counter-increment: tocNum;
  content: counter(tocNum) ".";
  font-size: 1.6rem;
  font-weight: 400;
  color: #004DA1;
  display: inline-block;
  position: absolute;
  top: 12px;
  left: 0;
}
.singlePage .tocBox .tocList__item a::after {
  content: "";
  border-style: solid;
  border-width: 0 0 12px 12px;
  border-color: transparent transparent #004DA1 transparent;
  display: inline-block;
  position: absolute;
  bottom: 12px;
  right: 0;
  transition-property: all;
  transition-duration: 0.1s;
  transition-timing-function: ease-out;
}
@media screen and (min-width: 767px) {
  .singlePage .tocBox .tocList__item a:hover {
    text-decoration: underline;
    color: #304A85;
  }
  .singlePage .tocBox .tocList__item a:hover::after {
    bottom: 6px;
  }
}
.singlePage .tocBox .tocList__item .tocList {
  counter-reset: tocChildNum;
  padding-left: 26px;
}
.singlePage .tocBox .tocList__item .tocList__item a {
  padding-left: 46px;
  display: block;
}
.singlePage .tocBox .tocList__item .tocList__item a::before {
  counter-increment: tocChildNum;
  content: counter(tocNum) " - " counter(tocChildNum) ".";
}
.singlePage .tocBox .tocMore {
  font-size: 1.5rem;
  font-weight: 500;
  background: #FECB3F;
  text-align: center;
  margin-top: 20px;
  padding: 8px 20px;
  display: inline-block;
  text-align: center;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}
@media screen and (min-width: 767px) {
  .singlePage .tocBox .tocMore:hover {
    color: #fff;
    background: #004DA1;
  }
}
.singlePage .tocBox:not(.open) .tocBox__inner > .tocList > .tocList__item:nth-child(n+4) {
  display: none;
}
.singlePage .tocBox:not(.open) .tocMore::after {
  transform: rotate(0deg);
}
@media screen and (min-width: 767px) {
  .singlePage .tocBox:not(.open) .tocMore:hover::after {
    transform: translateY(2px) rotate(90deg);
  }
}
.singlePage .related {
  margin: 60px auto 50px;
  position: relative;
}
.singlePage .related__ttl {
  position: absolute;
  top: -24px;
  right: 4px;
  z-index: 1;
}
.singlePage .related .relatedList__item {
  padding: 16px 24px 15px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
}
.singlePage .related .relatedList__item::after {
  border-width: 0 0 14px 14px;
  bottom: 14px;
  right: 12px;
}
.singlePage .related .relatedList__item .itemWrap .thumb {
  width: 80px;
}
.singlePage .related .relatedList__item .itemWrap .thumb img {
  margin: 0;
}
.singlePage .related .relatedList__item .itemWrap .thumb + .textWrap {
  width: calc(100% - 102px);
  margin-left: 22px;
}
.singlePage .related .relatedList__item .itemWrap .textWrap {
  padding: 15px 0;
}
.singlePage .related .relatedList__item .itemWrap .textWrap__ttl {
  font-size: 1.8rem;
  line-height: 1.38888;
}
.singlePage .related .relatedList__item + .relatedList__item {
  margin-top: 40px;
}
.singlePage .point {
  font-size: 1.5rem;
  font-weight: 700;
  color: #fff;
  display: inline-block;
  padding: 4px 16px 7px;
  background: #D70110;
  box-shadow: 3px 4px 0px 0px #AA000C;
  position: relative;
  transform: translateY(20px);
}
.singlePage .point::before {
  content: "";
  width: 4px;
  height: calc(100% + 4px);
  background: #AA000C;
  display: inline-block;
  position: absolute;
  top: 0;
  right: -4px;
}
.singlePage .point::after {
  content: "";
  border-style: solid;
  border-width: 11px 5px 0 5px;
  border-color: #D70110 transparent transparent transparent;
  display: inline-block;
  filter: drop-shadow(3px 4px 0px #AA000C);
  position: absolute;
  bottom: -11px;
  left: 50%;
  transform: translateX(-50%);
}
.singlePage .point .icon {
  display: inline-block;
  vertical-align: middle;
  margin: 0 15px 0 0;
}
.singlePage .point + ul, .singlePage .point ol {
  margin-top: 0;
  border-color: #FFF1B4;
}
.singlePage .point + ul li::before {
  background: url(../img/common/icon/icon_ul_yellow.svg) no-repeat center center;
  background-size: contain;
}
.singlePage .note {
  background: #F5F6F9;
  margin: 50px 40px;
  padding: 50px 40px 40px;
  position: relative;
}
.singlePage .note::before {
  content: "";
  width: 100%;
  height: 7px;
  display: block;
  border-top: 2px solid #004DA1;
  border-bottom: 2px solid #D70110;
  position: absolute;
  top: 0;
  left: 0;
}
.singlePage .note h1 {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.66666;
  color: #004DA1;
  background: none;
  margin: 0 0 20px !important;
  padding: 0 0 0 43px;
  border: none;
  position: relative;
}
.singlePage .note h1::before {
  content: "";
  width: 31px;
  height: 44px;
  display: inline-block;
  background: url(../img/common/ttl_topic.svg) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: -8px;
  left: 0;
  border: none;
  margin: 0;
  padding: 0;
  transform: none;
}
.singlePage .note h1::after {
  content: none;
}
.singlePage .note h2 {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.66666;
  color: #004DA1;
  background: none;
  margin: 0 0 20px !important;
  padding: 0 0 0 43px;
  border: none;
  position: relative;
}
.singlePage .note h2::before {
  content: "";
  width: 31px;
  height: 44px;
  display: inline-block;
  background: url(../img/common/ttl_topic.svg) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: -8px;
  left: 0;
  border: none;
  margin: 0;
  padding: 0;
  transform: none;
}
.singlePage .note h2::after {
  content: none;
}
.singlePage .note h3 {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.66666;
  color: #004DA1;
  background: none;
  margin: 0 0 20px !important;
  padding: 0 0 0 43px;
  border: none;
  position: relative;
}
.singlePage .note h3::before {
  content: "";
  width: 31px;
  height: 44px;
  display: inline-block;
  background: url(../img/common/ttl_topic.svg) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: -8px;
  left: 0;
  border: none;
  margin: 0;
  padding: 0;
  transform: none;
}
.singlePage .note h3::after {
  content: none;
}
.singlePage .note h4 {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.66666;
  color: #004DA1;
  background: none;
  margin: 0 0 20px !important;
  padding: 0 0 0 43px;
  border: none;
  position: relative;
}
.singlePage .note h4::before {
  content: "";
  width: 31px;
  height: 44px;
  display: inline-block;
  background: url(../img/common/ttl_topic.svg) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: -8px;
  left: 0;
  border: none;
  margin: 0;
  padding: 0;
  transform: none;
}
.singlePage .note h4::after {
  content: none;
}
.singlePage .note h5 {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.66666;
  color: #004DA1;
  background: none;
  margin: 0 0 20px !important;
  padding: 0 0 0 43px;
  border: none;
  position: relative;
}
.singlePage .note h5::before {
  content: "";
  width: 31px;
  height: 44px;
  display: inline-block;
  background: url(../img/common/ttl_topic.svg) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: -8px;
  left: 0;
  border: none;
  margin: 0;
  padding: 0;
  transform: none;
}
.singlePage .note h5::after {
  content: none;
}
.singlePage .note h6 {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.66666;
  color: #004DA1;
  background: none;
  margin: 0 0 20px !important;
  padding: 0 0 0 43px;
  border: none;
  position: relative;
}
.singlePage .note h6::before {
  content: "";
  width: 31px;
  height: 44px;
  display: inline-block;
  background: url(../img/common/ttl_topic.svg) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: -8px;
  left: 0;
  border: none;
  margin: 0;
  padding: 0;
  transform: none;
}
.singlePage .note h6::after {
  content: none;
}
.singlePage .note p {
  background: #fff;
  margin: 0 !important;
  padding: 20px;
}
.singlePage .note p + p {
  padding-top: 0;
}
.singlePage .btn {
  background: #F5F6F9;
  padding: 30px;
  margin: 50px auto;
}
.singlePage .btn a {
  font-size: 2.8rem;
  font-weight: 500;
  color: #fff;
  background: rgb(215, 1, 16);
  background: -moz-linear-gradient(180deg, rgb(215, 1, 16) 0%, rgb(185, 5, 18) 100%);
  background: -webkit-linear-gradient(180deg, rgb(215, 1, 16) 0%, rgb(185, 5, 18) 100%);
  background: linear-gradient(180deg, rgb(215, 1, 16) 0%, rgb(185, 5, 18) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d70110",endColorstr="#b90512",GradientType=1);
  border-radius: 5px;
  box-shadow: 4px 4px 0px 4px #AA000C;
  padding: 30px 5px;
  text-align: center;
  text-decoration: none;
  display: block;
}
.singlePage .btn a::before {
  content: "";
  width: 26px;
  height: 26px;
  display: inline-block;
  background: url(../img/common/icon/icon_arrow_right.svg) no-repeat center center;
  background-size: contain;
  margin: 0 10px -2px 0;
}
@media screen and (min-width: 767px) {
  .singlePage .btn a {
    transition-property: all;
    transition-duration: 0s;
    transition-timing-function: ease-out;
  }
  .singlePage .btn a:hover {
    box-shadow: none;
    transform: translate(8px, 8px);
    opacity: 0.9;
  }
}
.singlePage .intr {
  margin: 34px 0 70px;
  position: relative;
}
.singlePage .intr__ttl {
  position: absolute;
  top: -33px;
  right: 4px;
}
.singlePage .intrBox {
  border: 10px solid #E6E6E6;
  padding: 20px;
}
.singlePage .intrBox__link {
  font-size: 2.2rem;
  color: #004DA1;
  background: #F5F6F9;
  padding: 13px 5px;
  margin: 0 0 20px;
  display: block;
  text-align: center;
  text-decoration: none;
}
@media screen and (min-width: 767px) {
  .singlePage .intrBox__link:hover {
    text-decoration: underline;
  }
}
.singlePage .intrBox__cont {
  display: flex;
  align-items: flex-start;
}
.singlePage .intrBox__cont .thumb {
  width: clamp(150px, 19.0336749634vw, 260px);
  margin-right: 30px;
}
.singlePage .intrBox__cont .thumb + .spec {
  width: calc(100% - clamp(150px, 19.0336749634vw, 260px) - 30px);
}
.singlePage .intrBox__cont .spec__text {
  font-size: 1.5rem;
}
.singlePage .intrBox__cont .spec__table {
  margin: 16px 0;
}
.singlePage .intrBox__cont .spec__table th {
  font-weight: 500;
}
.singlePage .intrBox__cont .spec__table td {
  font-weight: 700;
  background: #fff;
  text-align: center;
}
.singlePage .intrBox__cont .spec__note {
  font-size: 1rem;
  margin: 5px 0 0;
  text-align: right;
}
.singlePage .intrBox__cont .spec__btn {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.5;
  color: #fff;
  text-decoration: none;
  display: block;
  background: rgb(215, 1, 16);
  background: -moz-linear-gradient(180deg, rgb(215, 1, 16) 0%, rgb(185, 5, 18) 100%);
  background: -webkit-linear-gradient(180deg, rgb(215, 1, 16) 0%, rgb(185, 5, 18) 100%);
  background: linear-gradient(180deg, rgb(215, 1, 16) 0%, rgb(185, 5, 18) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d70110",endColorstr="#b90512",GradientType=1);
  border-radius: 4px;
  box-shadow: 2px 2px 0px 2px #AA000C;
  padding: 13px 5px 11px;
  margin: 12px 0 0;
  text-align: center;
  position: relative;
}
.singlePage .intrBox__cont .spec__btn::before {
  content: "";
  width: 15px;
  height: 15px;
  display: inline-block;
  background: url(../img/common/icon/icon_arrow_right.svg) no-repeat center center;
  background-size: contain;
  margin: 0 10px -2px 0;
}
@media screen and (min-width: 767px) {
  .singlePage .intrBox__cont .spec__btn {
    transition-property: all;
    transition-duration: 0s;
    transition-timing-function: ease-out;
  }
  .singlePage .intrBox__cont .spec__btn:hover {
    box-shadow: none;
    transform: translate(2px, 2px);
    opacity: 0.9;
  }
}
@media screen and (min-width: 0) and (max-width: 767px) {
  .singlePage {
    /*---------------------
      記事タイトル
    ---------------------*/
    /*---------------------
      メインビジュアル
    ---------------------*/
    /*---------------------
      更新日
    ---------------------*/
    /*---------------------
      目次
    ---------------------*/
    /*---------------------
      関連記事
    ---------------------*/
    /*---------------------
      既存テーマスタイル
    ---------------------*/
    /* balloon状の見出し */
    /* トピックス状 */
    /* 商標誘導ボタン */
    /* 商標誘導カード */
  }
  .singlePage .articleTtl {
    font-size: 2.4rem;
    list-style: 1.33333;
  }
  .singlePage .articleMv {
    padding: 0 2.0512820513%;
  }
  .singlePage .date {
    text-align: left;
  }
  .singlePage .tocBox {
    margin: 50px auto;
    padding: 17px 4.358974359%;
    width: calc(108.7179487179% + 2px);
    margin-left: calc(-4.358974359% - 1px);
  }
  .singlePage .tocBox__inner {
    padding: 10px 3.8461538462% 26px;
    border: 1px solid #D2DAF0;
  }
  .singlePage .tocBox__inner > .tocList > .tocList__item:last-of-type {
    border-bottom: 1px solid #D2DAF0;
  }
  .singlePage .tocBox__ttl {
    margin: 0 0 10px;
  }
  .singlePage .tocBox__ttl::before {
    width: 18px;
    height: 14px;
    margin: 0 7px -2px 0;
  }
  .singlePage .tocBox .tocList__item a {
    font-size: 1.4rem;
    padding: 11px 1rem 11px 2rem;
  }
  .singlePage .tocBox .tocList__item a::before {
    font-size: 1.4rem;
    top: 11px;
  }
  .singlePage .tocBox .tocList__item a::after {
    border-width: 0 0 9px 9px;
    bottom: 9px;
  }
  .singlePage .tocBox .tocList__item .tocList {
    padding-left: 2rem;
  }
  .singlePage .tocBox .tocList__item .tocList__item a {
    padding-left: 4rem;
  }
  .singlePage .tocBox .tocMore {
    font-size: 1.3rem;
    margin-top: 15px;
    padding: 5px 15px;
  }
  .singlePage .related {
    margin: 30px auto;
  }
  .singlePage .related__ttl {
    position: relative;
    top: -8px;
    left: 50%;
    right: initial;
    transform: translateX(-50%);
  }
  .singlePage .related .relatedList__item {
    padding: 10px 6.6666666667% 10px 2.5641025641%;
  }
  .singlePage .related .relatedList__item::after {
    border-width: 0 0 10px 10px;
    bottom: 10px;
    right: 11px;
  }
  .singlePage .related .relatedList__item .itemWrap .thumb + .textWrap {
    width: calc(100% - 90px);
    margin-left: 10px;
  }
  .singlePage .related .relatedList__item .itemWrap .textWrap {
    padding: 0;
  }
  .singlePage .related .relatedList__item .itemWrap .textWrap__ttl {
    font-size: 1.4rem;
    line-height: 1.4285;
  }
  .singlePage .related .relatedList__item + .relatedList__item {
    margin-top: 17px;
  }
  .singlePage .point {
    font-size: 1.4rem;
    padding: 10px 20px 11px;
    transform: translateY(0);
  }
  .singlePage .point .icon {
    margin: 0 12px 0 0;
  }
  .singlePage .note {
    margin: 30px 0 40px;
    padding: 25px 5.8974358974% 25px;
  }
  .singlePage .note h1 {
    font-size: 1.5rem;
    margin: 0 0 9px !important;
    padding: 0 0 0 31px;
  }
  .singlePage .note h1::before {
    width: 24px;
    height: 33px;
  }
  .singlePage .note h2 {
    font-size: 1.5rem;
    margin: 0 0 9px !important;
    padding: 0 0 0 31px;
  }
  .singlePage .note h2::before {
    width: 24px;
    height: 33px;
  }
  .singlePage .note h3 {
    font-size: 1.5rem;
    margin: 0 0 9px !important;
    padding: 0 0 0 31px;
  }
  .singlePage .note h3::before {
    width: 24px;
    height: 33px;
  }
  .singlePage .note h4 {
    font-size: 1.5rem;
    margin: 0 0 9px !important;
    padding: 0 0 0 31px;
  }
  .singlePage .note h4::before {
    width: 24px;
    height: 33px;
  }
  .singlePage .note h5 {
    font-size: 1.5rem;
    margin: 0 0 9px !important;
    padding: 0 0 0 31px;
  }
  .singlePage .note h5::before {
    width: 24px;
    height: 33px;
  }
  .singlePage .note h6 {
    font-size: 1.5rem;
    margin: 0 0 9px !important;
    padding: 0 0 0 31px;
  }
  .singlePage .note h6::before {
    width: 24px;
    height: 33px;
  }
  .singlePage .note p {
    padding: 18px 4.6153846154%;
  }
  .singlePage .btn {
    padding: 8px;
    margin: 37px auto;
  }
  .singlePage .btn a {
    font-size: 1.8rem;
    border-radius: 4px;
    box-shadow: 2px 2px 0px 2px #AA000C;
    padding: 20px 4.358974359%;
  }
  .singlePage .btn a::before {
    width: 16px;
    height: 16px;
    margin: 0 5px -2px 0;
  }
  .singlePage .intr {
    margin: 32px 0 40px;
  }
  .singlePage .intr__ttl {
    font-size: 1.4rem;
    line-height: 3.2;
    position: relative;
    top: -12px;
    left: 50%;
    right: initial;
    transform: translateX(-50%);
  }
  .singlePage .intrBox {
    border: 7px solid #E6E6E6;
    padding: 10px 10px 20px;
  }
  .singlePage .intrBox__link {
    font-size: 1.6rem;
    padding: 10px;
  }
  .singlePage .intrBox__cont {
    display: block;
  }
  .singlePage .intrBox__cont .thumb {
    width: 97.4358974359%;
    margin: 0 auto 20px;
  }
  .singlePage .intrBox__cont .thumb + .spec {
    width: 100%;
  }
  .singlePage .intrBox__cont .spec__text {
    font-size: 1.6rem;
    text-align: center;
  }
  .singlePage .intrBox__cont .spec__table {
    margin: 10px 0 15px;
    display: table;
    white-space: normal;
  }
  .singlePage .intrBox__cont .spec__table th {
    font-size: 1.2rem;
    padding: 8px;
  }
  .singlePage .intrBox__cont .spec__table td {
    font-size: 1.4rem;
    padding: 8px;
  }
  .singlePage .intrBox__cont .spec__note {
    font-size: 1.2rem;
    margin: 15px 0 0;
    text-align: left;
  }
  .singlePage .intrBox__cont .spec__btn {
    padding: 17px 5px 17px;
    margin: 20px 0 0;
  }
}
.singlePage__in {
  max-width: 1140px;
  margin: 0 auto;
}
.singlePage__in > h1:first-child {
  margin-top: 0 !important;
}
.singlePage__in > h2:first-child {
  margin-top: 0 !important;
}
.singlePage__in > h3:first-child {
  margin-top: 0 !important;
}
.singlePage__in > h4:first-child {
  margin-top: 0 !important;
}
.singlePage__in > h5:first-child {
  margin-top: 0 !important;
}
.singlePage__in > h6:first-child {
  margin-top: 0 !important;
}
.singlePage__in .tocBox + * {
  margin-top: 0 !important;
}

/* 記事詳細ページ */
.articleDetail {
  /*---------------------
    記事タイトル
  ---------------------*/
  /*---------------------
    メインビジュアル
  ---------------------*/
  /*---------------------
    更新日
  ---------------------*/
  /*---------------------
    目次
  ---------------------*/
  /*---------------------
    関連記事
  ---------------------*/
  /*---------------------
    既存テーマスタイル
  ---------------------*/
  /* balloon状の見出し */
  /* トピックス状 */
  /* 商標誘導ボタン */
  /* 商標誘導カード */
  padding: 33px 0 0;
  position: relative;
}
.articleDetail h1, .articleDetail h2, .articleDetail h3, .articleDetail h4, .articleDetail h5, .articleDetail h6 {
  clear: both;
}
.articleDetail p:not([class]) {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.75;
}
.articleDetail p:not([class]) + p {
  margin-top: 2rem;
}
.articleDetail h1:not([class]), .articleDetail h1.wp-block-heading {
  font-size: 3.2rem;
  font-weight: 700;
  line-height: 1.5;
  margin-top: 100px;
  margin-bottom: 40px;
}
.articleDetail h2:not([class]), .articleDetail h2.wp-block-heading {
  font-size: 2.5rem;
  font-weight: 700;
  color: #fff;
  background: #004DA1;
  line-height: 1.2;
  margin-top: 50px;
  margin-bottom: 30px;
  padding: 15px 30px;
}
.articleDetail h3:not([class]), .articleDetail h3.wp-block-heading {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.4;
  margin-top: 68px;
  margin-bottom: 30px;
  padding-top: 18px;
  border-top: 3px solid #004DA1;
}
.articleDetail h4:not([class]), .articleDetail h4.wp-block-heading {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.666666;
  margin-top: 50px;
  margin-bottom: 30px;
  padding: 12px 24px;
  background: #F5F6F9;
  position: relative;
}
.articleDetail h4:not([class])::before, .articleDetail h4.wp-block-heading::before {
  content: "";
  width: 7px;
  height: 100%;
  display: inline-block;
  border-left: 2px solid #004DA1;
  border-right: 2px solid #D70110;
  position: absolute;
  top: 0;
  left: 0;
}
.articleDetail h5:not([class]), .articleDetail h5.wp-block-heading {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.66666;
  margin-top: 50px;
  margin-bottom: 33px;
  padding-left: 30px;
  position: relative;
}
.articleDetail h5:not([class])::before, .articleDetail h5.wp-block-heading::before {
  content: "";
  width: 19px;
  height: 17px;
  display: inline-block;
  background: url(../img/common/ttl_line_4.svg) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 6px;
  left: 0;
}
.articleDetail h6:not([class]), .articleDetail h6.wp-block-heading {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.77777;
  color: #004DA1;
  margin-top: 40px;
  margin-bottom: 20px;
  padding-left: 20px;
  position: relative;
}
.articleDetail h6:not([class])::before, .articleDetail h6.wp-block-heading::before {
  content: "";
  width: 10px;
  height: 10px;
  display: inline-block;
  background: #004DA1;
  position: absolute;
  top: 12px;
  left: 0;
  transform: rotate(45deg);
}
.articleDetail blockquote {
  position: relative;
  padding: 50px 20px 20px;
  margin: 50px 0 30px;
  box-sizing: border-box;
  font-style: italic;
  color: #464646;
  border: 10px solid #F5F6F9;
}
.articleDetail blockquote::before {
  content: "“";
  font-size: 5rem;
  font-weight: 700;
  color: #004DA1;
  display: inline-block;
  font-style: normal;
  width: 50px;
  height: 50px;
  line-height: 1.3;
  text-align: center;
  background: #F5F6F9;
  border-radius: 50px;
  position: absolute;
  top: -25px;
  left: 15px;
}
.articleDetail blockquote h1 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.articleDetail blockquote h2 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.articleDetail blockquote h3 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.articleDetail blockquote h4 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.articleDetail blockquote h5 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.articleDetail blockquote h6 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.articleDetail blockquote p {
  font-style: normal;
}
.articleDetail blockquote cite {
  display: block;
  text-align: right;
  line-height: 1;
  color: #888888;
  font-size: 0.9em;
  margin: 10px 0 0;
}
.articleDetail .btnWrap {
  clear: both;
}
.articleDetail img:not([class]), .articleDetail.wp-block-heading {
  display: block;
  margin: 15px auto 20px;
}
.articleDetail img.alignleft {
  float: left;
  margin: 0 30px 30px 0;
}
.articleDetail img.alignright {
  float: right;
  margin: 0 0 30px 30px;
}
.articleDetail img.aligncenter, .articleDetail img.alignnone {
  margin: 0 auto;
  display: block;
}
.articleDetail ul:not([class]) {
  margin: 50px 30px;
  padding: 38px 27px;
  background: #FFF;
  border: 10px solid #F5F6F9;
  clear: both;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .articleDetail ul:not([class]) {
    padding: 2.7818448023vw 1.9765739385vw;
  }
}
.articleDetail ul:not([class]) li {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2;
  color: #004DA1;
  padding-left: 28px;
  position: relative;
}
.articleDetail ul:not([class]) li::before {
  content: "";
  width: 18px;
  height: 18px;
  background: url(../img/common/icon/icon_ul.svg) no-repeat center center;
  background-size: contain;
  display: inline-block;
  position: absolute;
  top: 6px;
  left: 0;
}
.articleDetail ul:not([class]) li + li {
  margin-top: 10px;
}
.articleDetail ul:not([class]) h1::before {
  content: none;
}
.articleDetail ul:not([class]) h2::before {
  content: none;
}
.articleDetail ul:not([class]) h3::before {
  content: none;
}
.articleDetail ul:not([class]) h4::before {
  content: none;
}
.articleDetail ul:not([class]) h5::before {
  content: none;
}
.articleDetail ul:not([class]) h6::before {
  content: none;
}
.articleDetail ol:not([class]) {
  margin: 50px 30px;
  padding: 38px 27px;
  background: #FFF;
  border: 10px solid #F5F6F9;
  counter-reset: listNum;
  clear: both;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .articleDetail ol:not([class]) {
    padding: 2.7818448023vw 1.9765739385vw;
  }
}
.articleDetail ol:not([class]) li {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2;
  padding-left: 31px;
  position: relative;
}
.articleDetail ol:not([class]) li::before {
  counter-increment: listNum;
  content: counter(listNum);
  font-size: 1.2rem;
  font-weight: 400;
  color: #fff;
  background: #004DA1;
  width: 24px;
  line-height: 24px;
  border-radius: 50px;
  text-align: center;
  display: inline-block;
  position: absolute;
  top: 4px;
  left: 0;
}
.articleDetail ol:not([class]) li + li {
  margin-top: 10px;
}
.articleDetail ol:not([class]) h1::before {
  content: none;
}
.articleDetail ol:not([class]) h2::before {
  content: none;
}
.articleDetail ol:not([class]) h3::before {
  content: none;
}
.articleDetail ol:not([class]) h4::before {
  content: none;
}
.articleDetail ol:not([class]) h5::before {
  content: none;
}
.articleDetail ol:not([class]) h6::before {
  content: none;
}
@media screen and (min-width: 0) and (max-width: 767px) {
  .articleDetail p:not([class]) {
    font-size: 1.5rem;
    line-height: 1.71428;
  }
  .articleDetail h1:not([class]), .articleDetail h1.wp-block-heading {
    font-size: 2.4rem;
    list-style: 1.44444;
    margin-top: 54px;
    margin-bottom: 20px;
  }
  .articleDetail h2:not([class]), .articleDetail h2.wp-block-heading {
    font-size: 1.8rem;
    line-height: 1.55555;
    margin-top: 54px;
    margin-bottom: 20px;
    margin-left: calc(-4.358974359% - 1px);
    padding: 15px 4.358974359%;
    width: calc(108.7179487179% + 2px);
  }
  .articleDetail h3:not([class]), .articleDetail h3.wp-block-heading {
    font-size: 1.6rem;
    line-height: 1.5;
    margin-top: 40px;
    margin-bottom: 20px;
    padding-top: 15px;
  }
  .articleDetail h4:not([class]), .articleDetail h4.wp-block-heading {
    font-size: 1.4rem;
    line-height: 1.625;
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 11px 4.1025641026%;
  }
  .articleDetail h5:not([class]), .articleDetail h5.wp-block-heading {
    font-size: 1.5rem;
    margin-top: 30px;
    margin-bottom: 20px;
    padding-left: 22px;
  }
  .articleDetail h5:not([class])::before, .articleDetail h5.wp-block-heading::before {
    width: 15px;
    height: 14px;
    top: 5px;
  }
  .articleDetail h6:not([class]), .articleDetail h6.wp-block-heading {
    font-size: 1.5rem;
    line-height: 1.73333;
    padding-left: 15px;
  }
  .articleDetail h6:not([class])::before, .articleDetail h6.wp-block-heading::before {
    width: 8px;
    height: 8px;
    top: 10px;
  }
  .articleDetail blockquote {
    padding: 40px 3.8461538462% 20px;
    margin: 40px 0 30px;
    border: 7px solid #F5F6F9;
  }
  .articleDetail blockquote::before {
    font-size: 4rem;
    width: 45px;
    height: 45px;
    line-height: 1.5;
  }
  .articleDetail blockquote h1 {
    font-size: 1.5rem;
  }
  .articleDetail blockquote h2 {
    font-size: 1.5rem;
  }
  .articleDetail blockquote h3 {
    font-size: 1.5rem;
  }
  .articleDetail blockquote h4 {
    font-size: 1.5rem;
  }
  .articleDetail blockquote h5 {
    font-size: 1.5rem;
  }
  .articleDetail blockquote h6 {
    font-size: 1.5rem;
  }
  .articleDetail img.alignleft {
    margin: 0 7px 7px 0;
  }
  .articleDetail img.alignright {
    margin: 0 0 7px 7px;
  }
  .articleDetail ul:not([class]) {
    margin: 20px auto 40px;
    padding: 17px 5.1282051282%;
    border: 7px solid #F5F6F9;
  }
  .articleDetail ul:not([class]) li {
    padding-left: 28px;
  }
  .articleDetail ul:not([class]) li::before {
    width: 20px;
    height: 20px;
    top: 5px;
  }
  .articleDetail ol:not([class]) {
    margin: 20px auto 40px;
    padding: 17px 5.1282051282%;
    border: 7px solid #F5F6F9;
  }
  .articleDetail ol:not([class]) li::before {
    font-size: 1rem;
    width: 22px;
    line-height: 22px;
  }
}
.articleDetail iframe {
  width: 100%;
}
.articleDetail .articleTtl {
  font-size: 3.2rem;
  list-style: 1.5;
  margin: 0 0 8px;
}
.articleDetail .articleMv {
  margin: 15px auto;
  text-align: center;
  overflow: hidden;
}
.articleDetail .articleMv img {
  margin: 0 auto;
}
.articleDetail .date {
  text-align: right;
}
.articleDetail .date > * {
  font-size: 1.2rem !important;
  font-weight: 400;
  color: #333333;
  display: inline-block;
  line-height: 1 !important;
  margin: 0;
}
.articleDetail .date > * + * {
  margin: 0 0 0 1rem !important;
}
.articleDetail .tocBox {
  margin: 40px auto 100px;
  padding: 23px;
  background: #F5F6F9;
}
.articleDetail .tocBox__inner {
  background: #fff;
  padding: 0 20px 20px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
}
.articleDetail .tocBox__ttl {
  font-size: 1.8rem;
  line-height: 1;
  margin: 0 0 15px;
  text-align: center;
}
.articleDetail .tocBox__ttl::before {
  content: "";
  width: 20px;
  height: 16px;
  display: inline-block;
  background: url(../img/common/icon/icon_book.svg) no-repeat center center;
  background-size: contain;
  margin: 0 9px -2px 0;
}
.articleDetail .tocBox .tocList {
  counter-reset: tocNum;
}
.articleDetail .tocBox .tocList__item:not(:last-child) {
  border-bottom: 1px solid #D2DAF0;
}
.articleDetail .tocBox .tocList__item a {
  font-size: 1.6rem;
  font-weight: 400;
  color: #004DA1;
  display: block;
  padding: 12px 24px;
  text-decoration: none;
  position: relative;
}
.articleDetail .tocBox .tocList__item a::before {
  counter-increment: tocNum;
  content: counter(tocNum) ".";
  font-size: 1.6rem;
  font-weight: 400;
  color: #004DA1;
  display: inline-block;
  position: absolute;
  top: 12px;
  left: 0;
}
.articleDetail .tocBox .tocList__item a::after {
  content: "";
  border-style: solid;
  border-width: 0 0 12px 12px;
  border-color: transparent transparent #004DA1 transparent;
  display: inline-block;
  position: absolute;
  bottom: 12px;
  right: 0;
  transition-property: all;
  transition-duration: 0.1s;
  transition-timing-function: ease-out;
}
@media screen and (min-width: 767px) {
  .articleDetail .tocBox .tocList__item a:hover {
    text-decoration: underline;
    color: #304A85;
  }
  .articleDetail .tocBox .tocList__item a:hover::after {
    bottom: 6px;
  }
}
.articleDetail .tocBox .tocList__item .tocList {
  counter-reset: tocChildNum;
  padding-left: 26px;
}
.articleDetail .tocBox .tocList__item .tocList__item a {
  padding-left: 46px;
  display: block;
}
.articleDetail .tocBox .tocList__item .tocList__item a::before {
  counter-increment: tocChildNum;
  content: counter(tocNum) " - " counter(tocChildNum) ".";
}
.articleDetail .tocBox .tocMore {
  font-size: 1.5rem;
  font-weight: 500;
  background: #FECB3F;
  text-align: center;
  margin-top: 20px;
  padding: 8px 20px;
  display: inline-block;
  text-align: center;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}
@media screen and (min-width: 767px) {
  .articleDetail .tocBox .tocMore:hover {
    color: #fff;
    background: #004DA1;
  }
}
.articleDetail .tocBox:not(.open) .tocBox__inner > .tocList > .tocList__item:nth-child(n+4) {
  display: none;
}
.articleDetail .tocBox:not(.open) .tocMore::after {
  transform: rotate(0deg);
}
@media screen and (min-width: 767px) {
  .articleDetail .tocBox:not(.open) .tocMore:hover::after {
    transform: translateY(2px) rotate(90deg);
  }
}
.articleDetail .related {
  margin: 60px auto 50px;
  position: relative;
}
.articleDetail .related__ttl {
  position: absolute;
  top: -24px;
  right: 4px;
  z-index: 1;
}
.articleDetail .related .relatedList__item {
  padding: 16px 24px 15px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
}
.articleDetail .related .relatedList__item::after {
  border-width: 0 0 14px 14px;
  bottom: 14px;
  right: 12px;
}
.articleDetail .related .relatedList__item .itemWrap .thumb {
  width: 80px;
}
.articleDetail .related .relatedList__item .itemWrap .thumb img {
  margin: 0;
}
.articleDetail .related .relatedList__item .itemWrap .thumb + .textWrap {
  width: calc(100% - 102px);
  margin-left: 22px;
}
.articleDetail .related .relatedList__item .itemWrap .textWrap {
  padding: 15px 0;
}
.articleDetail .related .relatedList__item .itemWrap .textWrap__ttl {
  font-size: 1.8rem;
  line-height: 1.38888;
}
.articleDetail .related .relatedList__item + .relatedList__item {
  margin-top: 40px;
}
.articleDetail .point {
  font-size: 1.5rem;
  font-weight: 700;
  color: #fff;
  display: inline-block;
  padding: 4px 16px 7px;
  background: #D70110;
  box-shadow: 3px 4px 0px 0px #AA000C;
  position: relative;
  transform: translateY(20px);
}
.articleDetail .point::before {
  content: "";
  width: 4px;
  height: calc(100% + 4px);
  background: #AA000C;
  display: inline-block;
  position: absolute;
  top: 0;
  right: -4px;
}
.articleDetail .point::after {
  content: "";
  border-style: solid;
  border-width: 11px 5px 0 5px;
  border-color: #D70110 transparent transparent transparent;
  display: inline-block;
  filter: drop-shadow(3px 4px 0px #AA000C);
  position: absolute;
  bottom: -11px;
  left: 50%;
  transform: translateX(-50%);
}
.articleDetail .point .icon {
  display: inline-block;
  vertical-align: middle;
  margin: 0 15px 0 0;
}
.articleDetail .point + ul, .articleDetail .point ol {
  margin-top: 0;
  border-color: #FFF1B4;
}
.articleDetail .point + ul li::before {
  background: url(../img/common/icon/icon_ul_yellow.svg) no-repeat center center;
  background-size: contain;
}
.articleDetail .note {
  background: #F5F6F9;
  margin: 50px 40px;
  padding: 50px 40px 40px;
  position: relative;
}
.articleDetail .note::before {
  content: "";
  width: 100%;
  height: 7px;
  display: block;
  border-top: 2px solid #004DA1;
  border-bottom: 2px solid #D70110;
  position: absolute;
  top: 0;
  left: 0;
}
.articleDetail .note h1 {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.66666;
  color: #004DA1;
  background: none;
  margin: 0 0 20px !important;
  padding: 0 0 0 43px;
  border: none;
  position: relative;
}
.articleDetail .note h1::before {
  content: "";
  width: 31px;
  height: 44px;
  display: inline-block;
  background: url(../img/common/ttl_topic.svg) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: -8px;
  left: 0;
  border: none;
  margin: 0;
  padding: 0;
  transform: none;
}
.articleDetail .note h1::after {
  content: none;
}
.articleDetail .note h2 {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.66666;
  color: #004DA1;
  background: none;
  margin: 0 0 20px !important;
  padding: 0 0 0 43px;
  border: none;
  position: relative;
}
.articleDetail .note h2::before {
  content: "";
  width: 31px;
  height: 44px;
  display: inline-block;
  background: url(../img/common/ttl_topic.svg) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: -8px;
  left: 0;
  border: none;
  margin: 0;
  padding: 0;
  transform: none;
}
.articleDetail .note h2::after {
  content: none;
}
.articleDetail .note h3 {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.66666;
  color: #004DA1;
  background: none;
  margin: 0 0 20px !important;
  padding: 0 0 0 43px;
  border: none;
  position: relative;
}
.articleDetail .note h3::before {
  content: "";
  width: 31px;
  height: 44px;
  display: inline-block;
  background: url(../img/common/ttl_topic.svg) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: -8px;
  left: 0;
  border: none;
  margin: 0;
  padding: 0;
  transform: none;
}
.articleDetail .note h3::after {
  content: none;
}
.articleDetail .note h4 {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.66666;
  color: #004DA1;
  background: none;
  margin: 0 0 20px !important;
  padding: 0 0 0 43px;
  border: none;
  position: relative;
}
.articleDetail .note h4::before {
  content: "";
  width: 31px;
  height: 44px;
  display: inline-block;
  background: url(../img/common/ttl_topic.svg) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: -8px;
  left: 0;
  border: none;
  margin: 0;
  padding: 0;
  transform: none;
}
.articleDetail .note h4::after {
  content: none;
}
.articleDetail .note h5 {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.66666;
  color: #004DA1;
  background: none;
  margin: 0 0 20px !important;
  padding: 0 0 0 43px;
  border: none;
  position: relative;
}
.articleDetail .note h5::before {
  content: "";
  width: 31px;
  height: 44px;
  display: inline-block;
  background: url(../img/common/ttl_topic.svg) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: -8px;
  left: 0;
  border: none;
  margin: 0;
  padding: 0;
  transform: none;
}
.articleDetail .note h5::after {
  content: none;
}
.articleDetail .note h6 {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.66666;
  color: #004DA1;
  background: none;
  margin: 0 0 20px !important;
  padding: 0 0 0 43px;
  border: none;
  position: relative;
}
.articleDetail .note h6::before {
  content: "";
  width: 31px;
  height: 44px;
  display: inline-block;
  background: url(../img/common/ttl_topic.svg) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: -8px;
  left: 0;
  border: none;
  margin: 0;
  padding: 0;
  transform: none;
}
.articleDetail .note h6::after {
  content: none;
}
.articleDetail .note p {
  background: #fff;
  margin: 0 !important;
  padding: 20px;
}
.articleDetail .note p + p {
  padding-top: 0;
}
.articleDetail .btn {
  background: #F5F6F9;
  padding: 30px;
  margin: 50px auto;
}
.articleDetail .btn a {
  font-size: 2.8rem;
  font-weight: 500;
  color: #fff;
  background: rgb(215, 1, 16);
  background: -moz-linear-gradient(180deg, rgb(215, 1, 16) 0%, rgb(185, 5, 18) 100%);
  background: -webkit-linear-gradient(180deg, rgb(215, 1, 16) 0%, rgb(185, 5, 18) 100%);
  background: linear-gradient(180deg, rgb(215, 1, 16) 0%, rgb(185, 5, 18) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d70110",endColorstr="#b90512",GradientType=1);
  border-radius: 5px;
  box-shadow: 4px 4px 0px 4px #AA000C;
  padding: 30px 5px;
  text-align: center;
  text-decoration: none;
  display: block;
}
.articleDetail .btn a::before {
  content: "";
  width: 26px;
  height: 26px;
  display: inline-block;
  background: url(../img/common/icon/icon_arrow_right.svg) no-repeat center center;
  background-size: contain;
  margin: 0 10px -2px 0;
}
@media screen and (min-width: 767px) {
  .articleDetail .btn a {
    transition-property: all;
    transition-duration: 0s;
    transition-timing-function: ease-out;
  }
  .articleDetail .btn a:hover {
    box-shadow: none;
    transform: translate(8px, 8px);
    opacity: 0.9;
  }
}
.articleDetail .intr {
  margin: 34px 0 70px;
  position: relative;
}
.articleDetail .intr__ttl {
  position: absolute;
  top: -33px;
  right: 4px;
}
.articleDetail .intrBox {
  border: 10px solid #E6E6E6;
  padding: 20px;
}
.articleDetail .intrBox__link {
  font-size: 2.2rem;
  color: #004DA1;
  background: #F5F6F9;
  padding: 13px 5px;
  margin: 0 0 20px;
  display: block;
  text-align: center;
  text-decoration: none;
}
@media screen and (min-width: 767px) {
  .articleDetail .intrBox__link:hover {
    text-decoration: underline;
  }
}
.articleDetail .intrBox__cont {
  display: flex;
  align-items: flex-start;
}
.articleDetail .intrBox__cont .thumb {
  width: clamp(150px, 19.0336749634vw, 260px);
  margin-right: 30px;
}
.articleDetail .intrBox__cont .thumb + .spec {
  width: calc(100% - clamp(150px, 19.0336749634vw, 260px) - 30px);
}
.articleDetail .intrBox__cont .spec__text {
  font-size: 1.5rem;
}
.articleDetail .intrBox__cont .spec__table {
  margin: 16px 0;
}
.articleDetail .intrBox__cont .spec__table th {
  font-weight: 500;
}
.articleDetail .intrBox__cont .spec__table td {
  font-weight: 700;
  background: #fff;
  text-align: center;
}
.articleDetail .intrBox__cont .spec__note {
  font-size: 1rem;
  margin: 5px 0 0;
  text-align: right;
}
.articleDetail .intrBox__cont .spec__btn {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.5;
  color: #fff;
  text-decoration: none;
  display: block;
  background: rgb(215, 1, 16);
  background: -moz-linear-gradient(180deg, rgb(215, 1, 16) 0%, rgb(185, 5, 18) 100%);
  background: -webkit-linear-gradient(180deg, rgb(215, 1, 16) 0%, rgb(185, 5, 18) 100%);
  background: linear-gradient(180deg, rgb(215, 1, 16) 0%, rgb(185, 5, 18) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d70110",endColorstr="#b90512",GradientType=1);
  border-radius: 4px;
  box-shadow: 2px 2px 0px 2px #AA000C;
  padding: 13px 5px 11px;
  margin: 12px 0 0;
  text-align: center;
  position: relative;
}
.articleDetail .intrBox__cont .spec__btn::before {
  content: "";
  width: 15px;
  height: 15px;
  display: inline-block;
  background: url(../img/common/icon/icon_arrow_right.svg) no-repeat center center;
  background-size: contain;
  margin: 0 10px -2px 0;
}
@media screen and (min-width: 767px) {
  .articleDetail .intrBox__cont .spec__btn {
    transition-property: all;
    transition-duration: 0s;
    transition-timing-function: ease-out;
  }
  .articleDetail .intrBox__cont .spec__btn:hover {
    box-shadow: none;
    transform: translate(2px, 2px);
    opacity: 0.9;
  }
}
@media screen and (min-width: 0) and (max-width: 767px) {
  .articleDetail {
    /*---------------------
      記事タイトル
    ---------------------*/
    /*---------------------
      メインビジュアル
    ---------------------*/
    /*---------------------
      更新日
    ---------------------*/
    /*---------------------
      目次
    ---------------------*/
    /*---------------------
      関連記事
    ---------------------*/
    /*---------------------
      既存テーマスタイル
    ---------------------*/
    /* balloon状の見出し */
    /* トピックス状 */
    /* 商標誘導ボタン */
    /* 商標誘導カード */
  }
  .articleDetail .articleTtl {
    font-size: 2.4rem;
    list-style: 1.33333;
  }
  .articleDetail .articleMv {
    padding: 0 2.0512820513%;
  }
  .articleDetail .date {
    text-align: left;
  }
  .articleDetail .tocBox {
    margin: 50px auto;
    padding: 17px 4.358974359%;
    width: calc(108.7179487179% + 2px);
    margin-left: calc(-4.358974359% - 1px);
  }
  .articleDetail .tocBox__inner {
    padding: 10px 3.8461538462% 26px;
    border: 1px solid #D2DAF0;
  }
  .articleDetail .tocBox__inner > .tocList > .tocList__item:last-of-type {
    border-bottom: 1px solid #D2DAF0;
  }
  .articleDetail .tocBox__ttl {
    margin: 0 0 10px;
  }
  .articleDetail .tocBox__ttl::before {
    width: 18px;
    height: 14px;
    margin: 0 7px -2px 0;
  }
  .articleDetail .tocBox .tocList__item a {
    font-size: 1.4rem;
    padding: 11px 1rem 11px 2rem;
  }
  .articleDetail .tocBox .tocList__item a::before {
    font-size: 1.4rem;
    top: 11px;
  }
  .articleDetail .tocBox .tocList__item a::after {
    border-width: 0 0 9px 9px;
    bottom: 9px;
  }
  .articleDetail .tocBox .tocList__item .tocList {
    padding-left: 2rem;
  }
  .articleDetail .tocBox .tocList__item .tocList__item a {
    padding-left: 4rem;
  }
  .articleDetail .tocBox .tocMore {
    font-size: 1.3rem;
    margin-top: 15px;
    padding: 5px 15px;
  }
  .articleDetail .related {
    margin: 30px auto;
  }
  .articleDetail .related__ttl {
    position: relative;
    top: -8px;
    left: 50%;
    right: initial;
    transform: translateX(-50%);
  }
  .articleDetail .related .relatedList__item {
    padding: 10px 6.6666666667% 10px 2.5641025641%;
  }
  .articleDetail .related .relatedList__item::after {
    border-width: 0 0 10px 10px;
    bottom: 10px;
    right: 11px;
  }
  .articleDetail .related .relatedList__item .itemWrap .thumb + .textWrap {
    width: calc(100% - 90px);
    margin-left: 10px;
  }
  .articleDetail .related .relatedList__item .itemWrap .textWrap {
    padding: 0;
  }
  .articleDetail .related .relatedList__item .itemWrap .textWrap__ttl {
    font-size: 1.4rem;
    line-height: 1.4285;
  }
  .articleDetail .related .relatedList__item + .relatedList__item {
    margin-top: 17px;
  }
  .articleDetail .point {
    font-size: 1.4rem;
    padding: 10px 20px 11px;
    transform: translateY(0);
  }
  .articleDetail .point .icon {
    margin: 0 12px 0 0;
  }
  .articleDetail .note {
    margin: 30px 0 40px;
    padding: 25px 5.8974358974% 25px;
  }
  .articleDetail .note h1 {
    font-size: 1.5rem;
    margin: 0 0 9px !important;
    padding: 0 0 0 31px;
  }
  .articleDetail .note h1::before {
    width: 24px;
    height: 33px;
  }
  .articleDetail .note h2 {
    font-size: 1.5rem;
    margin: 0 0 9px !important;
    padding: 0 0 0 31px;
  }
  .articleDetail .note h2::before {
    width: 24px;
    height: 33px;
  }
  .articleDetail .note h3 {
    font-size: 1.5rem;
    margin: 0 0 9px !important;
    padding: 0 0 0 31px;
  }
  .articleDetail .note h3::before {
    width: 24px;
    height: 33px;
  }
  .articleDetail .note h4 {
    font-size: 1.5rem;
    margin: 0 0 9px !important;
    padding: 0 0 0 31px;
  }
  .articleDetail .note h4::before {
    width: 24px;
    height: 33px;
  }
  .articleDetail .note h5 {
    font-size: 1.5rem;
    margin: 0 0 9px !important;
    padding: 0 0 0 31px;
  }
  .articleDetail .note h5::before {
    width: 24px;
    height: 33px;
  }
  .articleDetail .note h6 {
    font-size: 1.5rem;
    margin: 0 0 9px !important;
    padding: 0 0 0 31px;
  }
  .articleDetail .note h6::before {
    width: 24px;
    height: 33px;
  }
  .articleDetail .note p {
    padding: 18px 4.6153846154%;
  }
  .articleDetail .btn {
    padding: 8px;
    margin: 37px auto;
  }
  .articleDetail .btn a {
    font-size: 1.8rem;
    border-radius: 4px;
    box-shadow: 2px 2px 0px 2px #AA000C;
    padding: 20px 4.358974359%;
  }
  .articleDetail .btn a::before {
    width: 16px;
    height: 16px;
    margin: 0 5px -2px 0;
  }
  .articleDetail .intr {
    margin: 32px 0 40px;
  }
  .articleDetail .intr__ttl {
    font-size: 1.4rem;
    line-height: 3.2;
    position: relative;
    top: -12px;
    left: 50%;
    right: initial;
    transform: translateX(-50%);
  }
  .articleDetail .intrBox {
    border: 7px solid #E6E6E6;
    padding: 10px 10px 20px;
  }
  .articleDetail .intrBox__link {
    font-size: 1.6rem;
    padding: 10px;
  }
  .articleDetail .intrBox__cont {
    display: block;
  }
  .articleDetail .intrBox__cont .thumb {
    width: 97.4358974359%;
    margin: 0 auto 20px;
  }
  .articleDetail .intrBox__cont .thumb + .spec {
    width: 100%;
  }
  .articleDetail .intrBox__cont .spec__text {
    font-size: 1.6rem;
    text-align: center;
  }
  .articleDetail .intrBox__cont .spec__table {
    margin: 10px 0 15px;
    display: table;
    white-space: normal;
  }
  .articleDetail .intrBox__cont .spec__table th {
    font-size: 1.2rem;
    padding: 8px;
  }
  .articleDetail .intrBox__cont .spec__table td {
    font-size: 1.4rem;
    padding: 8px;
  }
  .articleDetail .intrBox__cont .spec__note {
    font-size: 1.2rem;
    margin: 15px 0 0;
    text-align: left;
  }
  .articleDetail .intrBox__cont .spec__btn {
    padding: 17px 5px 17px;
    margin: 20px 0 0;
  }
}
.articleDetail::before {
  content: "";
  width: 100%;
  height: 10px;
  display: block;
  border-top: 3px solid #004DA1;
  border-bottom: 3px solid #D70110;
  position: absolute;
  top: 0;
  left: 0;
}
.articleDetail__in > h1:first-child {
  margin-top: 0 !important;
}
.articleDetail__in > h2:first-child {
  margin-top: 0 !important;
}
.articleDetail__in > h3:first-child {
  margin-top: 0 !important;
}
.articleDetail__in > h4:first-child {
  margin-top: 0 !important;
}
.articleDetail__in > h5:first-child {
  margin-top: 0 !important;
}
.articleDetail__in > h6:first-child {
  margin-top: 0 !important;
}
.articleDetail__in .tocBox + * {
  margin-top: 0 !important;
}

/* 記事一覧ページ */
.articleListWrap {
  background: #F5F6F9;
  padding: 90px 1% 100px;
}
.articleListWrap__in {
  max-width: 1140px;
  margin: 0 auto;
}

@media screen and (min-width: 0) and (max-width: 767px) {
  /* singlePage */
  .singlePage {
    padding: 0 4.358974359%;
  }
  /* 記事詳細ページ */
  .articleDetail {
    padding: 19px 0 0;
  }
  .articleDetail::before {
    height: 9px;
  }
  .articleDetail__in {
    padding: 0 4.358974359%;
  }
  /* 記事一覧ページ */
  .articleListWrap {
    padding: 20px 4.358974359%;
  }
}
/*=================================
  article
=================================*/
/* 記事一覧 */
.articleList__item {
  background: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  padding: 25px;
  position: relative;
}
.articleList__item::after {
  content: "";
  border-style: solid;
  border-width: 0 0 17px 17px;
  border-color: transparent transparent #004DA1 transparent;
  display: inline-block;
  position: absolute;
  bottom: 14px;
  right: 14px;
  transition-property: all;
  transition-duration: 0.1s;
  transition-timing-function: ease-out;
}
@media screen and (min-width: 767px) {
  .articleList__item:hover .textWrap__ttl {
    text-decoration: underline !important;
  }
}
.articleList__item .itemWrap {
  display: flex;
}
.articleList__item .itemWrap .thumb {
  width: 100px;
}
.articleList__item .itemWrap .thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.articleList__item .itemWrap .thumb + .textWrap {
  width: calc(100% - 126px);
  margin-left: 26px;
}
.articleList__item .itemWrap .textWrap__ttl {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.38888;
  color: #004DA1;
  display: block;
  text-decoration: none;
}
.articleList__item .itemWrap .textWrap__text {
  font-size: 1.6rem;
  line-height: 1.75;
  margin: 16px 0 0;
}
.articleList__item .itemWrap .textWrap__data {
  line-height: 1;
  margin-top: 4px;
}
.articleList__item .itemWrap .textWrap__data > * {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.7142;
  display: inline-block;
}
.articleList__item .itemWrap .textWrap__data > * + * {
  margin-left: 2rem;
}
.articleList__item + .articleList__item {
  margin-top: 20px;
}

/* 関連記事 */
.relatedBox {
  padding: 60px 1% 40px;
  background: #F5F6F9;
}
.relatedBox__inner {
  max-width: 1140px;
  margin: 0 auto;
}
.relatedBox__ttl {
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.5;
  padding: 0 0 0 48px;
  margin: 0 0 50px;
  position: relative;
}
.relatedBox__ttl::before {
  content: "";
  width: 28px;
  height: 24px;
  display: inline-block;
  background: url(../img/common/ttl_line_4.svg) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 12px;
  left: 0;
}
.relatedBox__text {
  font-size: 1.6rem;
  font-weight: 400;
  margin-top: 7px;
}
.relatedBox .relatedList {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.relatedBox .relatedList__item {
  width: calc((100% - 40px) / 2);
  margin-bottom: 20px;
}
.relatedBox .relatedList__item:not(:nth-child(2n+1)) {
  margin-left: 40px;
}

.relatedList__item {
  background: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  position: relative;
}
.relatedList__item::after {
  content: "";
  border-style: solid;
  border-width: 0 0 12px 12px;
  border-color: transparent transparent #004DA1 transparent;
  display: inline-block;
  position: absolute;
  bottom: 8px;
  right: 8px;
  transition-property: all;
  transition-duration: 0.1s;
  transition-timing-function: ease-out;
}
@media screen and (min-width: 767px) {
  .relatedList__item:hover .textWrap__ttl {
    text-decoration: underline !important;
  }
}
.relatedList__item .itemWrap {
  display: flex;
}
.relatedList__item .itemWrap .thumb {
  width: 100px;
}
.relatedList__item .itemWrap .thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.relatedList__item .itemWrap .thumb + .textWrap {
  width: calc(100% - 100px);
}
.relatedList__item .itemWrap .textWrap {
  padding: 18px 26px;
}
.relatedList__item .itemWrap .textWrap__ttl {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.5625;
  color: #004DA1;
  display: block;
  text-decoration: none;
}
.relatedList__item .itemWrap .textWrap__text {
  font-size: 1.5rem;
  line-height: 1.5;
  margin: 10px 0 0;
}
.relatedList__item .itemWrap .textWrap__data {
  line-height: 1;
  margin-top: 4px;
}
.relatedList__item .itemWrap .textWrap__data > * {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.7142;
  display: inline-block;
}
.relatedList__item .itemWrap .textWrap__data > * + * {
  margin-left: 2rem;
}

/*カテゴリー別記事一覧*/
.articleByCategory {
  max-width: 1060px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}
.articleByCategory__ttl {
  color: #fff;
  background: #004DA1;
  padding: 8px 18px;
  margin: 0 0 20px;
  display: flex;
  align-items: center;
}
.articleByCategory__ttl .ttl, .articleByCategory__ttl .num {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.66666;
}
.articleByCategory__item {
  width: calc((100% - 40px) / 2);
}
.articleByCategory__item:nth-child(2n) {
  margin-left: 40px;
}
.articleByCategory__item:nth-child(n+3) {
  margin-top: 40px;
}
.articleByCategory__item .btn--more {
  width: 70%;
  margin: 30px auto 0;
}
.articleByCategory .articleList__item {
  padding: 0;
}
.articleByCategory .articleList__item .itemWrap .thumb + .textWrap {
  width: calc(100% - 100px);
  margin-left: 0;
}
.articleByCategory .articleList__item .itemWrap .textWrap {
  padding: 26px 30px;
}
.articleByCategory .articleList__item .itemWrap .textWrap__ttl {
  font-size: 1.6rem;
  line-height: 1.5625;
}
.articleByCategory .articleList__item::after {
  border-width: 0 0 12px 12px;
  right: 8px;
  bottom: 8px;
}

@media screen and (min-width: 0) and (max-width: 767px) {
  /*=================================
  article
  =================================*/
  /* 記事一覧 */
  .articleList__item {
    padding: 8px;
  }
  .articleList__item::after {
    border-width: 0 0 10px 10px;
    bottom: 7px;
    right: 7px;
  }
  .articleList__item .itemWrap .thumb {
    width: 80px;
  }
  .articleList__item .itemWrap .thumb + .textWrap {
    width: calc(100% - 95px);
    margin-left: 15px;
  }
  .articleList__item .itemWrap .textWrap__ttl {
    font-size: 1.4rem;
    line-height: 1.64285;
  }
  .articleList__item .itemWrap .textWrap__text {
    display: none;
  }
  .articleList__item .itemWrap .textWrap__data {
    margin-top: 0;
  }
  .articleList__item .itemWrap .textWrap__data > * {
    font-size: 1.2rem;
  }
  .articleList__item + .articleList__item {
    margin-top: 17px;
  }
  /* 関連記事 */
  .relatedBox {
    padding: 40px 4.358974359%;
  }
  .relatedBox__ttl {
    font-size: 2.2rem;
    line-height: 1.545454;
    padding: 0 0 0 27px;
    margin: 0 0 23px;
  }
  .relatedBox__ttl::before {
    width: 20px;
    height: 17px;
    top: 8px;
  }
  .relatedBox__text {
    font-size: 1.4rem;
  }
  .relatedBox .relatedList {
    display: block;
  }
  .relatedBox .relatedList__item {
    width: 100%;
    margin-bottom: 15px;
  }
  .relatedBox .relatedList__item:not(:nth-child(2n+1)) {
    margin-left: 0;
  }
  .relatedList__item::after {
    border-width: 0 0 8px 8px;
    bottom: 6px;
    right: 6px;
  }
  .relatedList__item .itemWrap .thumb {
    width: 80px;
  }
  .relatedList__item .itemWrap .thumb + .textWrap {
    width: calc(100% - 80px);
  }
  .relatedList__item .itemWrap .textWrap {
    padding: 11px 12px 26px;
  }
  .relatedList__item .itemWrap .textWrap__ttl {
    font-size: 1.4rem;
    line-height: 1.64285;
  }
  .relatedList__item .itemWrap .textWrap__text {
    display: none;
  }
  .relatedList__item .itemWrap .textWrap__data {
    margin-top: 0;
  }
  .relatedList__item .itemWrap .textWrap__data > * {
    font-size: 1.2rem;
  }
  /*カテゴリー別記事一覧*/
  .articleByCategory {
    display: block;
  }
  .articleByCategory__ttl {
    padding: 9px 4.6153846154%;
    margin: 0 0 15px;
  }
  .articleByCategory__ttl .ttl, .articleByCategory__ttl .num {
    font-size: 1.6rem;
  }
  .articleByCategory__item {
    width: 100%;
  }
  .articleByCategory__item:nth-child(2n) {
    margin-left: 0;
  }
  .articleByCategory__item:nth-child(n+2) {
    margin-top: 40px;
  }
  .articleByCategory__item .btn--more {
    width: 100%;
    margin: 15px auto 0;
  }
  .articleByCategory .articleList__item {
    padding: 0;
  }
  .articleByCategory .articleList__item .itemWrap .thumb + .textWrap {
    width: calc(100% - 80px);
    margin-left: 0;
  }
  .articleByCategory .articleList__item .itemWrap .textWrap {
    padding: 11px 3.0769230769%;
  }
  .articleByCategory .articleList__item .itemWrap .textWrap__ttl {
    font-size: 1.4rem;
  }
  .articleByCategory .articleList__item::after {
    border-width: 0 0 8px 8px;
    right: 6px;
    bottom: 6px;
  }
}
/* =================================
  side nav
================================= */
.sideNav {
  width: 290px;
}
.sideNav__inner {
  width: 290px;
}
.sideNav .sideSec {
  position: relative;
  padding-top: 15px;
}
.sideNav .sideSec__ttl {
  font-size: 1.5rem;
  position: absolute;
  top: -55px;
}
.sideNav .sideSec__cont {
  background: #fff;
}
.sideNav .sideSec__cont .contTtl {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.5555;
  padding: 0 0 18px 27px;
  border-bottom: 1px solid #CCCCCC;
  position: relative;
}
.sideNav .sideSec__cont .contTtl::before {
  content: "";
  width: 20px;
  height: 17px;
  display: inline-block;
  background: url(../img/common/ttl_line_4.svg) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 8px;
  left: 0;
}
.sideNav .sideSec__cont .linkList {
  margin: 20px 0 0;
}
.sideNav .sideSec__cont .linkList__item {
  position: relative;
}
.sideNav .sideSec__cont .linkList__item a {
  font-size: 1.6rem;
  line-height: 1.375;
  color: #004DA1;
  display: block;
  text-decoration: none;
  padding: 0 0 0 22px;
}
@media screen and (min-width: 767px) {
  .sideNav .sideSec__cont .linkList__item a:hover {
    text-decoration: underline;
  }
}
.sideNav .sideSec__cont .linkList__item::before {
  content: "";
  width: 16px;
  height: 16px;
  display: inline-block;
  background: url(../img/common/icon/icon_arrow_right_blue.svg) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 5px;
  left: 0;
}
.sideNav .sideSec__cont .linkList__item + .linkList__item {
  margin-top: 20px;
}
.sideNav .sideSec__cont .linkList.new .linkList__item .date {
  font-size: 1.2rem;
  display: block;
}
.sideNav .sideSec__cont .linkList.new .linkList__item a {
  padding: 0;
}
.sideNav .sideSec__cont .linkList.new .linkList__item::before {
  content: none;
}
.sideNav .sideSec__cont .linkList.ranking {
  margin: 0;
}
.sideNav .sideSec__cont .linkList.ranking .linkList__item {
  border-right: 1px solid #CCCCCC;
  border-left: 1px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
  padding: 17px 17px 17px 60px;
  position: relative;
}
.sideNav .sideSec__cont .linkList.ranking .linkList__item .icon {
  position: absolute;
  top: 17px;
  left: 17px;
}
.sideNav .sideSec__cont .linkList.ranking .linkList__item .text {
  font-size: 1.4rem;
  line-height: 1.57142;
}
.sideNav .sideSec__cont .linkList.ranking .linkList__item a {
  font-size: 1.8rem;
  color: #000;
  padding: 0;
}
.sideNav .sideSec__cont .linkList.ranking .linkList__item::before {
  content: none;
}
.sideNav .sideSec__cont .linkList.ranking .linkList__item::after {
  content: "";
  display: inline-block;
  border-width: 0 0 12px 12px;
  border-style: solid;
  border-color: transparent transparent #D70110 transparent;
  position: absolute;
  right: 8px;
  bottom: 8px;
}
.sideNav .sideSec__cont .linkList.ranking .linkList__item + .linkList__item {
  margin-top: 0;
}
.sideNav .sideSec__cont + .sideSec__cont {
  margin-top: 50px;
}

@media screen and (max-width: 1023px) {
  /* =================================
    side nav
  ================================= */
  .sideNav {
    width: 100%;
  }
  .sideNav__inner {
    width: 100%;
  }
  .sideNav .sideSec {
    display: flex;
    flex-wrap: wrap;
    padding-top: 0;
  }
  .sideNav .sideSec__ttl {
    display: none;
  }
  .sideNav .sideSec__cont {
    width: 48%;
    margin-bottom: 4%;
  }
  .sideNav .sideSec__cont:nth-child(2n-1) {
    margin-left: 4%;
  }
  .sideNav .sideSec__cont .linkList.ranking {
    margin: 0;
  }
  .sideNav .sideSec__cont .linkList.ranking .linkList__item {
    border-right: none;
    border-left: none;
  }
  .sideNav .sideSec__cont + .sideSec__cont {
    margin-top: 0;
  }
}
@media screen and (min-width: 0) and (max-width: 767px) {
  /* =================================
    side nav
  ================================= */
  .sideNav {
    padding: 0 4.358974359%;
  }
  .sideNav .sideSec__cont {
    width: 100%;
    margin-bottom: 11%;
  }
  .sideNav .sideSec__cont:nth-child(2n-1) {
    margin-left: 0;
  }
  .sideNav .sideSec__cont .linkList.ranking .linkList__item .icon {
    left: 0;
  }
}
/*=================================
  cvBox
=================================*/
.cvBox {
  padding: 68px 1% 90px;
  background: #fff;
}
.cvBox__inner {
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  background: #FFF8DB;
  position: relative;
}
.cvBox__inner::before {
  content: "";
  width: 100%;
  height: 10px;
  display: block;
  border-top: 3px solid #004DA1;
  border-bottom: 3px solid #D70110;
  position: absolute;
  top: 0;
  left: 0;
}
.cvBox__inner::after {
  content: "";
  width: 100%;
  height: 10px;
  display: block;
  border-top: 3px solid #004DA1;
  border-bottom: 3px solid #D70110;
  position: absolute;
  bottom: 0;
  left: 0;
}
.cvBox__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: left top;
}
.cvBox__cont {
  padding: 74px 60px;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .cvBox__cont {
    padding: 5.4172767204vw 4.39238653vw;
  }
}
.cvBox__ttl {
  font-size: 3.2rem;
  font-weight: 700;
  line-height: 1.71875;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .cvBox__ttl {
    font-size: clamp(2.8rem, 2.3426061493vw, 3.2rem);
  }
}
.cvBox__ttl .sm {
  font-size: 2.5rem;
  line-height: 2.2;
  display: block;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .cvBox__ttl .sm {
    font-size: clamp(2.1rem, 1.8301610542vw, 2.5rem);
  }
}
.cvBox__btn {
  margin: 30px 0 0;
}
.cvBox__btn .btn {
  width: 100%;
}

.cvBox--loan {
  background: #FFF7D4;
  padding: 60px 1%;
}
.cvBox--loan__inner {
  max-width: 1140px;
  margin: 0 auto;
}
.cvBox--loan .balloon--red {
  display: block;
  width: 100%;
  max-width: 350px;
  margin: 0 auto 18px;
  text-align: center;
}

@media screen and (min-width: 0) and (max-width: 767px) {
  /*=================================
    cvBox
  =================================*/
  .cvBox {
    padding: 40px 6.4102564103%;
  }
  .cvBox__inner {
    display: block;
  }
  .cvBox__inner::before {
    height: 9px;
  }
  .cvBox__inner::after {
    height: 9px;
  }
  .cvBox__cont {
    padding: 14px 5.1282051282% 30px;
  }
  .cvBox__ttl {
    font-size: 3rem;
    line-height: 1.4;
  }
  .cvBox__ttl .sm {
    font-size: 1.8rem;
    line-height: 2;
  }
  .cvBox__btn {
    margin: 19px 0 0;
  }
  .cvBox--loan {
    padding: 30px 6.4102564103%;
    display: block !important;
  }
  .cvBox--loan .ttl--base {
    margin: 0 0 10px;
  }
  .cvBox--loan .balloon--red {
    font-size: 1.5rem;
    line-height: 3.5;
    width: 95%;
    margin: 0 auto;
    text-align: center;
  }
  .cvBox--loan .btnWrap {
    margin: 25px 0 0;
  }
  .cvBox--loan .loanList__item {
    margin: 17px 0 0;
  }
}
/*=================================
  typeList
=================================*/
.typeList {
  display: flex;
  flex-wrap: wrap;
}
.typeList__item {
  width: calc((100% - 60px) / 3);
  background: #fff;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.06);
  margin-bottom: 30px;
  padding: 30px 30px 30px 102px;
  position: relative;
}
.typeList__item:not(:nth-child(3n+1)) {
  margin-left: 30px;
}
.typeList__item .icon {
  width: 55px;
  height: 50px;
  object-fit: contain;
  position: absolute;
  top: 36px;
  left: 25px;
}
.typeList__item .ttl {
  font-size: 2.2rem;
  font-weight: 500;
  line-height: 1.5909;
}
.typeList__item .text {
  font-size: 1.6rem;
  line-height: 1.625;
  margin: 9px 0 0;
}
.typeList__item .btn {
  font-size: 1.3rem;
  font-weight: 700;
  line-height: 1.46153;
  color: #D70110;
  text-decoration: none;
  text-align: center;
  display: inline-block;
  border: 1px solid #D70110;
  border-radius: 3px;
  margin: 10px 0 0;
  padding: 10px 20px;
}
.typeList__item .btn::before {
  content: "";
  width: 15px;
  height: 15px;
  display: inline-block;
  background: url(../img/common/icon/icon_crown_red.svg) no-repeat center center;
  background-size: contain;
  margin: 0 7px -2px 0;
}
@media screen and (min-width: 767px) {
  .typeList__item .btn:hover {
    color: #fff;
    background: #D70110;
  }
  .typeList__item .btn:hover::before {
    background: url(../img/common/icon/icon_crown_white.svg) no-repeat center center;
    background-size: contain;
  }
}

@media screen and (min-width: 0) and (max-width: 767px) {
  /*=================================
    typeList
  =================================*/
  .typeList {
    display: block;
  }
  .typeList__item {
    width: 100%;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.06);
    margin: 26px 0 0;
    padding: 20px 7.4358974359%;
  }
  .typeList__item:not(:nth-child(3n+1)) {
    margin-left: 0;
  }
  .typeList__item .icon {
    top: 28px;
  }
  .typeList__item .ttl {
    padding-left: 73px;
  }
  .typeList__item .text {
    padding-left: 73px;
  }
  .typeList__item .btn {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.5;
    margin: 25px 0 0;
    padding: 15px 10px;
    display: block;
  }
  .typeList__item .btn::before {
    width: 19px;
    height: 19px;
    margin: 0 10px -2px 0;
  }
}
/*=================================
  loanList
=================================*/
.loanList {
  margin: 0 30px 0 0;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
}
.loanList__item {
  padding: 20px;
  border: 1px solid #CCCCCC;
  width: 340px;
  background: #fff;
  margin: 0 0 30px 30px;
}
.loanList__item .thumb {
  display: block;
  margin: 0 auto 13px;
}
.loanList__item .link {
  font-size: 2rem;
  font-weight: 500;
  color: #004DA1;
  line-height: 1.75;
  text-decoration: none;
  display: block;
  text-align: center;
  margin: 0 0 20px;
}
@media screen and (min-width: 767px) {
  .loanList__item .link:hover {
    text-decoration: underline;
  }
}
.loanList__item .band {
  font-size: 1.4rem;
  font-weight: 400;
  color: #fff;
  background: #004DA1;
  text-align: center;
  display: block;
  padding: 3px 5px;
}
.loanList__item .text {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.44444;
  color: #D70110;
  text-align: center;
  margin: 10px 0 0;
}
.loanList__item .table {
  margin: 10px 0 20px;
}
.loanList__item .table th, .loanList__item .table td {
  text-align: center;
  padding: 16px 6px;
}
.loanList__item .table th {
  font-size: 1.2rem;
  font-weight: 400;
  color: #000;
  background: #F5F6F9;
  min-width: 65px;
}
.loanList__item .table td {
  font-size: 1.4rem;
  font-weight: 700;
  background: #fff;
}
.loanList__item .note {
  font-size: 1.2rem;
  line-height: 1.5;
  margin: -10px 0 10px;
}
.loanList__item .btn {
  font-size: 1.4rem;
  line-height: 1.428571;
  padding: 19px 5px 17px;
  display: block;
}

@media screen and (min-width: 0) and (max-width: 767px) {
  /*=================================
    loanList
  =================================*/
  .loanList {
    margin: 0;
    display: block;
  }
  .loanList__item {
    padding: 20px 5.1282051282%;
    width: 100%;
    margin: 25px 0 0;
  }
  .loanList__item .table {
    display: table;
    white-space: normal;
  }
}