@use "../../global" as *;

/*=================================
  breadcrumb
=================================*/
.breadcrumb {
  border-bottom: 1px solid #DEDEDE;
  padding: 14px 1%;
  ul {
    li {
      font-size: 1.4rem;
      line-height: 1;
      color: #B8B8B8;
      display: inline-block;
      a {
        font-size: 1.4rem;
        line-height: 1;
        color: $font-color-base;
        margin-right: 13px;
        @include mq(pc) {
          &:hover {
            color: $color-link;
            text-decoration: none;
          }
        }
        &::after {
          content: "";
          border-style: solid;
          border-width: 5.5px 0 5.5px 6px;
          border-color: transparent transparent transparent #B8B8B8;
          display: inline-block;
          margin-left: 13px;
        }
      }
    }
  }
}
@include mq(sp) {
  /*=================================
    breadcrumb
  =================================*/
  .breadcrumb {
    padding: 10px per(17);
    ul {
      li {
        font-size: 1.2rem;
        line-height: 1.5;
        a {
          font-size: 1.2rem;
          line-height: 1.5;
          margin-right: 7px;
          &::after {
            margin-left: 7px;
            margin-bottom: -1px;
          }
        }
      }
    }
  }
}