@use "../../global" as *;
/*=================================
  trademark
=================================*/
.trademark {
  /* キービジュアル */
  .ttlWrap {
    background: #FFF7D4;
    padding: 50px 0 0 2.2%;
    position: relative;
    &__inner {
      max-width: 1276px;
      margin: 0 auto;
      background: #fff;
      padding: 67px 4% 87px;
      position: relative;
      &::before {
        content: "";
        width: 10px;
        height: 100%;
        display: block;
        border-left: 3px solid $color-blue;
        border-right: 3px solid $color-red;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    &__ttl {
      font-size: 1.9rem;
      line-height: 3.7;
      letter-spacing: .04em;
      box-shadow: 6px 6px 0px 0px $color-red-dark;
      position: absolute;
      top: -30px;
      left: -2%;
      &::before {
        width: 6px;
        height: calc(100% + 6px);
        right: -6px;
      }
      &::after {
        border-width: 18px 10px 0 10px;
        bottom: -18px;
        filter: drop-shadow(4px 6px 0px $color-red-dark);
      }
      .icon {
        width: 56px;
        margin: 0 10px 0 0;
      }
    }
    &__catch {
      font-size: 3.6rem;
      font-weight: 700;
      line-height: 1.333333;
      margin: 0 0 17px;
      max-width: 1070px;
    }
    &__band {
      font-size: 2.4rem;
      font-weight: 700;
      color: $color-red;
      text-align: center;
      display: block;
      background: #FFE6E8;
      max-width: 1070px;
      &::before {
        content: "";
        width: 24px;
        height: 21px;
        display: inline-block;
        background: url(../img/common/icon/icon_bullhorn.svg) no-repeat center center;
        background-size: contain;
        margin: 0 14px -2px 0;
      }
    }
    & + .trademarkCont {
      position: relative;
      top: -60px;
    }
  }
  /* メインコンテンツ */
  &Cont {
    background: #fff;
    &__inner {
      max-width: 1276px;
      margin: 0 auto;
      background: #fff;
      padding: 0 4% 30px;
    }
    .headWrap {
      max-width: 1070px;
      display: flex;
      align-items: flex-start;
      .thumb {
        @include mq(tab) {
          width: vw(300);
        }
        & + .pointList {
          width: calc(100% - 330px);
          margin-left: 30px;
          @include mq(tab) {
            width: calc(100% - vw(330));
            margin-left: vw(30);
          }
        }
      }
      .pointList {
        padding: 38px 18px;
        background: #FFF;
        border: 10px solid #FFF1B4;
        clear: both;
        @include mq(tab) {
          padding: vw(38) vw(27);
        }
        &__item {
          font-size: 2rem;
          font-weight: 700;
          line-height: 2;
          padding: 3px 12px 3px 40px;
          border-bottom: 1px dashed #CCCCCC;
          position: relative;
          &::before {
            content: "";
            width: 24px;
            height: 24px;
            background: url(../img/common/icon/icon_ul_yellow.svg) no-repeat center center;
            background-size: contain;
            display: inline-block;
            position: absolute;
            top: 19px;
            left: 3px;
          }
        }
      }
    }
    .status {
      margin: 25px 0 40px;
      .statusList {
        max-width: 1084px;
        margin: 0 0 13px -7px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        &__item {
          background: $color-blue;
          border: 3px solid $color-blue;
          border-radius: 5px;
          text-align: center;
          display: flex;
          align-items: center;
          margin: 0 7px 7px;
          width: 94px;
          height: 94px;
          position: relative;
          .wrap {
            font-size: 3.3rem;
            font-weight: 500;
            line-height: 1.121212;
            color: #fff;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
          }
          .sm {
            font-size: 1.8rem;
            display: block;
          }
          .md {
            font-size: 2.3rem;
            display: block;
          }
          &.no {
            border-color: #CCCCCC;
            background: #fff;
            &::after {
              content: "NO";
              font-size: 1.4rem;
              line-height: 1;
              color: #fff;
              padding: 2px 9px;
              background: $color-red;
              display: inline-block;
              position: absolute;
              right: -3px;
              bottom: -3px;
            }
            .wrap {
              color: #CCCCCC;
            }
          }
        }
      }
      .note {
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 2;
        max-width: 1070px;
      }
    }
    .btn {
      background: $color-gray;
      padding: 15px 18px 20px;
      margin: 40px 0 0;
      max-width: 1070px;
      a {
        font-size: 2.8rem;
        font-weight: 500;
        color: #fff;
        background: rgb(215,1,16);
        background: -moz-linear-gradient(180deg, rgba(215,1,16,1) 0%, rgba(185,5,18,1) 100%);
        background: -webkit-linear-gradient(180deg, rgba(215,1,16,1) 0%, rgba(185,5,18,1) 100%);
        background: linear-gradient(180deg, rgba(215,1,16,1) 0%, rgba(185,5,18,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d70110",endColorstr="#b90512",GradientType=1);
        border-radius: 5px;
        box-shadow: 4px 4px 0px 4px $color-red-dark;
        padding: 30px 5px;
        text-align: center;
        text-decoration: none;
        display: block;
        &::before {
          content: "";
          width: 26px;
          height: 26px;
          display: inline-block;
          background: url(../img/common/icon/icon_arrow_right.svg) no-repeat center center;
          background-size: contain;
          margin: 0 10px -2px 0;
        }
        @include mq(pc) {
          @include animation-base(all,0s);
          &:hover {
            box-shadow: none;
            transform: translate(8px,8px);
            opacity: .9;
          }
        }
      }
    }
    .other {
      padding: 33px 0 0;
      margin: 40px 0 0;
      max-width: 1070px;
      position: relative;
      &::before {
        content: "";
        width: calc(100% + 8%);
        height: 3px;
        display: block;
        background: $color-blue;
        position: absolute;
        top: 0;
        left: -4%;
      }
    }
    .conditions {
      margin: 0 0 30px;
      &__ttl {
        font-size: 1.8rem;
        font-weight: 700;
        line-height: 1.5555;
        margin: 0 0 10px;
        &::before {
          content: "";
          width: 18px;
          height: 21px;
          display: inline-block;
          background: url(../img/common/icon/icon_user.svg) no-repeat center center;
          background-size: contain;
          margin: 0 8px -2px 0;
        }
      }
      &__text {
        font-size: 1.6rem;
        line-height: 1.75;
        .note {
          font-size: 1.2rem;
          display: block;
        }
      }
    }
    .spec {
      margin: 0 0 30px;
      &__ttl {
        font-size: 1.8rem;
        font-weight: 700;
        line-height: 1.5555;
        margin: 0 0 10px;
        &::before {
          content: "";
          width: 22px;
          height: 20px;
          display: inline-block;
          background: url(../img/common/icon/icon_table.svg) no-repeat center center;
          background-size: contain;
          margin: 0 8px -2px 0;
        }
      }
      &__table {
        margin: 0;
        border-left: none;
        border-right: none;
        th,td {
          font-size: 1.4rem;
          color: $font-color-base;
          text-align: center;
          border-left: none;
          border-right: none;
          border-color: #CCCCCC;
        }
        th {
          font-weight: 500;
          background: $color-gray;
        }
        td {
          font-weight: 400;
          background: #fff;
        }
      }
    }
  }
}


@media screen and (max-width: $display-width-s) {
  /*=================================
    trademark
  =================================*/
  .trademark {
    /* キービジュアル */
    .ttlWrap {
      padding: 30px 0 0 per(17);
      &__inner {
        padding: 0 per(17) 0 per(23);
        &::before {
          width: 7px;
          border-left: 2px solid $color-blue;
          border-right: 2px solid $color-red;
        }
      }
      &__ttl {
        font-size: 1.6rem;
        line-height: 1.375;
        box-shadow: 6px 6px 0px 0px $color-red-dark;
        position: relative;
        padding: 6px 5px 5px 50px;
        top: -15px;
        left: 0;
        &::before {
          width: 6px;
          height: calc(100% + 6px);
          right: -6px;
        }
        &::after {
          border-width: 18px 10px 0 10px;
          bottom: -18px;
          filter: drop-shadow(3px 5px 0px $color-red-dark);
        }
        .icon {
          width: 41px;
          margin: 0;
          position: absolute;
          top: 5px;
          left: 6px;
        }
      }
      &__catch {
        font-size: 2.2rem;
        line-height: 1.363636;
        margin: 0 0 15px;
      }
      &__band {
        font-size: 1.6rem;
        padding: 2px 0;
        &::before {
          width: 15px;
          height: 14px;
          margin: 0 10px -2px 0;
        }
      }
      & + .trademarkCont {
        top: 0;
      }
    }
    /* メインコンテンツ */
    &Cont {
      padding: 20px per(25) 0;
      &__inner {
        padding: 0 0 40px;
      }
      .headWrap {
        display: block;
        .thumb {
          margin: 0 auto;
          display: block;
          & + .pointList {
            width: 100%;
            margin: 10px 0 0;
          }
        }
        .pointList {
          padding: 0;
          border: none;
          &__item {
            font-size: 1.4rem;
            line-height: 1.42857;
            padding: 10px 15px 10px 27px;
            &::before {
              width: 16px;
              height: 16px;
              top: 19px;
              left: 3px;
            }
          }
        }
      }
      .status {
        margin: 20px 0 30px;
        .statusList {
          margin: 0 0 20px -5px;
          justify-content: center;
          &__item {
            margin: 0 4px 8px;
            width: 61px;
            height: 61px;
            .wrap {
              font-size: 1.9rem;
              line-height: 1.10526;
            }
            .sm {
              font-size: 1rem;
            }
            .md {
              font-size: 1.3rem;
            }
            &.no {
              &::after {
                font-size: .8rem;
              }
            }
          }
        }
        .note {
          line-height: 1.71428;
        }
      }
      .btn {
        background: transparent;
        padding: 0;
        margin: 30px 0;
        a {
          font-size: 1.6rem;
          border-radius: 4px;
          box-shadow: 2px 2px 0px 2px $color-red-dark;
          padding: 20px 5px;
          &::before {
            width: 18px;
            height: 18px;
            margin: 0 5px -3px 0;
          }
        }
      }
      .other {
        padding: 15px 0 0;
        margin: 30px 0 0;
        &::before {
          width: 100%;
          height: 2px;
          left: 0;
        }
      }
      .conditions {
        &__ttl {
          font-size: 1.6rem;
          line-height: 1.4375;
          margin: 0 0 15px;
          &::before {
            width: 16px;
            height: 18px;
            margin: 0 7px -2px 0;
          }
        }
        &__text {
          font-size: 1.4rem;
          line-height: 1.71428;
        }
      }
      .spec {
        &__ttl {
          font-size: 1.6rem;
          line-height: 1.4375;
          margin: 0 0 15px;
          &::before {
            width: 19px;
            height: 16px;
            margin: 0 6px -2px 0;
          }
        }
        &__table {
          display: block;
          white-space: normal;
          border-right: 1px solid #CCCCCC;
          border-bottom: 1px solid #CCCCCC;
          &:nth-child(2n) th {
            border-left: 1px solid #CCCCCC;
          }
          tr {
            display: flex;
            flex-wrap: wrap;
          }
          th,td {
            font-size: 1.3rem;
            padding: 20px 10px;
            border: none;
            border-top: 1px solid #CCCCCC;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          th {
            font-weight: 500;
            background: $color-gray;
            width: 6rem;
          }
          td {
            width: calc((100% - 12rem) / 2);
          }
        }
      }
    }
  }
}