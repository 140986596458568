@use "../../global" as *;
/*=================================
  ranking
=================================*/
.ranking {
  /* キービジュアル */
  .ttlWrap {
    background: #FFF7D4;
    padding: 50px 4% 0 0;
    position: relative;
    &::before {
      content: "";
      width: 100%;
      height: 10px;
      display: block;
      border-top: 3px solid $color-blue;
      border-bottom: 3px solid $color-red;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
    &__inner {
      max-width: 1276px;
      margin: 0 auto;
      background: #fff;
      padding: 36px 4% 50px 0;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      flex-direction: row-reverse;
      position: relative;
      &::after {
        content: "";
        width: clamp(211px,vw(311),311px);
        height: clamp(162px,vw(262),262px);
        display: inline-block;
        background: url(../img/under/ranking_mv_deco.svg) no-repeat center center;
        background-size: contain;
        position: absolute;
        top: -30px;
        right: -4%;
      }
    }
    .textWrap {
      position: relative;
      z-index: 1;
    }
    &__img {
      height: 100%;
      object-fit: contain;
      margin-right: 34px;
      margin-top: -86px;
    }
    &__ttl {
      font-size: clamp(1.5rem,vw(19),1.9rem);
      font-weight: 700;
      line-height: 1;
      border-top: 5px solid $color-blue;
      border-bottom: 5px solid $color-red;
      display: inline-block;
      padding: 8px;
      margin: 0 0 20px;
      position: relative;
      &::before {
        content: "";
        width: 25px;
        height: 16px;
        display: inline-block;
        background: url(../img/common/icon/icon_crown_blue.svg) no-repeat center center #fff;
        background-size: contain;
        position: absolute;
        top: -16px;
        left: 2rem;
      }
    }
    &__catch {
      font-size: clamp(2.2rem,vw(42),4.2rem);
      font-weight: 700;
      line-height: 1.142857;
      margin: 0 0 20px;
    }
    &__text {
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 2.25;
    }
  }
  /* メインコンテンツ */
  &Cont {
    @include singleStyle-base;
    @include singleStyle-parts;
    margin: 0 auto;
    max-width: calc(1276px + 4%);
    padding: 0 4%;
    background: #fff;
    &__inner {
      background: #fff;
      padding: 0 0 30px;
      max-width: 1140px;
      margin: 0 auto;
    }
    .box--col2 {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      border: 10px solid #EDEEF1;
      padding: 28px 33px 36px;
      margin: 43px 0 70px;
      @include mq(tab) {
        flex-wrap: wrap;
        justify-content: center;
      }
      &__right {
        margin-left: 5.26%;
        width: calc(42% - 2.63%);
        @include mq(tab) {
          margin-left: 3%;
          width: calc(50% - 1.5%);
          min-width: 400px;
        }
      }
      &__left {
        width: calc(58% - 2.63%);
        @include mq(tab) {
          width: calc(50% - 1.5%);
          min-width: 400px;
        }
      }
      &__ttl {
        font-size: 2.5rem;
        font-weight: 700;
        line-height: 1.5;
        margin: 0 0 28px;
        padding: 0 0 0 36px;
        position: relative;
        .sm {
          font-size: 1.4rem;
          font-weight: 500;
          display: inline-block;
          margin: 0 0 0 1rem;
        }
        &::before {
          content: "";
          width: 26px;
          height: 23px;
          display: inline-block;
          background: url(../img/common/ttl_line_4.svg) no-repeat center center;
          background-size: contain;
          position: absolute;
          top: 8px;
          left: 0;
        }
      }
      &__img {
        display: block;
        text-align: center;
      }
    }
    .box--yellow {
      background: #FFF7D4;
      border-radius: 30px;
      padding: 23px per(33,390) 34px;
      &__ttl {
        font-size: 1.8rem;
        font-weight: 700;
        margin: 0 0 19px;
        .icon {
          margin: 0 6px 2px 0;
        }
      }
      &__list {
        li {
          padding: 0 0 0 2rem;
          position: relative;
          &::before {
            content: "・";
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
    }
  }
}
.rankingBox {
  padding: 50px 4% 85px;
  background: $color-gray;
  &__inner {
    max-width: $base-width;
    margin: 0 auto;
  }
  &__ttl {
    font-size: 4rem;
    font-weight: 700;
    line-height: 1.212121;
    text-align: center;
  }
  .rankingList {
    counter-reset: rankingNum;
    &__ttl {
      font-size: 3.6rem;
      font-weight: 700;
      line-height: 1.2;
      color: #fff;
      text-decoration: none;
      background: $color-blue;
      padding: 19px 23px 15px;
      a {
        font-size: 3.6rem;
        font-weight: 700;
        line-height: 1.2;
        color: #fff;
        text-decoration: none;
      }
      &::before {
        counter-increment: rankingNum;
        content: counter(rankingNum);
        font-size: 2.5rem;
        font-weight: 700;
        line-height: 2.5;
        color: $color-blue;
        background: url(../img/common/icon/icon_crown_ranking.svg) no-repeat center center;
        background-size: contain;
        width: 53px;
        height: 55px;
        display: inline-block;
        text-align: center;
        vertical-align: middle;
        margin: 0 16px 11px 0;
      }
    }
    &__catch {
      font-size: 3.6rem;
      font-weight: 700;
      line-height: 1.33333;
      color: $color-red;
      margin: 0 0 30px;
      text-align: center;
    }
    &__item {
      background: #fff;
      box-shadow: 0 8px 20px rgba(0, 0, 0, .06);
      margin: 50px 0 0;
      &:nth-child(1) {
        .rankingList__ttl {
          background: rgb(174,140,56);
          background: -moz-linear-gradient(90deg, rgba(174,140,56,1) 0%, rgba(206,172,91,1) 100%);
          background: -webkit-linear-gradient(90deg, rgba(174,140,56,1) 0%, rgba(206,172,91,1) 100%);
          background: linear-gradient(90deg, rgba(174,140,56,1) 0%, rgba(206,172,91,1) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ae8c38",endColorstr="#ceac5b",GradientType=1);
          &::before {
            color: #B08E3A;
          }
        }
      }
      &:nth-child(2) {
        .rankingList__ttl {
          background: rgb(180,180,180);
          background: -moz-linear-gradient(90deg, rgba(180,180,180,1) 0%, rgba(200,198,198,1) 100%);
          background: -webkit-linear-gradient(90deg, rgba(180,180,180,1) 0%, rgba(200,198,198,1) 100%);
          background: linear-gradient(90deg, rgba(180,180,180,1) 0%, rgba(200,198,198,1) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#b4b4b4",endColorstr="#c8c6c6",GradientType=1);
          &::before {
            color: #B5B5B5;
          }
        }
      }
      &:nth-child(3) {
        .rankingList__ttl {
          background: rgb(101,74,42);
          background: -moz-linear-gradient(90deg, rgba(101,74,42,1) 0%, rgba(146,125,76,1) 100%);
          background: -webkit-linear-gradient(90deg, rgba(101,74,42,1) 0%, rgba(146,125,76,1) 100%);
          background: linear-gradient(90deg, rgba(101,74,42,1) 0%, rgba(146,125,76,1) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#654a2a",endColorstr="#927d4c",GradientType=1);
          &::before {
            color: #684E2C;
          }
        }
      }
      .container {
        padding: 30px 6% 64px;
      }
      .headWrap {
        display: flex;
        align-items: flex-start;
        .thumb {
          @include mq(tab) {
            width: vw(280);
          }
          & + .pointList {
            width: calc(100% - 308px);
            margin-left: 28px;
            @include mq(tab) {
              width: calc(100% - vw(308));
              margin-left: vw(28);
            }
          }
        }
        .pointList {
          padding: 25px 22px;
          background: #FFF;
          border: 10px solid #FFF1B4;
          clear: both;
          @include mq(tab) {
            padding: vw(25) vw(22);
          }
          &__item {
            font-size: 1.8rem;
            font-weight: 700;
            line-height: 2;
            padding: 3px 12px 3px 40px;
            border-bottom: 1px dashed #CCCCCC;
            position: relative;
            &::before {
              content: "";
              width: 22px;
              height: 22px;
              background: url(../img/common/icon/icon_ul_yellow.svg) no-repeat center center;
              background-size: contain;
              display: inline-block;
              position: absolute;
              top: 16px;
              left: 3px;
            }
          }
        }
      }
      .status {
        margin: 25px 0 40px;
        .statusList {
          margin: 0 0 10px -7px;
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          &__item {
            background: $color-blue;
            border: 3px solid $color-blue;
            border-radius: 5px;
            text-align: center;
            display: flex;
            align-items: center;
            margin: 0 6px 7px 7px;
            width: 88px;
            height: 88px;
            position: relative;
            .wrap {
              font-size: 3rem;
              font-weight: 500;
              line-height: 1.133333;
              color: #fff;
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              justify-content: center;
            }
            .sm {
              font-size: 1.6rem;
              display: block;
            }
            .md {
              font-size: 2.1rem;
              display: block;
            }
            &.no {
              border-color: #CCCCCC;
              background: #fff;
              &::after {
                content: "NO";
                font-size: 1.2rem;
                line-height: 1;
                color: #fff;
                padding: 2px 9px;
                background: $color-red;
                display: inline-block;
                position: absolute;
                right: -3px;
                bottom: -3px;
              }
              .wrap {
                color: #CCCCCC;
              }
            }
          }
        }
        .note {
          font-size: 1.4rem;
          font-weight: 400;
          line-height: 2;
        }
      }
      .conditions {
        margin: 40px 0 0;
        &__ttl {
          font-size: 1.6rem;
          font-weight: 700;
          line-height: 1.4375;
          padding: 15px 12px;
          border-top: 2px solid $color-blue;
          &::before {
            content: "";
            width: 16px;
            height: 18px;
            display: inline-block;
            background: url(../img/common/icon/icon_user.svg) no-repeat center center;
            background-size: contain;
            margin: 0 8px -2px 0;
          }
        }
        &__text {
          font-size: 1.6rem;
          line-height: 1.75;
          .note {
            font-size: 1.2rem;
            display: block;
          }
        }
      }
      .spec {
        margin: 40px 0 0;
        &__ttl {
          font-size: 1.6rem;
          font-weight: 700;
          line-height: 1.4375;
          padding: 15px 12px;
          border-top: 2px solid $color-red;
          &::before {
            content: "";
            width: 20px;
            height: 18px;
            display: inline-block;
            background: url(../img/common/icon/icon_table.svg) no-repeat center center;
            background-size: contain;
            margin: 0 8px -2px 0;
          }
        }
        &__table {
          margin: 0;
          th,td {
            text-align: center;
            border-color: #CCCCCC;
          }
          th {
            font-weight: 500;
          }
          td {
            background: #fff;
          }
        }
      }
      .btn--col2 {
        display: flex;
        justify-content: center;
        .btn {
          width: calc((100% - 4%) / 2);
          &:nth-child(2n) {
            margin-left: 4%;
          }
        }
      }
      .btn {
        background: $color-gray;
        padding: 10px;
        margin: 40px 0 0;
        a {
          font-size: 2.2rem;
          font-weight: 500;
          color: #fff;
          background: rgb(215,1,16);
          background: -moz-linear-gradient(180deg, rgba(215,1,16,1) 0%, rgba(185,5,18,1) 100%);
          background: -webkit-linear-gradient(180deg, rgba(215,1,16,1) 0%, rgba(185,5,18,1) 100%);
          background: linear-gradient(180deg, rgba(215,1,16,1) 0%, rgba(185,5,18,1) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d70110",endColorstr="#b90512",GradientType=1);
          border-radius: 5px;
          box-shadow: 3px 3px 0px 3px $color-red-dark;
          padding: 30px 5px;
          text-align: center;
          text-decoration: none;
          display: block;
          &::before {
            content: "";
            width: 25px;
            height: 25px;
            display: inline-block;
            background: url(../img/common/icon/icon_arrow_right.svg) no-repeat center center;
            background-size: contain;
            margin: 0 12px -4px 0;
          }
          @include mq(pc) {
            @include animation-base(all,0s);
            &:hover {
              box-shadow: none;
              transform: translate(6px,6px);
              opacity: .9;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $display-width-s) {
  /*=================================
    ranking
  =================================*/
  .ranking {
    /* キービジュアル */
    .ttlWrap {
      padding: 30px per(17) 0 0;
      &::before {
        content: none;
      }
      &__inner {
        padding: 20px per(18) 30px;
        display: block;
        &::after {
          content: "";
          width: clamp(87px,vw(173,$sp-width),173px);
          height: clamp(73px,vw(146,$sp-width),146px);
          top: -20px;
          right: per(-17);
        }
      }
      &__img {
        margin: 30px 0;
      }
      &__ttl {
        font-size: 1.5rem;
        border-top: 4px solid $color-blue;
        border-bottom: 4px solid $color-red;
        padding: 6px 8px;
        margin: 0 0 10px;
        &::before {
          width: 20px;
          height: 13px;
          top: -13px;
        }
      }
      &__catch {
        font-size: 3.4rem;
        line-height: 1.23529;
        margin: 0 0 10px;
      }
      &__text {
        font-size: 1.4rem;
        line-height: 1.78571;
      }
    }
    /* メインコンテンツ */
    &Cont {
      padding: 0 per(17);
      &__inner {
        padding: 0;
      }
      .box--col2 {
        display: block;
        border: none;
        padding: 0;
        margin: 40px 0;
        &__right {
          margin: 20px 0 0;
          width: 100%;
        }
        &__left {
          width: 100%;
        }
        &__ttl {
          font-size: 1.6rem;
          line-height: 1.875;
          margin: 0 0 10px;
          padding: 0 0 0 21px;
          .sm {
            font-size: 1rem;
          }
          &::before {
            width: 15px;
            height: 14px;
          }
        }
      }
      .box--yellow {
        border-radius: 15px;
        padding: 20px per(30);
        &__ttl {
          font-size: 1.5rem;
          margin: 0 0 12px;
          text-align: center;
          .icon {
            width: 25px;
            margin: 0 5px 2px 0;
          }
        }
        &__list {
          li {
            font-size: 1.3rem;
          }
        }
      }
    }
  }
  .rankingBox {
    padding: 40px per(10) 38px;
    &__ttl {
      font-size: 2rem;
      line-height: 1.6;
    }
    .rankingList {
      &__ttl {
        font-size: 1.8rem;
        line-height: 1.38888;
        padding: 7px per(8) 2px;
        a {
          font-size: 1.8rem;
          line-height: 1.38888;
        }
        &::before {
          font-size: 1rem;
          line-height: 2.5;
          width: 23px;
          height: 24px;
          margin: 0 5px 7px 0;
        }
      }
      &__catch {
        font-size: 2rem;
        line-height: 1.4;
        margin: 0 0 15px;
      }
      &__item {
        box-shadow: 0 4px 10px rgba(0, 0, 0, .1);
        margin: 30px 0 0;
        .container {
          padding: 15px per(10) 20px;
        }
        .headWrap {
          display: block;
          .thumb {
            margin: 0 auto;
            display: block;
            & + .pointList {
              width: 100%;
              margin: 10px 0 0;
            }
          }
          .pointList {
            padding: 0;
            border: none;
            &__item {
              font-size: 1.4rem;
              line-height: 1.42857;
              padding: 10px 15px 10px 27px;
              &::before {
                width: 16px;
                height: 16px;
                top: 19px;
                left: 3px;
              }
            }
          }
        }
        .status {
          margin: 20px 0 30px;
          .statusList {
            margin: 0 0 20px -5px;
            justify-content: center;
            &__item {
              margin: 0 4px 8px;
              width: 61px;
              height: 61px;
              .wrap {
                font-size: 1.9rem;
                line-height: 1.10526;
              }
              .sm {
                font-size: 1rem;
              }
              .md {
                font-size: 1.3rem;
              }
              &.no {
                &::after {
                  font-size: .8rem;
                }
              }
            }
          }
          .note {
            line-height: 1.71428;
          }
        }
        .spec {
          &__ttl {
            padding: 15px 0;
            &::before {
              width: 19px;
              height: 16px;
              margin: 0 6px -2px 0;
            }
          }
          &__table {
            display: block;
            white-space: normal;
            border-right: 1px solid #CCCCCC;
            border-bottom: 1px solid #CCCCCC;
            &:nth-child(2n) th {
              border-left: 1px solid #CCCCCC;
            }
            tr {
              display: flex;
              flex-wrap: wrap;
            }
            th,td {
              font-size: 1.3rem;
              padding: 20px 10px;
              border: none;
              border-top: 1px solid #CCCCCC;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            th {
              font-weight: 500;
              width: 6rem;
            }
            td {
              width: calc((100% - 12rem) / 2);
            }
          }
        }
        .conditions {
          &__ttl {
            padding: 15px 0;
            &::before {
              width: 16px;
              height: 18px;
              margin: 0 7px -2px 0;
            }
          }
          &__text {
            font-size: 1.4rem;
            line-height: 1.71428;
          }
        }
        .btn--col2 {
          display: block;
          .btn {
            width: 100%;
            & + .btn {
              margin: 15px 0 0;
            }
          }
        }
        .btn {
          background: transparent;
          padding: 0;
          margin: 40px 0 0;
          a {
            font-size: 1.8rem;
            border-radius: 4px;
            box-shadow: 2px 2px 0px 2px $color-red-dark;
            padding: 20px 5px;
            &::before {
              width: 18px;
              height: 18px;
              margin: 0 5px -3px 0;
            }
            @include mq(pc) {
              @include animation-base(all,0s);
              &:hover {
                box-shadow: none;
                transform: translate(6px,6px);
                opacity: .9;
              }
            }
          }
        }
      }
    }
  }
}