@use "../../global" as *;

/*=================================
  pager
=================================*/
.pager {
  margin: 50px auto 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &List {
    &__item {
      margin-right: 3px;
      display: inline-block;
      a,span {
        font-size: 1.5rem;
        line-height: 1;
        padding: 13px 16px;
        display: block;
      }
      a {
        color: $color-theme;
        text-decoration: none;
        background: #fff;
        @include mq(pc) {
          &:hover {
            color: #fff;
            background: $color-theme;
          }
        }
      }
      span {
        color: #fff;
        background: $color-theme;
      }
    }
  }
  .listCount {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: $display-width-s){
  /*=================================
    pager
  =================================*/
  .pager {
    margin: 20px auto 0;
    display: block;
    text-align: center;
    &List {
      &__item {
        a,span {
          font-size: 1.2rem;
          line-height: 1;
          padding: 13px 16px;
          display: block;
        }
      }
    }
    .listCount {
      font-size: 1.2rem;
      margin: 15px 0 0;
    }
  }
}