@use "../../global" as *;

/*=================================
  button
=================================*/
.btnWrap {
  margin: 20px auto 30px;
  text-align: center;
  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }

  > * + * {
    margin-top: 9px;
  }
}
.btn--red {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.428571;
  color: #fff;
  text-decoration: none;
  display: block;
  background: rgb(215,1,16);
  background: -moz-linear-gradient(180deg, rgba(215,1,16,1) 0%, rgba(185,5,18,1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(215,1,16,1) 0%, rgba(185,5,18,1) 100%);
  background: linear-gradient(180deg, rgba(215,1,16,1) 0%, rgba(185,5,18,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d70110",endColorstr="#b90512",GradientType=1);
  border-radius: 4px;
  box-shadow: 2px 2px 0px 2px $color-red-dark;
  padding: 19px 5px 17px;
  text-align: center;
  position: relative;
  @include mq(tab) {
    font-size: clamp(15px, vw(18), 18px);
    padding: vw(17) vw(28);
  }
}
.btn--ranking {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.428571;
  color: #fff;
  text-decoration: none;
  display: block;
  background: rgb(215,1,16);
  background: -moz-linear-gradient(180deg, rgba(215,1,16,1) 0%, rgba(185,5,18,1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(215,1,16,1) 0%, rgba(185,5,18,1) 100%);
  background: linear-gradient(180deg, rgba(215,1,16,1) 0%, rgba(185,5,18,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d70110",endColorstr="#b90512",GradientType=1);
  border-radius: 4px;
  box-shadow: 2px 2px 0px 2px $color-red-dark;
  padding: 19px 5px 17px;
  text-align: center;
  position: relative;
  @include mq(tab) {
    font-size: clamp(15px, vw(18), 18px);
    padding: vw(17) vw(28);
  }
  &::before {
    content: "";
    width: 15px;
    height: 15px;
    display: inline-block;
    background: url(../img/common/icon/icon_crown_white.svg) no-repeat center center;
    background-size: contain;
    margin: 0 7px -2px 0;
  }
}
.btn--blue {
  font-size: 1.4rem;
  font-weight: 500;
  color: #fff;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  background: $color-theme;
  padding: 15px;
}
.btn--more {
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.42857;
  color: $font-color-base;
  border: 1px solid $color-blue;
  text-decoration: none;
  text-align: center;
  display: block;
  background: #fff;
  margin: 0 auto;
  padding: 20px 15px;
  border-radius: 3px;
  position: relative;
  &::before {
    content: "";
    width: 16px;
    height: 16px;
    display: inline-block;
    background: url(../img/common/icon/icon_arrow_right_blue.svg) no-repeat center center;
    background-size: contain;
    margin: 0 8px -3px 0;
  }
}

@include mq(pc) {
  /*=================================
    button
  =================================*/
  .btnWrap {
    display: flex;
    justify-content: center;
    > * + * {
      margin: 0 20px;
    }
  }
  .btn--red {
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 1.444444;
    border-radius: 5px;
    padding: 17px 28px;
    display: inline-block;
    @include animation-base(all,0s);
    &:hover {
      box-shadow: none;
      transform: translate(4px, 4px);
    }
  }
  .btn--ranking {
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 1.444444;
    border-radius: 5px;
    padding: 17px 28px;
    display: inline-block;
    &::before {
      width: 20px;
      height: 20px;
      margin: 0 13px -2px 0;
    }
    @include animation-base(all,0s);
    &:hover {
      box-shadow: none;
      transform: translate(4px, 4px);
    }
  }
  .btn--blue {
    font-size: 2.2rem;
    padding: 23px 37px;
    &:hover {
      opacity: .9;
    }
  }
  .btn--more {
    &:hover {
      background: $color-blue;
      color: #fff;
      &::before {
        @include animation-base(all,.5s);
        background: url(../img/common/icon/icon_arrow_right_white.svg) no-repeat center center;
        background-size: contain;
      }
    }
  }
}