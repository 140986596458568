@use "../global" as *;
/*=================================
  typeList
=================================*/
.typeList {
  display: flex;
  flex-wrap: wrap;
  &__item {
    width: calc((100% - 60px) / 3);
    background: #fff;
    box-shadow: 0 8px 20px rgba(0, 0, 0, .06);
    margin-bottom: 30px;
    padding: 30px 30px 30px 102px;
    position: relative;
    &:not(:nth-child(3n + 1)) {
      margin-left: 30px;
    }
    .icon {
      width: 55px;
      height: 50px;
      object-fit: contain;
      position: absolute;
      top: 36px;
      left: 25px;
    }
    .ttl {
      font-size: 2.2rem;
      font-weight: 500;
      line-height: 1.59090;
    }
    .text {
      font-size: 1.6rem;
      line-height: 1.625;
      margin: 9px 0 0;
    }
    .btn {
      font-size: 1.3rem;
      font-weight: 700;
      line-height: 1.46153;
      color: $color-red;
      text-decoration: none;
      text-align: center;
      display: inline-block;
      border: 1px solid $color-red;
      border-radius: 3px;
      margin: 10px 0 0;
      padding: 10px 20px;
      &::before {
        content: "";
        width: 15px;
        height: 15px;
        display: inline-block;
        background: url(../img/common/icon/icon_crown_red.svg) no-repeat center center;
        background-size: contain;
        margin: 0 7px -2px 0;
      }
      @include mq(pc) {
        &:hover {
          color: #fff;
          background: $color-red;
          &::before {
            background: url(../img/common/icon/icon_crown_white.svg) no-repeat center center;
            background-size: contain;
          }
        }
      }
    }
  }
}

@include mq(sp) {
  /*=================================
    typeList
  =================================*/
  .typeList {
    display: block;
    &__item {
      width: 100%;
      box-shadow: 0 5px 10px rgba(0, 0, 0, .06);
      margin: 26px 0 0;
      padding: 20px per(29);
      &:not(:nth-child(3n + 1)) {
        margin-left: 0;
      }
      .icon {
        top: 28px;
      }
      .ttl {
        padding-left: 73px;
      }
      .text {
        padding-left: 73px;
      }
      .btn {
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 1.5;
        margin: 25px 0 0;
        padding: 15px 10px;
        display: block;
        &::before {
          width: 19px;
          height: 19px;
          margin: 0 10px -2px 0;
        }
      }
    }
  }
}