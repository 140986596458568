@use "../global" as *;
/* =================================
  side nav
================================= */
.sideNav {
  width: 290px;
  &__inner {
    width: 290px;
  }
  .sideSec {
    position: relative;
    padding-top: 15px;
    &__ttl {
      font-size: 1.5rem;
      position: absolute;
      top: -55px;
    }
    &__cont {
      background: #fff;
      .contTtl {
        font-size: 2rem;
        font-weight: 700;
        line-height: 1.5555;
        padding: 0 0 18px 27px;
        border-bottom: 1px solid #CCCCCC;
        position: relative;
        &::before {
          content: "";
          width: 20px;
          height: 17px;
          display: inline-block;
          background: url(../img/common/ttl_line_4.svg) no-repeat center center;
          background-size: contain;
          position: absolute;
          top: 8px;
          left: 0;
        }
      }
      .linkList {
        margin: 20px 0 0;
        &__item {
          position: relative;
          a {
            font-size: 1.6rem;
            line-height: 1.375;
            color: $color-blue;
            display: block;
            text-decoration: none;
            padding: 0 0 0 22px;
            @include mq(pc) {
              &:hover {
                text-decoration: underline;
              }
            }
          }
          &::before {
            content: "";
            width: 16px;
            height: 16px;
            display: inline-block;
            background: url(../img/common/icon/icon_arrow_right_blue.svg) no-repeat center center;
            background-size: contain;
            position: absolute;
            top: 5px;
            left: 0;
          }
          & + .linkList__item {
            margin-top: 20px;
          }
        }
        &.new {
          .linkList__item {
            .date {
              font-size: 1.2rem;
              display: block;
            }
            a {
              padding: 0;
            }
            &::before {
              content: none;
            }
          }
        }
        &.ranking {
          margin: 0;
          .linkList__item {
            border-right: 1px solid #CCCCCC;
            border-left: 1px solid #CCCCCC;
            border-bottom: 1px solid #CCCCCC;
            padding: 17px 17px 17px 60px;
            position: relative;
            .icon {
              position: absolute;
              top: 17px;
              left: 17px;
            }
            .text {
              font-size: 1.4rem;
              line-height: 1.57142;
            }
            a {
              font-size: 1.8rem;
              color: $font-color-base;
              padding: 0;
            }
            &::before {
              content: none;
            }
            &::after {
              content: "";
              display: inline-block;
              border-width: 0 0 12px 12px;
              border-style: solid;
              border-color: transparent transparent $color-red transparent;
              position: absolute;
              right: 8px;
              bottom: 8px;
            }
            & + .linkList__item {
              margin-top: 0;
            }
          }
        }
      }
      & + .sideSec__cont {
        margin-top: 50px;
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  /* =================================
    side nav
  ================================= */
  .sideNav {
    width: 100%;
    &__inner {
      width: 100%;
    }
    .sideSec {
      display: flex;
      flex-wrap: wrap;
      padding-top: 0;
      &__ttl {
        display: none;
      }
      &__cont {
        width: calc((100% - 4%) / 2);
        margin-bottom: 4%;
        &:nth-child(2n - 1) {
          margin-left: 4%;
        }
        .linkList {
          &.ranking {
            margin: 0;
            .linkList__item {
              border-right: none;
              border-left: none;
            }
          }
        }
        & + .sideSec__cont {
          margin-top: 0;
        }
      }
    }
  }
}

@include mq(sp) {
    /* =================================
    side nav
  ================================= */
  .sideNav {
    padding: 0 per(17);
    .sideSec {
      &__cont {
        width: 100%;
        margin-bottom: 11%;
        &:nth-child(2n - 1) {
          margin-left: 0;
        }
        .linkList {
          &.ranking {
            .linkList__item {
              .icon {
                left: 0;
              }
            }
          }
        }
      }
    }
  }
}