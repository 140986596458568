// color
$base-color: #fff;
$font-color-base: #000;
$color-link: #304A85;
$color-theme: #304A85;
$color-theme-light:#F5F8FF;
$color-red: #D70110;
$color-red-dark: #AA000C;
$color-blue: #004DA1;
$color-blue-dark: #00336B;
$color-pink: #ee063c;
$color-pink-dark: #b0022b;
$color-yellow: #FECB3F;
$color-gray: #F5F6F9;

// width
$base-width: 1140px;
$base-width-in: 1000px;
$display-width-s: 767px;
$design-width: 1366;
$sp-width: 390;

//タイトルの定義
@mixin ttl-base($size,$color:$font-color-base,$lineHeight:1.25,$weight:bold) {
  font-size: $size;
  color: $color;
  line-height: $lineHeight;
  font-weight: $weight;
  font-family: 'Roboto', "Noto Sans JP", "源ノ角ゴシック", "Yu Gothic Medium","游ゴシック Medium","游ゴシック",YuGothic,YuGothicM,"ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
}

//vw計算
@function vw($pixelsVw,$width:$design-width) {
  @return calc($pixelsVw / $width) * 100vw;
}
//%計算
@function per($pixelsPer,$width:$sp-width) {
  @return calc($pixelsPer / $width) * 100%;
}