@use "../../global" as *;

/*=================================
  table
=================================*/
table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 30px 0 50px;
  width: 100%;
  border: 1px solid #E3E3E3;
  caption {
    font-size: 2rem;
    font-weight: 700;
    margin: 0 0 30px;
    text-align: center;
    caption-side: top;
    position: relative;
    &::before {
      content: "";
      width: 19px;
      height: 17px;
      display: inline-block;
      background: url(../img/common/ttl_line_4.svg) no-repeat center center;
      background-size: contain;
      margin: 0 9px -2px 0;
    }
  }
  thead {
    th,td {
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 1.75;
      color: #fff;
      text-align: center;
      background: $color-blue;
    }
  }
  tbody tr {
    td {
      &:nth-of-type(1) {
        background: $color-gray;
      }
      &.gray {
        background: $color-gray;
      }
      &:nth-of-type(3) {
        background: #FFF7D4;
      }
      &.yellow {
        background: #FFF7D4;
      }
    }
  }
  th {
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.75;
    color: #fff;
    text-align: center;
    background: $color-blue;
    padding: 19px 17px;
    @include mq(pc) {
      min-width: 90px;
    }
  }
  td {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.75;
    padding: 19px 12px;
  }
  th,td {
    border: 1px solid #E3E3E3;
    vertical-align: middle;
  }
  &.scroll {
    white-space: nowrap;
    overflow: auto;
    display: block;
    border: none;
    ::-webkit-scrollbar {
      height: 5px;
    }
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    ::-webkit-scrollbar-thumb {
      background: #bcbcbc;
    }
  }
}

.table--comparison {
  overflow: auto;
  margin: 50px 0;
  table {
    border-collapse: collapse;
    border-spacing: 0px;
    th,td {
      font-size: 1.6rem;
      text-align: center;
    }
    th {
      max-width: 200px;
      text-align: center;
    }
    td {
      max-width: 200px;
      background: #fff!important;
    }
    img {
      max-width: 180px;
      height: auto;
      display: block;
      margin: 0 auto!important;
    }
  }
  caption {
    margin: 0 0 20px;
    max-width: 1140px;
  }
  // tr {
  //   &:not(:first-child) .fixedCell {
  //     font-weight: 400;
  //     color: $font-color-base;
  //     background: #FFF7D4;
  //     min-width: 150px;
  //   }
  // }
  // th {
  //   &:not(.nowrapCell) {
  //     white-space: nowrap;
  //   }
  // }
  // td {
  //   &:not(.nowrapCell) {
  //     white-space: nowrap;
  //   }
  // }
  .fixedCell {
    position: sticky;
    left: 0;
    z-index: 500;
    font-weight: 400;
    color: $font-color-base;
    background: #FFF7D4!important;
    min-width: 150px;
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0px;
      display: block;
      border-right: 1px solid #E3E3E3;
    }
    &::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: -1px;
      display: block;
      border-left: 1px solid #E3E3E3;
    }
  }
  .specTb__btn {
    font-size: 1.3rem;
    font-weight: 500;
    line-height: 1.46153;
    color: #fff;
    text-decoration: none;
    display: block;
    background: rgb(215,1,16);
    background: -moz-linear-gradient(180deg, rgba(215,1,16,1) 0%, rgba(185,5,18,1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(215,1,16,1) 0%, rgba(185,5,18,1) 100%);
    background: linear-gradient(180deg, rgba(215,1,16,1) 0%, rgba(185,5,18,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d70110",endColorstr="#b90512",GradientType=1);
    border-radius: 3px;
    box-shadow: 2px 2px 0px 2px $color-red-dark;
    padding: 11px 5px 10px;
    text-align: center;
    position: relative;
    &::before {
      content: "";
      width: 13px;
      height: 13px;
      display: inline-block;
      background: url(../img/common/icon/icon_arrow_right_white.svg) no-repeat center center;
      background-size: contain;
      margin: 0 7px -2px 0;
    }
    @include mq(pc) {
      @include animation-base(all,0s);
      &:hover {
        box-shadow: none;
        transform: translate(4px, 4px);
      }
    }
    @include mq(tab) {
      font-size: clamp(15px, vw(18), 18px);
      padding: vw(17) vw(28);
    }
  }
}
.scrollAnnounce {
  display: none;
}
@media screen and (max-width: $display-width-s){
  /*=================================
    table
  =================================*/
  table {
    margin: 20px 0 40px;
    &:not(.noscroll) {
      display: block;
      white-space: nowrap;
      overflow-x: auto;
      border: none;
    }
    caption {
      font-size: 1.5rem;
      margin: 15px 0;
    }
    th,td {
      padding: 14px 20px;
    }
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    &::-webkit-scrollbar-track {
      border-radius: none;
      background: #d6d6d6;
    }
    /* スクロールのつまみ部分の設定 */
    &::-webkit-scrollbar-thumb {
      background: $color-blue;
    }
  }
  .table--comparison {
    margin: 38px 0 60px;
    table {
      white-space: normal;
      th,td {
        font-size: 1.8rem;
        padding: 12px;
      }
      th {
        min-width: 200px;
      }
      img {
        max-width: 100%;
      }
    }
    caption {
      margin: 0 0 18px;
      text-align: left;
    }
    .fixedCell {
      font-size: 1.6rem;
      font-weight: 700;
      min-width: 110px;
      top: -1px;
      left: -2px;
      &::after {
        width: calc(100% + 2px);
        left: -1px;
        top: -1px;
      }
    }
    .specTb__btn {
      font-size: 1.9rem;
      &::before {
        width: 20px;
        height: 20px;
        margin: 0 7px -4px 0;
      }
    }
  }
  .scrollAnnounce {
    display: block;
    text-align: center;
    .text {
      font-size: 1.3rem;
      font-weight: 600;
      color: #fff;
      background: $color-theme;
      padding: 3px 6px;
      position: relative;
      &::after {
        content: "";
        border-style: solid;
        border-width: 7px 6.5px 0 6.5px;
        border-color: $color-theme transparent transparent transparent;
        display: inline-block;
        position: absolute;
        bottom: -7px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}