@use "../global" as *;
/*=================================
  loanList
=================================*/
.loanList {
  margin: 0 30px 0 0;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  &__item {
    padding: 20px;
    border: 1px solid #CCCCCC;
    width: 340px;
    background: #fff;
    margin: 0 0 30px 30px;
    .thumb {
      display: block;
      margin: 0 auto 13px;
    }
    .link {
      font-size: 2rem;
      font-weight: 500;
      color: $color-blue;
      line-height: 1.75;
      text-decoration: none;
      display: block;
      text-align: center;
      margin: 0 0 20px;
      @include mq(pc) {
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .band {
      font-size: 1.4rem;
      font-weight: 400;
      color: #fff;
      background: $color-blue;
      text-align: center;
      display: block;
      padding: 3px 5px;
    }
    .text {
      font-size: 1.8rem;
      font-weight: 700;
      line-height: 1.44444;
      color: $color-red;
      text-align: center;
      margin: 10px 0 0;
    }
    .table {
      margin: 10px 0 20px;
      th,td {
        text-align: center;
        padding: 16px 6px;
      }
      th {
        font-size: 1.2rem;
        font-weight: 400;
        color: $font-color-base;
        background: $color-gray;
        min-width: 65px;
      }
      td {
        font-size: 1.4rem;
        font-weight: 700;
        background: #fff;
      }
    }
    .note {
      font-size: 1.2rem;
      line-height: 1.5;
      margin: -10px 0 10px;
    }
    .btn {
      font-size: 1.4rem;
      line-height: 1.428571;
      padding: 19px 5px 17px;
      display: block;
    }
  }
}

@include mq(sp) {
  /*=================================
    loanList
  =================================*/
  .loanList {
    margin: 0;
    display: block;
    &__item {
      padding: 20px per(20);
      width: 100%;
      margin: 25px 0 0;
      .table {
        display: table;
        white-space: normal;
      }
    }
  }
}